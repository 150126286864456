import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Loader from "../Loader";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt_BR from 'date-fns/locale/pt-BR';
import Select from "react-select";
import { Accordion, Card, Button } from "react-bootstrap";

registerLocale('pt-BR', pt_BR);

let reportsServiceBaseUrl = process.env.MIX_BASE_URL_REPORTS;


export default class RelatorioRankingCidadeForm extends Component {

    defaultTiposOptions = [{
        label: 'Vertical', value: 'Vertical'
    }, {
        label: 'Horizontal', value: 'Horizontal'
    }, {
        label: 'Comercial', value: 'Comercial'
    }, {
        label: 'Hotel', value: 'Hotel'
    }];


    constructor(props) {
        super();

        this.state = {
            periodo_inicial: '',
            periodo_final: '',
            tipos: this.defaultTiposOptions.filter(tipo => tipo.value != 'Hotel'),
            estados: [],
            cidadesOptions: [],
            cidades: [],
            incorporadoras: [],
            tipo_detalhamento: '',
            padrao: [],
            detalhes_para_exibir: [],
            tiposOptions: this.defaultTiposOptions,
            estadosOptions: [],
            errors: [],
            incorporadorasOptions: [],
            loading: false,
            periodos: [],
            agrupamentoOptions: [{
                label: 'Trimestre', value: 'Trimestre'
            }, {
                label: 'Semestre', value: 'Semestre'
            }, {
                label: 'Anual', value: 'Anual'
            }],
            tipoDetalhamentoOptions: [{
                label: 'Cidade', value: 'CidadeOnly'
            }, {
                label: 'Incorporadora', value: 'IncorporadoraOnly'
            },],
            rows: [],
            submited: false,
            tipo_relatorio_options: [
                {
                    label: 'Agrupado', value: 'Agrupado'
                },
                {
                    label: 'Por Período', value: 'Por Período'
                },
            ],

        }
    }

    getRequestHeader = () => {
        return {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${this.state.user.jwt_token}`,
                responseType: 'blob'
            }
        }
    }

    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }

    loadAuthUser = async () => {
        try {

            let response = await axios.get(route('user.auth'));

            if (response.status === 200) {
                this.setState({
                    ...this.state, user: response.data
                });
            }

        } catch (e) {
            console.log(e);
        }
    }

    loadEstados = async () => {
        try {

            let response = await axios.get(route('web.todos_estados.index'));

            if (response.status === 200) {
                this.setState({
                    ...this.state, estadosOptions: response.data
                });
            }

        } catch (e) {
            console.log(e);
        }
    }

    loadCidades = async () => {
        try {

            if (this.state.estados.length === 0) {
                return;
            }

            this.setLoading(true);

            let siglasEstados = this.state.estados.map(estado => estado.value);


            let response = await axios.post(route('web.cidades_por_estado.index'), {
                estados: siglasEstados
            });

            if (response.status === 200) {
                this.setState({
                    ...this.state, cidadesOptions: response.data
                });
            }

        } catch (e) {
            console.log(e);
        } finally {
            this.setLoading(false);
        }
    }





    componentDidMount = async () => {


        try {
            Promise.all([this.loadAuthUser(), this.loadEstados(),]);
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Validação do formulário
     */
    validate = async () => {

        let errors = {};

        if (!this.state.periodo_inicial) {
            errors['periodo_inicial'] = ['O campo período inicial é obrigatório'];
        }


        if (!this.state.periodo_final) {
            errors['periodo_final'] = ['O campo período final é obrigatório'];
        }


        if (this.state.tipos.length === 0) {
            errors['tipos'] = ['O campo tipo é obrigatório'];
        }

        if (!this.state.tipo_detalhamento) {
            errors['tipo_detalhamento'] = ['O campo tipo de detalhamento é obrigatório'];
        }

        if (Object.keys(errors).length > 0) {
            await this.setState({
                ...this.state,
                errors: errors
            });

            throw new Error('Validation Error');
        }


    }


    submit = async () => {

        try {

            await this.validate();

            this.setState({
                ...this.state, errors: [], rows: []
            });


            this.setLoading(true);



            let payload = this.getPayloadForRequest();

            let rows = [];

            //resposta é um excel

            // let response = await axios.post(`${reportsServiceBaseUrl}/ranking-cidades/gerar`, payload, this.getRequestHeader(), {
            //     responseType: 'arraybuffer'
            // } );

            let response = await axios.post(`${reportsServiceBaseUrl}/ranking-cidades/gerar`, payload, this.getRequestHeader());

            if (response.status === 200) {

                this.setState({
                    ...this.state,
                    rows: rows,
                    response_tipo_detalhamento: payload.tipo_detalhamento,
                    submited: true
                });

                if (response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    const type = response.headers['content-type']
                    const blob = new Blob([response.data])
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)

                    console.log(response.headers);
                    link.download = response.headers['x-filename'];

                    link.click();


                    link.remove();
                    window.document.body.removeChild(link);

                    URL.revokeObjectURL(link.href);

                    return;
                }

            }

        } catch (e) {
            console.log(e);

            if (e.response && e.response.status === 422) {

                let errors = e.response.data.errors;


                this.setState({
                    ...this.state, errors: errors
                });

            }


        } finally {
            this.setLoading(false);
            this.setState({
                ...this.state,
                submited: true
            });
        }
    }



    getPayloadForRequest = () => {
        let payload = {};

        payload.periodo_inicial = this.state.periodo_inicial;
        payload.periodo_final = this.state.periodo_final;

        payload.tipos = this.state.tipos.map(tipo => tipo.value);
        payload.estados = this.state.estados.map(estado => estado.value);
        payload.cidades = this.state.cidades.map(cidade => cidade.value);
        payload.tipo_relatorio = this.state.tipo_relatorio.value;
        payload.tipo_detalhamento = this.state.tipo_detalhamento.value;

        return payload;
    }


    render() {

        return (<div className="card">
            {this.state.loading && <Loader />}

            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Relatório Ranking - Cidades
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <form action="">
                                <div className="form-group row">
                                    <div className="col-4">

                                        <label htmlFor="status">Período Inicial</label>
                                        <DatePicker
                                            locale="pt-BR"
                                            name="periodo_inicial"
                                            className="form-control"
                                            //dateFormat="dd/MM/yyyy"
                                            showMonthYearPicker
                                            autoComplete="off"
                                            selected={this.state.periodo_inicial}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, periodo_inicial: e
                                                });
                                            }}
                                        />
                                        {this.state.errors['periodo_inicial'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['periodo_inicial'][0]}
                                            </div>}

                                    </div>
                                    <div className="col-4">

                                        <label htmlFor="status">Período Final</label>
                                        <DatePicker
                                            locale="pt-BR"
                                            name="periodo_final"
                                            autoComplete="off"
                                            className="form-control"
                                            //dateFormat="dd/MM/yyyy"
                                            showMonthYearPicker
                                            selected={this.state.periodo_final}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, periodo_final: e
                                                });
                                            }}
                                        />
                                        {this.state.errors['periodo_final'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['periodo_final'][0]}
                                            </div>}

                                    </div>

                                    <div className="col-4">
                                        <label htmlFor="status">Tipo</label>
                                        <Select
                                            isMulti
                                            name="tipo"
                                            closeMenuOnSelect={false}
                                            options={this.state.tiposOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione os Tipos"
                                            value={this.state.tipos}
                                            onChange={async (e) => {

                                                let value = e;
                                                await this.setState({
                                                    ...this.state, tipos: e || []
                                                });


                                            }}></Select>

                                        {this.state.errors['tipos'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['tipos'][0]}
                                            </div>}
                                    </div>

                                </div>

                                <div className="form-group row">
                                    <div className="col-sm-6 col-md-6 col-12">
                                        <label htmlFor="estado">Estado</label>
                                        <Select
                                            name="estado"
                                            closeMenuOnSelect={false}
                                            isMulti
                                            options={this.state.estadosOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione o tipo de relatório"
                                            value={this.state.type}
                                            inputValue={this.state.inputEstadoValue ?? ""}
                                            onInputChange={(input) => {
                                                this.setState({ inputEstadoValue: input });
                                            }}
                                            filterOption={(option, input) => {
                                                if (!input) return true;

                                                const searchTerms = input.split(";").map(term => term.trim().toLowerCase());

                                                const lastTermIsEmpty = searchTerms[searchTerms.length - 1] === "";

                                                return searchTerms.some(term => lastTermIsEmpty ? option.label?.toLowerCase() == term : option.label?.toLowerCase().includes(term)
                                                );

                                            }}
                                            onChange={async (e) => {
                                                let value = e;

                                                if (e === null) {
                                                    value = [];
                                                }

                                                await this.setState({
                                                    ...this.state, estados: value
                                                });

                                                this.loadCidades();
                                            }}></Select>

                                        {this.state.errors['estados'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['estados'][0]}
                                            </div>}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-12">
                                        <label htmlFor="cidades">Cidades</label>
                                        <Select
                                            name="cidades"
                                            closeMenuOnSelect={false}
                                            isMulti
                                            options={this.state.cidadesOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione as cidades"
                                            value={this.state.cidades}
                                            inputValue={this.state.inputCidadeValue ?? ""}
                                            onInputChange={(input) => {
                                                this.setState({ inputCidadeValue: input });
                                            }}
                                            filterOption={(option, input) => {
                                                if (!input) return true;

                                                const searchTerms = input.split(";").map(term => term.trim().toLowerCase());

                                                const lastTermIsEmpty = searchTerms[searchTerms.length - 1] === "";

                                                return searchTerms.some(term => lastTermIsEmpty ? option.label?.toLowerCase() == term : option.label?.toLowerCase().includes(term)
                                                );

                                            }}
                                            onChange={async (e) => {
                                                let value = e;

                                                if (e === null) {
                                                    value = [];
                                                }

                                                await this.setState({
                                                    ...this.state, cidades: value
                                                });
                                            }}></Select>

                                        {this.state.errors['cidades'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['cidades'][0]}
                                            </div>}
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-6">
                                        <label htmlFor="status">Análise Por</label>
                                        <Select
                                            name="tipo_detalhamento"
                                            options={this.state.tipoDetalhamentoOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione o tipo de detalhamento"
                                            value={this.state.tipo_detalhamento}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, tipo_detalhamento: e
                                                });
                                            }}></Select>

                                        {this.state.errors['tipo_detalhamento'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['tipo_detalhamento'][0]}
                                            </div>}
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor='detalhes_para_exibir'>Tipo de Relatório</label>
                                        <Select
                                            name="detalhes_para_exibir"
                                            options={this.state.tipo_relatorio_options}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione os detalhes"
                                            value={this.state.tipo_relatorio}
                                            onChange={async (e) => {
                                                let value = e;
                                                if (e === null) {
                                                    value = [];

                                                }
                                                await this.setState({
                                                    ...this.state, tipo_relatorio: value
                                                });
                                            }}></Select>
                                        {this.state.errors['tipo_relatorio'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['tipo_relatorio'][0]}
                                            </div>}

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button className={"btn btn-primary button-brain btn-round btn-block"}
                                            onClick={async () => {
                                                await this.submit();
                                            }} type="button">Gerar Relatório
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>);
    }
}


if (document.getElementById('js-form-ranking-report')) {
    ReactDOM.render(<RelatorioRankingCidadeForm />, document.getElementById('js-form-ranking-report'));
}
