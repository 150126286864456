import React from 'react';

import ReactDOM from 'react-dom';
import InnerLoading from "../Viabilidade/InnerLoading";

class Anexos extends React.Component {

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        this.state = {
            anexos: [],
            base_id: 0,
            loading: false,
            errors: {},
            files: [],
            showFeedBack: false
        }
    }



    setLoading = async (loading) => {
        this.setState({
            loading
        });
    }

    componentDidMount = async ()=> {


        const baseId = route().params.id;

        console.log( route().params );

        console.log(baseId);

        await this.setState({
            base_id: baseId,
        });

        this.ref =

        await this.getAnexos();
    }

    upload = async ( event )=>{

        event.preventDefault();

        this.setState({
            ...this.state,
            errors: {},
            showFeedBack: false
        });

        try{
            await this.setLoading(true);

            const rota = route('auditoria-anexar-arquivo', this.state.base_id );

            const formData = new FormData();

            for (let i = 0; i < this.state.files.length; i++) {
                formData.append('anexos[]', this.state.files[i]);
            }

            let response = await axios.post(rota, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                }
            });

            this.setState({
                anexos: response.data.anexos,
                files: [],
                showFeedBack: true
            });

            this.inputRef.current.value = '';

        }catch (e) {
            console.log('erro ao enviar arquivos');
            console.log(e);
            console.log(e?.response );
            if( e.response && e.response.status === 422 ){
                let errors = e.response.data.errors;

                this.setState({
                    errors
                });
            }
        }
        finally {
            await this.setLoading(false);
        }


    }

    render() {
        return (
            <div>
                {this.state.loading && <InnerLoading />}
                {this.state.showFeedBack && <div className="alert alert-success alert-dismissible fade show">Anexos salvos com sucesso</div>}
                <form>
                    <div className="form-group row">
                        <div className="col-12">
                            <label htmlFor="follow_up">Anexos</label>
                            <input type="file" multiple className="form-control-file"
                                   onChange={(e) => this.setState({files: Array.prototype.slice.call(e.target.files)})}
                                   name="anexos[]"
                                   ref={this.inputRef}
                            />
                            {this.state.errors.anexos && <div className="invalid-feedback d-block">
                                {this.state.errors.anexos[0]}
                            </div>}
                        </div>
                    </div>

                    <a className="btn btn-default btn-block" onClick={this.upload}>
                        Salvar Anexos
                    </a>
                </form>

                <div className="card">
                    <div className="card-header">
                        <h2 className="card-title">Arquivos Anexados</h2>
                    </div>
                    <div className="card-body">
                        <ul className="list-group">
                            {this.state.anexos.map(( anexo, index )=>{
                                return (
                                    <li className="list-group-item" key={index}>
                                        <div className="pull-left">
                                            {anexo.name} <br/>
                                            <i className="fa fa-clock-o"></i>
                                            &nbsp;<small>{anexo.created_at}</small>
                                        </div>
                                        <div className="pull-right">
                                            <a className="btn btn-success btn-sm" target="_blank"
                                               href={anexo.url}>
                                                <i className="fa fa-file-download"></i>
                                            </a>
                                        </div>
                                    </li>
                                );
                            })}

                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    getAnexos = async () => {
        try{
            const response = await axios.get( route('auditoria.bases_anexos', this.state.base_id) );

            this.setState({
                anexos: response.data
            });
        }catch (e){
            console.log(e);
        }
        finally {

        }
    }
}

if (document.querySelector('.js-anexos-auditoria')) {
    ReactDOM.render(<Anexos />, document.querySelector('.js-anexos-auditoria'));
}
