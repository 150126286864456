import React from 'react';
import {Dialog} from "primereact/dialog";
import {Dropdown} from 'primereact/dropdown';
import {InputSwitch} from 'primereact/inputswitch';


export default class ModalCadastroEdicaoFiltro extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sub_categorias: '',
        }
    }

    async componentDidMount() {
    }

    render() {

        const acao = this.props.filtro?.id ? 'Editar Filtro' : 'Cadastrar Filtro';

        return (<>
            <Dialog
                header={acao}
                visible={this.props.show}
                style={{width: '50vw', marginTop: '50px'}}
                onHide={this.props.handleClose}
                blockScroll={true}
            >

                <div className="card">
                    <div className="card-body">
                        <div className="form-group row">
                            <div className="col-12">
                                <label>Filtro</label>
                                <input type="text"
                                        name="sub_categoria"
                                        value={this.props.filtro?.sub_categoria}
                                        onChange={this.props.handleChange}
                                        className="form-control"
                                        placeholder="Filtro"
                                />
                                {this.props.errors?.sub_categoria && <div className="feedback text-danger d-block">{this.props.errors.sub_categoria[0].replace('sub categoria', '')}</div>}
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-12">
                                <button className="btn btn-success btn-sm btn-block"
                                        onClick={this.props.handleSave}>Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>);
    }
}
const styles = {
    legend: {
        padding: '5px',
        margin: '4px',
    }
}

