/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/App');
require('./components/Mapeamento/MapeamentoLinks/Index');
require('./components/Mapeamento/Qualidade/Index');
require('./components/Mapeamento/ProdutividadeReport/Index');
require('./components/Mapeamento/MapeamentoWorker/Index');
require('./components/Mapeamento/Produtividade/Index');
require('./components/Mapeamento/ProdutividadeGerencial/Index');
require('./components/Mapeamento/Index');
require('./components/MapaComparativo/Index');
require('./components/Atachments/Index');

require('./components/Atachments/Index');
require('./components/RegioesAdministrativas/Index');
require('./components/RegioesAdministrativas/Cidades/Index');

require('./components/MapaComparativo/Index');
require('./components/DivulgacaoMelhorias/Index');


require('./components/AnaliseTemporalV2/Index');
require('./components/ReportLocationBuilding/Index');



require('./components/MapaComparativo/Index');

require('./components/AppAnuncios');
require('./components/HistoricUpdateForm');
require('./components/FormTicketSuport');
require('./components/TicketsList')
require('./components/TicketSuport');
require('./components/MapaRevendaLocacao/DefaulMap');
require('./components/MapaFornecedor/DefaulMap');
require('./components/ReferenciasCub/ReferenciasCub');
require('./components/GeoReferenciamentoMapa/DefaulMap');
require('./components/GeoReferenciamento/Index');

require('./components/TableAuditLot/TableAuditLotsIndex');
require('./components/TableAuditLot/FormTableAuditLot');
require('./components/TableAuditLot/TableAuditLotsBasesIndex');
require('./components/TableAuditLot/FormReport');
require('./components/UpdatePointConfig/UpdatePointConfig');
require('./components/UpdatePointsReport/UpdatePointsReportForm');
require('./components/RelatorioAcompanhamento/RelatorioAcompanhamentoForm');
require('./components/Viabilidade/GruposViabilidade');
require('./components/Viabilidade/GrupoViabilidadeAnalises');
require('./components/Viabilidade/GrupoForm');
require('./components/RelatorioRankingCidades/RelatorioRankingCidadeForm');



require('./components/Viabilidade/AnaliseForm');
require('./components/BuscaGeolocalizacao/BuscaGeolocalizacao');

require('./components/ColetaPolosGeradores/ColetaPolosGeradores');
require('./components/Auditoria/Anexos');
require('./components/PolosGeradores/Listagem');
require('./components/PolosGeradores/Filtros/Listagem');
require('./components/RelatorioRevendaLocacao/RelatorioRevendaLocacao');
