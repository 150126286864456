import React from 'react';
import ReactDOM from "react-dom";
import Loader from "../../Loader";
import {ToastContainer, toast} from 'react-toastify';
import {OverlayPanel} from "primereact/overlaypanel";
import ModalRemocaoMassa from "../ModalRemocaoMassa";
import ModalCadastroEdicaoFiltro from "./ModalCadastroEdicaoFiltro";


class Listagem extends React.Component {


    constructor() {
        super();
        this.state = {
            filtros: [],
            loading: false,
            pagination: {
                total: 0,
                per_page: 20,
                current_page: 1,
                last_page: 1,
                from: 1,
                to: 0
            },
            filters: {},
            sub_categorias: [],
            currentEditing: null,
            showEditModal: false,
            errors: {},
            ids_selecionados: [],
            show_modal_remocao_em_grupo: false,
            id_delete: null,
            novo_filtro: {
                sub_categoria_id: null,
            }
        }
    }


    async componentDidMount() {
        await Promise.all([
            this.loadFiltros(),
            this.getSubCategorias()
        ]);
    }

    loadFiltros = async () => {
        try {
            await this.setLoading(true);
            const response = await axios.get(route('polos-geradores-filtros.index'));

            this.setState({
                ...this.state,
                filtros: response.data.data,
            });
        } catch (e) {
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    openModalEdit = async () => {
        this.setState({
            showEditModal: true
        });
    }

    closeModalEdit = async () => {
        this.setState({
            showEditModal: false
        });
    }

    getSubCategorias = async () => {
        try {
            try {

                const response = await axios.get(route('web.polos_geradores_subcategorias.index'));

                this.setState({
                    sub_categorias: response.data
                });
            } catch (e) {
                console.log(e);
            }
        } catch (e) {
            console.log(e);
        }
    }

    handleSave = async () => {
        try {

            this.setState({
                errors: {}
            });

            await this.setLoading(true);

            if( this.state.currentEditing.id ) {
                let response = await axios.put(route('polos-geradores-filtros.update', this.state.currentEditing.id), this.state.currentEditing);
            }else{
                let response = await axios.post(route('polos-geradores-filtros.store'), this.state.currentEditing);
            }

            toast.success('Filtro Salvo com sucesso!');
            await this.closeModalEdit();
            await this.loadFiltros();

        } catch (e) {
            if (e.response && e.response.status === 422) {
                this.setState({
                    errors: e.response.data.errors
                });
            }
        } finally {
            await this.setLoading(false);
        }
    }

    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }

    setFilterValue = async (e) => {

        console.log(e.target.name);

        await this.setState({
            filters: {
                ...this.state.filters,
                [e.target.name]: e.target.value,

            },
            pagination: {
                ...this.state.pagination,
                current_page: 1
            }
        });
    }

    handleChange = async (e) => {
        await this.setState({
            currentEditing: {
                ...this.state.currentEditing,
                [e.target.name]: e.target.value
            }
        });
    }

    prepareFiltersForRequest = () => {
        let filters = {};

        for (let key in this.state.filters) {
            let value = this.state.filters[key];

            if (!value) {
                continue;
            }

            if (typeof value === 'string') {
                filters[key] = value;
            }

            if (Array.isArray(value)) {
                filters[key] = value;
            }
        }

        filters.page = this.state.pagination.current_page || 1;

        return filters;
    }

    handleCheck = async (e) => {
        let id = parseInt(e.target.value);

        if (e.target.checked) {
            await this.setState({
                ids_selecionados: [...this.state.ids_selecionados, id]
            });
        } else {
            await this.setState({
                ids_selecionados: this.state.ids_selecionados.filter((item) => item !== id)
            });
        }
    }

    checkAll = async (e) => {
        if (e.target.checked) {
            let ids = this.state.filtros.map((filtro) => filtro.id);
            await this.setState({
                ids_selecionados: ids
            });
        } else {
            await this.setState({
                ids_selecionados: []
            });
        }
    }


    showModalDelecaoEmGrupo = async () => {
        this.setState({
            show_modal_remocao_em_grupo: true
        });
    }

    closeModalRemocaoEmGrupo = async () => {
        this.setState({
            show_modal_remocao_em_grupo: false
        });
    }

    formatIntegerNumber = (number) => {
        return new Intl.NumberFormat('pt-BR').format(number);
    }

    deleteFiltro = async () => {
        try {
            await this.setLoading(true);

            const response = await axios.delete(route('polos-geradores-filtros.destroy', this.state.id_delete));

            if (response.status === 204) {
                toast.success('Filtro Removido com sucesso!');
                this.op.hide();
                await this.loadFiltros();

                this.setState({
                    ...this.state,
                    id_delete: null
                });
            }

        } catch (e) {
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    handleRemocaoEmGrupo = async () => {
        try {
            await this.setLoading(true);

            const response = await axios.post(route('polos-geradores-filtros.destroy_many'), {
                ids: this.state.ids_selecionados
            });

            if (response.status === 204) {
                toast.success(response.data.message);

                this.setState({
                    ids_selecionados: []
                });

                await this.closeModalRemocaoEmGrupo();
                await this.loadFiltros();
            }

        } catch (e) {
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    render() {
        return (
            <div className="card">

                {this.state.loading && (
                    <Loader/>
                )}

                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{width: "50%", zIndex: 9999999, fontWieght: 'bold'}}
                />

                <ModalCadastroEdicaoFiltro
                    show={this.state.showEditModal}
                    handleClose={this.closeModalEdit}
                    handleChange={this.handleChange}
                    handleSave={this.handleSave}
                    errors={this.state.errors}
                    filtro={this.state.currentEditing}
                />

                <ModalRemocaoMassa
                    show={this.state.show_modal_remocao_em_grupo}
                    handleClose={this.closeModalRemocaoEmGrupo}
                    ids_selecionados={this.state.ids_selecionados}
                    handleSave={this.handleRemocaoEmGrupo}
                    errors={this.state.errors}
                />

                <div className="card-header">
                    Gerenciamento de Filtros de Polos Geradores

                    <div className="float-right">
                        <button className="btn btn-sm btn-success" onClick={async (e) => {
                            e.preventDefault();
                            this.setState({
                                currentEditing: {
                                    sub_categoria: '',
                                }
                            });
                            await this.openModalEdit();
                        }}>
                            <i className="fa fa-plus"></i> &nbsp;
                            Novo Filtro
                        </button>
                    </div>
                </div>

                <div className="card-body">
                    <form>
                        <div className="form-group row">
                            <div className="col-12 col-md-12 col-sm-12 col-lg-12">
                                <label htmlFor="title">Filtro</label>
                                <input type="text" id="sub_categoria" name="sub_categoria"
                                       onChange={this.setFilterValue}
                                       value={this.state.filters?.sub_categoria}
                                       className="form-control"/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                                <button className="btn btn-sm btn-block btn-success" onClick={async (e) => {
                                    e.preventDefault();
                                    await this.loadPolos();
                                }}>
                                    <i className="fa fa-search"></i> &nbsp;
                                    Pesquisar
                                </button>
                            </div>

                            <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                                <button className="btn btn-sm btn-block btn-primary" onClick={async (e) => {
                                    e.preventDefault();
                                    await this.cleanFiltros();
                                }}>
                                    <i className="fa fa-eraser"></i> &nbsp;
                                    Limpar Filtros
                                </button>
                            </div>
                        </div>
                    </form>
                    <hr/>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="text-muted p-1">
                            Exibindo
                            de {this.formatIntegerNumber(this.state.pagination.from)} até {this.formatIntegerNumber(this.state.pagination.to)} de {this.formatIntegerNumber(this.state.pagination.total)} registros
                        </p>

                        <div>

                        </div>

                        <div>

                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button"
                                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                        disabled={this.state.ids_selecionados.length === 0}
                                        aria-expanded="false">
                                    Ações em Grupo
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" onClick={this.showModalDelecaoEmGrupo}>
                                        <i className="fa fa-trash"></i> &nbsp;
                                        Excluir
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <OverlayPanel
                        appendTo={document.body}
                        ref={(el) => this.op = el}>
                        <p>
                            Tem certeza que deseja excluir esse registro?
                        </p>
                        <button className="btn btn-danger btn-sm"
                                onClick={async (e) => {
                                    await this.deleteFiltro();
                                }}
                        >Sim
                        </button>
                        &nbsp;
                        <button className="btn btn-default btn-sm"
                                onClick={(e) => this.op.hide(e)}
                        >Cancelar
                        </button>
                    </OverlayPanel>


                    <div style={{overflowY: "auto"}}>
                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr style={{backgroundColor: '#5B7537', color: 'white'}}>
                                <th style={{color: 'white'}}>
                                    <input type="checkbox"
                                           className="checkbox"
                                           checked={this.state.ids_selecionados.length > 0 && this.state.ids_selecionados.length === this.state.filtros.length}
                                           onClick={this.checkAll}/>
                                </th>
                                <th style={{color: 'white'}}>Filtro</th>
                                <th style={{color: 'white'}}>Ações</th>
                            </tr>
                            </thead>

                            <tbody>
                            {this.state.filtros.map((filtro, index) => {
                                return (
                                    <tr key={filtro.id}>
                                        <td style={{width: '5%'}}>
                                            <input type="checkbox"
                                                   name="ids_selecionados"
                                                   className="checkbox"
                                                   checked={this.state.ids_selecionados.includes(filtro.id)}
                                                   onChange={this.handleCheck}
                                                   value={filtro.id}/>
                                        </td>
                                        <td style={{width: '85%'}}>{filtro.sub_categoria}</td>
                                        <td style={{width: '10%'}}>
                                            <div
                                                className="d-flex justify-content-between align-items-center align-content-center ">
                                                <a href="#" className="btn btn-sm btn-success"
                                                   onClick={async (e) => {
                                                       e.preventDefault();

                                                       await this.setState({
                                                           currentEditing: filtro
                                                       });

                                                       await this.openModalEdit();
                                                   }}
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </a>
                                                &nbsp;

                                                <button className="btn btn-sm btn-danger" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        id_delete: filtro.id
                                                    });
                                                    this.op.toggle(e);
                                                }}>
                                                    <i className="fa fa-trash"></i>
                                                </button>


                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>

                        </table>
                    </div>

                </div>

                <div className="card-footer">
                    <nav>
                        <ul className="pagination d-flex justify-content-center align-items-center">
                            <li className="page-item">
                                {this.state.pagination.current_page > 1 &&
                                    <a href="#" className="page-link" onClick={async (e) => {
                                        e.preventDefault();
                                        this.setState({
                                            pagination: {
                                                ...this.state.pagination,
                                                current_page: this.state.pagination.current_page - 1
                                            }
                                        })
                                        await this.loadFiltros();
                                    }}>
                                        Anterior
                                    </a>}
                            </li>
                            <li className="page-item">
                                {this.state.pagination.current_page < this.state.pagination.last_page &&
                                    <a href="#" className="page-link" onClick={async (e) => {
                                        e.preventDefault();
                                        this.setState({
                                            pagination: {
                                                ...this.state.pagination,
                                                current_page: this.state.pagination.current_page + 1
                                            }
                                        })
                                        await this.loadFiltros();
                                    }}>
                                        Próxima
                                    </a>}
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }

    async cleanFiltros() {
        await this.setState({
            filters: {}
        });

        await this.loadFiltros();
    }
}


if (document.getElementById('js-polos-geradores-filtros-gerenciamento')) {
    ReactDOM.render(<Listagem/>, document.getElementById('js-polos-geradores-filtros-gerenciamento'));
}

