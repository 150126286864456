// noinspection LanguageDetectionInspection

import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import MultiSelect from "react-multi-select-component";
import AsyncSelect from 'react-select/async';
import IntlCurrencyInput from "react-intl-currency-input";
import Axios from 'axios';

export default function FilterByType(props){

  const statusArray = [
    { value: 'Planta', label: 'Planta' },
    { value: 'Construção', label: 'Construção' },
    { value: 'Pronto', label: 'Pronto' },
  ]

  const padraoArray = [
    { value: 'Compacto', label: 'Compacto' },
    { value: 'Econômico', label: 'Econômico' },
    { value: 'Standard', label: 'Standard' },
    { value: 'Médio', label: 'Médio' },
    { value: 'Alto', label: 'Alto' },
    { value: 'Luxo', label: 'Luxo' },
    { value: 'Super Luxo', label: 'Super Luxo' },
  ]

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const [ listaMateriais, setListaMateriais ] = useState()

  useEffect( async () => {
    const response = await Axios.get('/supplies-basic')
    console.log(response.data)
    setListaMateriais(changeArray(response.data))
  }, [])

  function validate(){
    /* if(props.analise === 'Por Incorporadora' && props.incorporators == 0){
      alert("É necessário ao menos uma Incorporadora")
      return false
    } */
    if((props.analise === 'Por fase de obra' || props.analise === 'Por Incorporadora') && props.material == 0){
      alert("É necessário ao menos um material")
      return false
    }
    return true
  }

  function handleTicketMaximoChange(event, value, maskedValue){
    event.preventDefault();

    props.handleTicketMaximoChanged(value);
  };

  function changeArray(v){
    console.log('hello')
    let array = [];
    v.forEach(v => array.push({value: v, label: v}))

    console.log(array)

    return array;
  }

  function handleTicketMinimoChange(event, value, maskedValue){
    event.preventDefault();

    props.handleTicketMinimoChanged(value);
  };

  /**
   * @param {string} inputQuery
   */
  const searchIncorporadora = async (inputQuery) => {
    if (inputQuery.length >= 2){
      const response = await Axios.get('/autocomplete-incorporator-with-id?search=' + inputQuery);
      let incorporators =  response.data;

      let array = [];

      incorporators.forEach(incorporator => {
        array.push({value: incorporator.id, label: incorporator.text})
      });

      console.log( array );

      return array;
    }
  }



  const searchMaterial = async (inputQuery) => {
    if (inputQuery.length >= 2){
      const response = await Axios.get('/supplies-basic?description=' + inputQuery);
      console.log('response', response.data)
      return changeArray(response.data);
    }
  }

  return(
    <div className='filter-type'>
      <h3>{props.analise}</h3>
      <div className='fieldView'>
        <div className='selectView'>
          <div className="form-group row">
            <div className="col-md-6 col-sm-6 col-lg-6">
              <label className="label-fornecedor">Área Mínima:</label>
              <input
                type="number"
                name="area_minima"
                value={props.area_minima}
                onChange={ props.handleAreaMinimaChanged }
                style={{ padding: "3px" }}
                className="form-control input-round"
              />
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6">
              <label className="label-fornecedor">Área Máxima:</label>
              <input
                type="number"
                name="area_maxima"
                value={props.area_maxima}
                onChange={ props.handleAreaMaximaChanged }
                style={{ padding: "3px" }}
                className="form-control input-round"
              />
            </div>
          </div>
        </div>
        <div className='selectView'>
          <div className="form-group row">
            <div className="col-md-6 col-sm-6 col-lg-6">
                <label className="label-fornecedor">Ticket Mínimo:</label>
                <IntlCurrencyInput
                  currency="BRL"
                  config={currencyConfig}
                  onChange={handleTicketMinimoChange}
                  style={{ padding: "3px" }}
                  value={props.ticket_maior_que}
                  className="valor form-control input-round"
                />
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6">
                <label className="label-fornecedor">Ticket Máximo:</label>
                <IntlCurrencyInput
                  currency="BRL"
                  config={currencyConfig}
                  onChange={handleTicketMaximoChange}
                  style={{ padding: "3px" }}
                  value={props.ticket_menor_que}
                  className="valor form-control input-round"
                />
            </div>
          </div>
        </div>
        <div className='selectView'>
          <label className="label-fornecedor">Padrão:</label>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: 20,
              }),
              /* option: (defaultStyles, state) => ({
                  ...defaultStyles,
                  color: state.isSelected&& "#fff",
                  backgroundColor: state.isSelected&& "#5B7537",
              }), */
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#F2F2F2',
                primary: '#5B7537',
              },
            })}
            placeholder="Selecione"
            options={padraoArray}
            value={props.pattern}
            onChange={props.handlePatternChanged}
            isMulti={false}
          />
        </div>
        <div className='selectView'>
          <label className="label-fornecedor">Bairro:</label>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: 20,
              }),
              /* option: (defaultStyles, state) => ({
                  ...defaultStyles,
                  color: state.isSelected&& "#fff",
                  backgroundColor: state.isSelected&& "#5B7537",
              }), */
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#F2F2F2',
                primary: '#5B7537',
              },
            })}
            placeholder="Selecione"
            options={ props.bairroOptions }
            value={props.bairro}
            onChange={props.handleBairrosChanged}
            isMulti={true}
          />
        </div>
      </div>
      <div className='fieldView'>
        <div className='selectView'>
          <div className='fornecedor-label-row'>
            <label className="label-fornecedor">Incorporadora:</label>
            {/* { props.analise === 'Por Incorporadora'&&<div className='fornecedor-obrigatorio'>*</div> } */}
          </div>
          <AsyncSelect
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: 20,
              }),
              /* option: (defaultStyles, state) => ({
                  ...defaultStyles,
                  color: state.isSelected&& "#fff",
                  backgroundColor: state.isSelected&& "#5B7537",
              }), */
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#F2F2F2',
                primary: '#5B7537',
              },
            })}
            placeholder="Selecione"
            cacheOptions
            defaultOptions
            isMulti
            value={props.incorporators}
            loadOptions={searchIncorporadora}
            onChange={props.handleIncorporadoraChanged}
          />
        </div>
        <div className='selectView'>
          <label className="label-fornecedor">Fase de obra:</label>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: 20,
              }),
              /* option: (defaultStyles, state) => ({
                  ...defaultStyles,
                  color: state.isSelected&& "#fff",
                  backgroundColor: state.isSelected&& "#5B7537",
              }), */
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#F2F2F2',
                primary: '#5B7537',
              },
            })}
            placeholder="Selecione"
            options={statusArray}
            value={props.empStatus}
            onChange={props.handleEmpStatusChanged}
            isMulti={false}
          />
        </div>
        <div className='selectView'>
          <div className='fornecedor-label-row'>
            <label className="label-fornecedor">Material:</label>
            { (props.analise === 'Por fase de obra' || props.analise === 'Por Incorporadora')&&<div className='fornecedor-obrigatorio'>*</div> }
          </div>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: 20,
              }),
              /* option: (defaultStyles, state) => ({
                  ...defaultStyles,
                  color: state.isSelected&& "#fff",
                  backgroundColor: state.isSelected&& "#5B7537",
              }), */
              menu: (baseStyles, state) => ({
                ...baseStyles,
                height: 180,
              }),
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                height: 180,
              }),
              menuPortal: (baseStyles, state) => ({
                ...baseStyles,
                height: 180,
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#F2F2F2',
                primary: '#5B7537',
              },
            })}
            placeholder="Selecione"
            /* cacheOptions
            defaultOptions */
            isMulti
            options={listaMateriais}
            value={props.material}
            //loadOptions={searchMaterial}
            onChange={props.handleMaterialChanged}
          />
        </div>
      </div>
      <div className='btn-view'>
        <button
          className="btn btn-sm btn-round btn-filter"
          style={{
            marginTop: "15px",
            backgroundColor: "#5B7537",
            borderColor: "#5B7537",
          }}
          onClick={() => {
            if(validate()){
              props.openSupplyFilter()
            }
          }}
        >
          Filtrar
        </button>
        <button
          className="btn btn-sm btn-round btn-filter"
          style={{
            marginTop: "15px",
            backgroundColor: "#5B7537",
            borderColor: "#5B7537",
          }}
          onClick={props.limparFiltro}
        >
          Limpar
        </button>
      </div>
    </div>
  )
}
