import React, { Component } from "react";
import Axios from "axios";
export default class TableBasico extends Component{

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            data: [
                {material: 'CHAPA COMPENSADO PLASTIFICADO 18MM', unit: 'm²', coef: [0.69418, 1.30138, 1.30138, 1.09966, 1.09966, 1.43118, 1.43118], },
                {material: 'AÇO CA-50A D=10 mm', unit: 'kg', coef: [7.68513, 21.90724, 21.90724, 21.57696, 21.57696, 34.24481, 34.24481], },
                {material: 'CONCRETO FCK=25 MP', unit: 'm³', coef: [0.09129, 0.22751, 0.22751, 0.22796, 0.22796, 0.35924, 0.35924], },
                {material: 'CIMENTO CP - 32 II', unit: 'kg', coef: [40.90577, 65.42524, 65.42524, 63.39347, 63.39347, 67.16461, 67.16461], },
                {material: 'AREIA MÉDIA', unit: 'm³', coef: [0.12619, 0.20571, 0.20571, 0.19901, 0.19901, 0.20588, 0.20588], },
                {material: 'BRITA 2', unit: 'm³', coef: [0.00000, 0.02887, 0.02887, 0.02512, 0.02512, 0.89596, 0.89596], },
                {material: 'BLOCO CERÂMICA VEDAÇÃO 9 x 19 x 19cm', unit: 'Un', coef: [0.00000, 62.26067, 62.26067, 58.2113, 58.2113, 53.27997, 53.27997], },
                {material: 'BLOCO CONCRETO sem função estrutural 19 x 19 x 39 cm', unit: 'Un', coef: [14.39994, 0.80399, 0.80399, 0.46005, 0.46005, 0.73841, 0.73841], },
                {material: 'TELHA ONDULADA DE FIBROCIMENTO ESP.= 6mm', unit: 'm²', coef: [0.20309, 0.12428, 0.12428, 0.06424, 0.06424, 0.09286, 0.09286], },
                {material: 'PORTA INTERNA SEMI-OCA P/ PINTURA', unit: 'Un', coef: [0.16744, 0.15533, 0.15533, 0.15405, 0.15405, 0.14744, 0.14744], },
                {material: 'ESQUADRIA DE CORRER - 4 FOLHAS - 2,00 x 1,40 m', unit: 'm²', coef: [0.00000, 0.08054, 0.08054, 0.08308, 0.08308, 0.13615, 0.13615], },
                {material: 'JANELA DE CORRER - 2 FOLHAS - 1,20 x 1,20 m', unit: 'm²', coef: [0.18303, 0.04225, 0.04225, 0.03592, 0.03592, 0.02924, 0.02924], },
                {material: 'FECHADURA, TRÁFEGO MODERADO EM FERRO, ACABAMENTO CROMADO - TIPO IV', unit: 'Un', coef: [0.08541, 0.04747, 0.04747, 0.07825, 0.07825, 0.07512, 0.07512], },
                {material: 'PLACA CERÂMICA 30 x 40 cm - PEI II', unit: 'm²', coef: [0.19836, 2.19344, 2.19344, 2.21373, 2.21373, 1.87628, 1.87628], },
                {material: 'BANCADA DE PIA DE MARMORE 2,00 x 0,60 x 0,02 cm', unit: 'Un', coef: [0.03345, 0.01738, 0.01738, 0.01634, 0.01634, 0.00341, 0.00341], },
                {material: 'PLACA DE GESSO LISO 0,60 x 0,60 cm', unit: 'm²', coef: [2.13677, 0.26781, 0.26781, 0.18477, 0.18477, 0.15697, 0.15697], },
                {material: 'VIDRO LISO TRANSPARENTE 4 mm', unit: 'm²', coef: [0.10633, 0.09854, 0.09854, 0.10869, 0.10869, 0.11236, 0.11236], },
                {material: 'TINTA LÁTEX PVA', unit: 'l', coef: [2.57219, 2.08746, 2.08746, 1.64321, 1.64321, 2.95277, 2.95277], },
                {material: 'EMULSÃO ASFÁLTICA IMPERMEABILIZANTE', unit: 'kg', coef: [0.72716, 1.73252, 1.73252, 2.81573, 2.81573, 2.98597, 2.98597], },
                {material: 'FIO DE COBRE ANTICHAMA - 750 V, 2,5 mm2', unit: 'm', coef: [35.19608, 25.94777, 25.94777, 26.21838, 26.21838, 29.52384, 29.52384], },
                {material: 'DISJUNTOR TRIPOLAR 70 A', unit: 'Un', coef: [0.43300, 0.18984, 0.18984, 0.16993, 0.16993, 0.31913, 0.31913], },
                {material: 'BACIA SANITÁRIA COM CAIXA ACOPLADA', unit: 'Un', coef: [0.03687, 0.04182, 0.04182, 0.06221, 0.06221, 0.05474, 0.05474], },
                {material: 'REGISTRO DE PRESSÃO CROMADO D=1/2', unit: 'Un', coef: [0.19898, 0.1922, 0.1922, 0.30837, 0.30837, 0.19882, 0.19882], },
                {material: `TUBO DE FERRO GALVANIZADO COM COSTURA D=2 1/2'`, unit: 'm', coef: [0.24006, 0.12564, 0.12564, 0.07852, 0.07852, 0.30551, 0.30551], },
                {material: 'TUBO DE PVC-R RÍGIDO P/ ESGOTO D=150 mm', unit: 'm', coef: [0.54821, 0.52955, 0.52955, 0.45117, 0.45117, 0.34172, 0.34172], },
            ],
            padroesAndSlugs: [
                {
                    padrao: "PP 4 - baixo",
                    slug: "PP-4"
                },
                {
                    padrao: "R 8 - baixo",
                    slug: "R-8"
                },
                {
                    padrao: "PIS",
                    slug: "PIS"
                },
                {
                    padrao: "R 1 - normal",
                    slug: "R-1"
                },
                {
                    padrao: "R-16",
                    slug: "R-16"
                },
                {
                    padrao: "CAL-8",
                    slug: "CAL-8"
                },
                {
                    padrao: "CSL-8",
                    slug: "CSL-8"
                },
                {
                    padrao: "CSL-16",
                    slug: "CSL-16"
                },
            ]
           
        }


    }

    /* componentDidMount = async ()=> {
        try{
            
            let response = await Axios.get( route('web.supplies.index', {per_page: 200}));            

            if(response.status == 200){

                this.setState({
                    ...this.state,
                    data: response.data.data
                })
            }
        }catch (e) {
            console.log(e);
        }
    } */

    /* download = async () => {
        try{
            
            let response = await Axios.get( route('web.supplies.index', {format: 'excel', per_page: 200}));            

            if (response.status == 200) {
                if( response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
                    const type = response.headers['content-type']
                    const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'lote_basico.xlsx'
                    link.click()
                    
                }
            }
        }catch (e) {
            console.log(e);
        }
    } */

    render(){

        return(
            <div>
                {/* <div className="table-btn-div">
                    <button
                        className="btn btn-sm btn-round btn-filter btn-download"
                        style={{
                            marginTop: "15px",
                            backgroundColor: "#5B7537",
                            borderColor: "#5B7537",
                        }}
                        onClick={() => this.download() }
                    >
                        <i class="fa fa-download"></i>
                    </button>
                </div> */}
                <a
                    className="btn btn-sm btn-round btn-filter btn-download"
                    style={{
                        marginTop: "15px",
                        backgroundColor: "#5B7537",
                        borderColor: "#5B7537",
                    }}
                    role="button"
                    href="https://brain.hqssolucoes.com.br/insumos/coeficiente_cub.xlsx"
                    download
                >
                    <i class="fa fa-download"></i>
                </a>
                <table className="table table-striped table-container">
                    {/* <thead>

                        <tr style={styles.tr_header}>
                            <td colSpan={3}>Lote Básico</td>
                            <td>UN</td>
                            {this.state.padroesAndSlugs.map((padrao, index) => {
                                return <td key={index}>{padrao.padrao}</td>
                            })}
                        </tr>
                    </thead>
                            
                    <tr style={styles.tr_title}>
                        <td colSpan={3}>por m<sup>2</sup> de construção</td>
                        <td></td>
                        {this.state.padroesAndSlugs.map((padrao, index) => {
                            return <td key={index}>COEF.</td>
                        })}
                    </tr>
                    
                    <tr style={styles.tr_subtitle}>
                        <td colSpan={20}>MATERIAIS</td>
                    </tr>

                    {this.state.data.map( (item, index) => {
                        return (
                            <tr key={`supplies_${index}_`}>
                                <td colSpan={3}>{item.description}</td>
                                <td>{item.unit}</td>
                                {this.state.padroesAndSlugs.map((padrao, index) => {
                                    return <td key={`slcb_${index}_`}>{Number(item[padrao.slug]).toFixed(2)}</td>
                                })}
                            </tr>
                        )
                    })} */}
                            

                    <caption className="cub-caption" style={styles.tr_header}>Lote básico de m² de construção</caption>
                    <thead>
                        <tr style={styles.tr_title}>
                            <td></td>
                            <td></td>
                            <td style={{ textAlign: "center" }} colSpan={10}>Coeficiente por padrão</td>
                        </tr>
                        <tr style={styles.tr_title}>
                            <td>Materiais</td>
                            <td>Unidade</td>
                            <td style={{ textAlign: "center" }}>PIS<br/>Econômico</td>
                            <td style={{ textAlign: "center" }}>R-8 Normal<br/>Compacto</td>
                            <td style={{ textAlign: "center" }}>R-8 Normal<br/>Standard</td>
                            <td style={{ textAlign: "center" }}>R-16 Normal<br/>Médio</td>
                            <td style={{ textAlign: "center" }}>R-16 Normal<br/>Alto</td>
                            <td style={{ textAlign: "center" }}>R-16 Alto<br/>Luxo</td>
                            <td style={{ textAlign: "center" }}>R-16 Alto<br/>Super Luxo</td>
 
                        </tr>
                    </thead>
                    {this.state.data.map( (item, index) => {
                        return (
                            <tr key={`supplies_${index}_`}>
                                <td>{item.material}</td>
                                <td style={{ textAlign: 'center' }}>{item.unit}</td>
                                {item.coef.map((padrao, index) => {
                                    return <td key={`slcb_${index}_`} style={{ textAlign: 'right' }}>{padrao}</td>
                                })}
                            </tr>
                        )
                    })}
                </table>
            </div>
        );
    }

}

const styles = {
  tr_header: {
    backgroundColor: "#2e3525",
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
  },
  tr_title: {
    backgroundColor: "#5b7537",
    color: "#fff",
    fontWeight: "bold"
  },
  tr_subtitle: {
    backgroundColor: "#7ca83e",
    color: "#fff",
    fontWeight: "bold"
  },
}
