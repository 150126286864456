import React, { Component } from 'react';
import getBrowserGeolocation from '../util/geolocation';
import MapWithADrawingManager from './MapWithADrawingManager'
import ImoveisService from '../services/ImoveisService';
import SlidingPaneEmpreendimento from './SlidingPanelEmpreendimento';
import colors from '../util/colors';
import lodash from 'lodash';
import FormFilters from '../components/Anuncios/FormFilters';
import geolocationService from '../services/geolocationService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './Loader';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import StateService from '../services/KmlService';
import * as geolib from 'geolib';

import ToolsBar from './ToolsBar';

class AnunciosMap extends Component {

    initialState = {
        empreendimentos: [],
        currentPosition: { lat: -25.551136, lng: -49.1929618 },
        isSlideOpen: false,
        currentShowing: undefined,
        tipo_empreendimento: [],
        polygons: [],
        circles: [],
        markers: [],
        overlays: [],
        area_maior_que: '',
        area_menor_que: '',
        padrao: [],
        panel_empreendimento_visible: true,
        manualCircles: [],
        valor_maior_que: '',
        valor_menor_que: '',
        valor_metro_maior_que: '',
        valor_metro_menor_que : '',
        raio: 3000,
        cidade: [],
        estado: [],
        bairros: [],
        pontos: [],
        loading_empreendimento: false,
        fillColor: 'green',
        selectedIcon : undefined,
        showInfoWindow : false,
        showTooltip : false,
        radiusChanging: '',
        countRectangles : 0,
        countPolygons: 0,
        zoom: 15,
        address : '',
        bairrosOptions : [],
        quartos: [],
        tipo_imovel : [],
        tipo_negocio : '',
    }

    constructor() {
        super();
        this.state = this.initialState;
    }


    saveState = async () => {


        let state = {
            ...this.state,
            polygons : [],
            circles : [],
            overlays : []
        }

        let polygonsPaths = [];

        this.state.polygons.map( ( polygon )=>{
            let polygonPath = this.getPolygonPath( polygon );
            console.log( polygonPath )
            state.polygons.push( polygonPath );
        })

        state.circles = await this.getCirclesPlainObjects();


        let response = StateService.save( state );
    }

    addPolygon = async( coordinate )=>{

        let convertedCoordinates = [];

        coordinate.forEach( async(coord)=>{
            convertedCoordinates.push({
                lat: coord[0],
                lng : coord[0],
            });
        });

        const polygon = new google.maps.Polygon({
            paths: convertedCoordinates,
            strokeColor: "#FF0000",
            strokeOpacity: 0.3,
            strokeWeight: 2,
            fillColor: this.getColor(),
            fillOpacity: 0.3,
        });

        polygon.type = 'Polígono';
        polygon.color = polygon.fillColor;
        polygon.id = this.generateRandomString();
        polygon.name = this.state.countPolygons + 1;

        polygon.setMap( window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED );
    }

    addCircle = async ( circleProperties )=>
    {
        const radius = circleProperties.radius;

        let newCircle = {};
        newCircle.center = circleProperties.center;
        newCircle.radius = radius;

        newCircle.id = circleProperties.id;
        //circle.id = this.generateRandomString()


        newCircle.name = circleProperties.name;

        newCircle.fillColor = circleProperties.fillColor;
        newCircle.fillOpacity = 0.3;
        newCircle.type = circleProperties.type;
        newCircle.color = circleProperties.color;

        await this.setState({
            ...this.state,
            drawingMode: null,
            manualCircles: [...this.state.manualCircles, newCircle],
            overlays: [...this.state.overlays, newCircle]
        });
    }

    getCirclesPlainObjects = async()=>{
        return new Promise( ( resolve, reject )=>{
            let circles = [];

            for (let index = 0; index < this.state.manualCircles.length; index++) {
                const circle = this.state.manualCircles[index];

                if (circle.type === 'Círculo Manual'){
                    let newCircle = {
                        latitude: circle.center.lat(),
                        longitude: circle.center.lng(),
                        radius: circle.radius
                    }

                    circles.push(newCircle);
                }else{
                    let newCircle = {
                        latitude: circle.center.lat || circle.getCenter().lat() || circle.center.lat(),
                        longitude: circle.center.lng || circle.getCenter().lng() || circle.center.lng(),
                        radius: circle.radius
                    }

                    circles.push(newCircle);
                }

            }

            resolve(circles);
        });
    }


    /**
     * Esconde o Painel de Informações do Empreendimento
     */
    handleHideClicked = (event) => {
        event.preventDefault();

        this.setState({
            ...this.state,
            panel_empreendimento_visible: false
        });
    }

    togglePainelEmpreendimento = ()=>{
        this.setState({
            ...this.state,
            panel_empreendimento_visible: !this.state.panel_empreendimento_visible
        });
    }

    showAlertSemResultados = async()=>{

    }

    componentWillMount = async () => {
        window.circles = [];
        window.markers = [];
        window.refs = [];
        let currentPosition = await getBrowserGeolocation();

        this.timer = null;
        this.setState({
            ...this.state,
            currentPosition: currentPosition,
        });


    }

    onPolygonComplete = async (polygon) => {
        try {
            let path = this.getPolygonPath(polygon);

            //primeiro e último vértices devem ser iguais
            path.push(path[0]);

            polygon.type = 'Polígono';
            polygon.color = polygon.fillColor;
            polygon.id = this.generateRandomString();
            polygon.name = this.state.countPolygons + 1;

            await this.setState({
                ...this.state,
                //empreendimentos: empreendimentos,
                drawingMode: null,
                polygons: [polygon, ...this.state.polygons],
                overlays: [polygon, ...this.state.overlays],
                countPolygons : this.state.countPolygons + 1
            });

            this.reloadEmpreendimentos();



        } catch (error) {
            console.log(error);
        }
    }

    cleanState = () => {
        console.log('Alterando o estado');

        this.state.overlays.forEach((overlay) => {
            try {
                if (overlay.setMap !== undefined) {
                    overlay.setMap(null);
                }
            } catch (error) {
                console.log(error);
            }
        });
        document.querySelector('#search_box').value = '';


        this.setState({
            ...this.initialState,
            quartos : []
        });

    }

    getPolygonPath = (polygon) => {

        if (polygon.getBounds !== undefined) {
            let bounds = polygon.getBounds();
            let northEast = bounds.getNorthEast();
            let southWest = bounds.getSouthWest();

            let northWest = [northEast.lat(), southWest.lng()];
            let southEast = [southWest.lat(), northEast.lng()];

            let path = [
                [northEast.lat(), northEast.lng()],
                [northWest[0], northWest[1]],
                [southWest.lat(), southWest.lng()],
                [southEast[0], southEast[1]],
                [northEast.lat(), northEast.lng()],
            ];

            return path;
        }

        let path = [];

        for (var i = 0; i < polygon.getPath().getLength(); i++) {
            let coordinate = polygon.getPath().getAt(i).toUrlValue(10).split(',');

            path.push(
                [
                    Number(coordinate[0]),
                    Number(coordinate[1])
                ]
            );
        }

        return path;
    }

    onMarkerClick = async (id) => {

        if ($("#card-results").hasClass('collapsed') ){
            $("#card-results").removeClass("collapsed");
            $("#btn-results").removeClass("btn-results-off");
            $(".side-panel").removeClass("side-panel-off");

            $('.sliding-panel-container').addClass('active');
            $("#results-icon").removeClass("fa-angle-double-left");
            $("#results-icon").addClass("fa-angle-double-right");

        }
        await this.setState({
            loading_empreendimento : true,
            currentShowing : undefined
        });

        let empreendimento = await ImoveisService.getEmpreendimento(id);

        let selectedIcon = {
            position : {
                lat: empreendimento.location.coordinates[1],
                lng: empreendimento.location.coordinates[0]
            },
            icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-stars.png'
        };


        await this.setState({
            currentShowing: empreendimento,
            panel_empreendimento_visible: true,
            selectedIcon : selectedIcon

        });

        this.openPane();
        console.log(empreendimento);
    }

    //handle para marcar inseridas manualmente ou pelo SearchBox
    handleManualMarkerClick = () =>{

    }



    generateRandomString = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    onCircleComplete = async (circle) => {
        const center = circle.getCenter();
        const radius = circle.getRadius();
        circle.id = this.generateRandomString();
        circle.type = 'Círculo';


        console.log( center.lng(), center.lat());
        console.log( circle );

        let ponto = {
            lat : circle.getCenter().lat(),
            lng : circle.getCenter().lng()
        };

        let newCircle = {};
        newCircle.center = ponto;
        newCircle.radius = radius;

        newCircle.id = this.generateRandomString();
        //circle.id = this.generateRandomString()

        circle.setMap( null );
        newCircle.name = this.state.manualCircles.length + 1;

        newCircle.fillColor = circle.fillColor;
        newCircle.fillOpacity = 0.3;
        newCircle.type = 'Círculo';
        newCircle.color = circle.fillColor;

        await this.setState({
            ...this.state,
            //empreendimentos: empreendimentosWithoutDuplicates,
            drawingMode: null,
            //circles: [...this.state.circles, replaceCircle],
            manualCircles: [newCircle, ...this.state.manualCircles, ],
            overlays: [newCircle, ...this.state.overlays ],
            //pontos: [...this.state.pontos, ponto]

        });

        this.reloadEmpreendimentos();

    }

    onRectangleComplete = async (rectangle) => {
        let bounds = rectangle.getBounds();
        let northEast = bounds.getNorthEast();
        let southWest = bounds.getSouthWest();

        let northWest = [northEast.lat(), southWest.lng()];
        let southEast = [southWest.lat(), northEast.lng()];

        let path = [
            [northEast.lat(), northEast.lng()],
            [northWest[0], northWest[1]],
            [southWest.lat(), southWest.lng()],
            [southEast[0], southEast[1]],
            [northEast.lat(), northEast.lng()],
        ];


        console.log(rectangle );
        console.log(path);

        rectangle.color = rectangle.fillColor;
        // rectangle.gm_accessors_.fillColor = color;
        // rectangle.gm_accessors_.fillOpacity = 0.3;
        // rectangle.gm_bindings_.fillColor = color;
        // rectangle.gm_bindings_.fillOpacity = 0.3;
        rectangle.id = 'retangulo-' + this.generateRandomString();
        rectangle.name = this.state.countRectangles + 1;

        rectangle.type = 'Retângulo';

        await this.setState({
            ...this.state,
            //empreendimentos: empreendimentos,
            drawingMode: null,
            polygons: [rectangle, ...this.state.polygons],
            overlays: [rectangle, ...this.state.overlays],
            countRectangles : this.state.countRectangles + 1

        });


        this.reloadEmpreendimentos();

    }

    openPane = () => {
        this.setState({
            isSlideOpen: true
        });
    }


    closePane = () => {
        this.setState({
            isSlideOpen: false
        });
    }

    handleTipoEmpreendimentoChanged = async (e) => {

        let tipos = this.state.tipo_empreendimento;

        if (!tipos.includes(e.target.value)) {
            await this.setState({
                tipo_empreendimento: [...tipos, e.target.value]
            });
        } else {
            tipos.splice(tipos.indexOf(e.target.value), 1);

            await this.setState({
                tipo_empreendimento: tipos
            });
        }

        //this.reloadEmpreendimentos();
    }

    handleTipoImovelChanged = async (e) => {

        console.log( e );
        let tipos = this.state.tipo_imovel;
        this.setState({
            ...this.state,
            tipo_imovel: e
        });

        //this.reloadEmpreendimentos();
    }

    handleTipoNegocioChanged = async (e ) =>{
        this.setState({
            ...this.state,
            tipo_negocio: e
        });
    }

    reloadEmpreendimentos = async () => {

        //debugger;
        if (window.innerWidth < 700 ){
            $('#btn-filter').click();
        }

        await this.setState({
            ...this.state,
            loading : true
        });

        //Caso não tenha formas desenhadas no mapa a busca será por cidade
        if( this.thereAreDrownShapes() === false ){
            if( this.state.overlays.length == 0 && this.state.cidade == ''){
                alert('Desenhe uma área no Mapa ou digite uma Cidade na caixa de busca do mapa');

                await this.setState({
                    ...this.state,
                    loading : false
                });
                return;
            }

            if( this.thereAreDrownShapes() === false ){
                this.reloadEmpreendimentosOnCidade();

                return;
            }

        }


        await this.setState({
            ...this.state,
            empreendimentos: []
        });

        let empreendimentos = [];

        let empreendimentosInCircles = await this.reloadEmpreendimentosOnCircle();
        console.log('Empreendimentos Circles');
        let empreendimentosOnManualCircles = await this.reloadEmpreendimentosOnManualCircle();
        console.log(empreendimentosInCircles);
        let empreendimentosOnPolygons = await this.reloadEmpreendimentosOnPolygons();

        empreendimentos = [...empreendimentosInCircles, ...empreendimentosOnPolygons, ...empreendimentosOnManualCircles];

        //retira os empreendimentos duplicados
        let empreedimentosWithoutDuplicate = await this.removeDuplicates(empreendimentos);



        await this.setState({
            ...this.state,
            empreendimentos: empreedimentosWithoutDuplicate,
            loading: false,
            selectedIcon: undefined
        });




        if (empreedimentosWithoutDuplicate.length > 0) {
            this.openPane();

        }else{
            console.log('sem resultados');
            toast.warn('Nenhum resultado com os parametros fornecidos')
        }

    }

    fitMap = asyn => {
        if( this.state.movimentar_mapa){
            try {
                let coordinatesObjectArray = this.getCoordinatesObjectArray();

                var bounds = new google.maps.LatLngBounds();

                // this.state.empreendimentos.forEach( ( empreendimento )=>{
                //     let coords = { lng: empreendimento.location.coordinates[0], lat: empreendimento.location.coordinates[1] }
                //     bounds.extend( coords );
                // });

                let coords = { lng: this.state.empreendimentos[0].location.coordinates[0], lat: this.state.empreendimentos[0].location.coordinates[1] }
                bounds.extend(coords);

                window.googleMap.panTo(coords);
                //window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.fitBounds( bounds )
                window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(13);
                //alert(mapBounds);

                this.setState({
                    movimentar_mapa: false
                });
            } catch (error) {
                console.log( error )
            }
        }
    }

    getCoordinatesObjectArray = async => {
        let coordinates = [];

        this.state.empreendimentos.map( ( empreendimento )=>{
            let coordinatesPoint = {
                latitude : empreendimento.location.coordinates[1],
                longitude: empreendimento.location.coordinates[0],
            }

            coordinates.push( coordinatesPoint );
        });

        return coordinates;
    }

    handlePadraoChanged = async (e) => {

        if (e === null) {

            await this.setState({
                ...this.state,
                padrao: [],

            });

            return;

        }


        let padroes = [];
        e.forEach((padrao) => {
            if (padrao.value == 'Todos') {
                padroes = [
                    { value: 'Compacto', label: 'Compacto' },
                    { value: 'Econômico', label: 'Econômico' },
                    { value: 'Standard', label: 'Standard' },
                    { value: 'Médio', label: 'Médio' },
                    { value: 'Alto', label: 'Alto' },
                    { value: 'Luxo', label: 'Luxo' },
                    { value: 'Super Luxo', label: 'Super Luxo' },
                ];

                return;
            }

            if (!padroes.includes(padrao)) {
                padroes.push(padrao)
            }
        })

        await this.setState({
            ...this.state,
            padrao: padroes,

        });

        //this.reloadEmpreendimentos();

    }

    handleAreaMinimaChanged = async (e) => {
        await this.setState({
            area_maior_que: parseFloat(e.target.value)
        });

        //this.reloadEmpreendimentos();
    }


    handleRaioChanged = async (e) => {
        window.circles.forEach(( circle )=>{
            circle.setRadius(parseInt(e.target.value) )
        });
        await this.setState({
            raio: parseInt(e.target.value)
        });
    }

    handleAreaMaximaChanged = async (e) => {
        await this.setState({
            area_menor_que: parseFloat(e.target.value)
        });

        //this.reloadEmpreendimentos();
    }

    handleBairrosChanged = async (e) => {

        if (e === null) {
            await this.setState({
                ...this.state,
                bairros: [],

            });

            return;
        }

        let bairros = [];
        e.forEach((bairroSelected) => {

            if (!status.includes(bairroSelected)) {
                bairros.push(bairroSelected);
            }
        })

        await this.setState({
            bairros: bairros,
            movimentar_mapa : true
        });

        //this.reloadEmpreendimentos();
    }

    handleValorMinimoChanged = async ( value ) => {
        await this.setState({
            valor_maior_que: value
        });

        //this.reloadEmpreendimentos();
    }

    handleValorMaximoChanged = async ( value ) => {
        await this.setState({
            valor_menor_que: value
        });

        //this.reloadEmpreendimentos();
    }

    handleValorMetroMinimoChanged = async ( value ) => {
        await this.setState({
            valor_metro_maior_que: value
        });

        //this.reloadEmpreendimentos();
    }


    handleValorMetroMaximoChanged = async (value ) => {

        this.setState({
            valor_metro_menor_que: value
        });

        //this.reloadEmpreendimentos();
    }

    handleValorParcelaMaximaChanged = async (value ) => {

        this.setState({
            valor_parcela_maxima: value
        });

        //this.reloadEmpreendimentos();
    }

    handleValorParcelaMinimaChanged = async (value ) => {

        this.setState({
            valor_parcela_minima: value
        });

        //this.reloadEmpreendimentos();
    }

    handleQuartosChanged = async (e) => {
        const value = parseInt(e.target.value);

        let quartos = this.state.quartos;

        if (e.target.checked && !quartos.includes(value)) {
            quartos.push(value);
        }

        if (!e.target.checked && quartos.includes(value)) {
            var index = quartos.indexOf(value);

            if (index > -1) {
                quartos.splice(index, 1);
            }
        }

        await this.setState({
            ...this.state,
            quartos: quartos
        });

        //this.reloadEmpreendimentos();
    }

    removeDuplicates = async (empreendimentos) => {

        let newEmpreendimentos = lodash.uniqBy(empreendimentos, function (a) {
            return a._id;
        });


        return newEmpreendimentos;
    }

    reloadEmpreendimentosOnCircle = async () => {

        var empreendimentos = [];

        if (this.state.circles.length > 0) {
            for (let x = 0; x < this.state.circles.length; x++) {
                let circle = this.state.circles[x];
                const center = circle.getCenter();
                const radius = circle.getRadius();
                const color = circle.color;

                let empreendimentosResponse = await ImoveisService.getEmpreendimentosWithinRadius(
                    center.lat(),
                    center.lng(),
                    radius,
                    this.state
                );

                circle.meta_data = empreendimentosResponse.data.meta_data;

                let empreendimentosFromResponse = empreendimentosResponse.data.empreendimentos;

                empreendimentosFromResponse.map((empreendimento) => {
                    empreendimento.color = color;
                });

                let empreendimentosWithoutDuplicates = await this.removeDuplicates([...this.state.empreendimentos, ...empreendimentosFromResponse]);

                empreendimentos = [...empreendimentos, ...empreendimentosWithoutDuplicates];
            }
        }

        return empreendimentos;
    }//fim reloadEmpreendimentosOnCircle


    reloadEmpreendimentosOnManualCircle = async () => {

        var empreendimentos = [];

        if (this.state.manualCircles.length > 0) {
            for (let x = 0; x < this.state.manualCircles.length; x++) {
                let circle = this.state.manualCircles[x];
                const center = circle.center;
                const radius = circle.radius;

                try{
                    var lat = center.lat();
                    var lng = center.lng();
                }catch(e){
                    var lat = center.lat;
                    var lng = center.lng;
                }

                let empreendimentosResponse = await ImoveisService.getEmpreendimentosWithinRadius(
                     lat,
                     lng,
                    radius,
                    this.state
                );

                circle.meta_data = empreendimentosResponse.data.meta_data;

                let empreendimentosFromResponse = empreendimentosResponse.data.empreendimentos;

                let empreendimentosWithoutDuplicates = await this.removeDuplicates([...this.state.empreendimentos, ...empreendimentosFromResponse]);

                empreendimentos = [...empreendimentos, ...empreendimentosWithoutDuplicates];
            }
        }

        return empreendimentos;
    }


    reloadEmpreendimentosOnPolygons = async () => {
        let empreendimentos = [];
        if (this.state.polygons.length > 0) {
            for (let x = 0; x < this.state.polygons.length; x++) {
                let polygon = this.state.polygons[x];
                let path = this.getPolygonPath(polygon);
                path.push(path[0]);


                let empreendimentosResponse = await ImoveisService.getEmpreendimentosWithinPolygon(path, this.state);

                polygon.meta_data = empreendimentosResponse.data.meta_data;

                let empreendimentosFromResponse = empreendimentosResponse.data.empreendimentos;


                empreendimentosFromResponse.map((empreendimento) => {
                    empreendimento.color = polygon.color;
                });

                empreendimentos = [...empreendimentos, ...empreendimentosResponse.data.empreendimentos];
            }
        }
        return empreendimentos;
    }


    reloadEmpreendimentosOnCidade = async () => {

        let overlay = {};

        let empreendimentosResponse = await ImoveisService.getEmpreendimentoOnCidade(this.state);

        console.log("Recarregando pela Cidade");
        console.log(empreendimentosResponse);
        overlay.meta_data = empreendimentosResponse.data.meta_data ?? [];
        overlay.type = 'cidade';
        overlay.color = this.getColor();


        let empreendimentosFromResponse = empreendimentosResponse.data.empreendimentos;


        console.log('removido duplicados ');
        console.log(this.state.empreendimentos);

        await this.setState({
            ...this.state,
            empreendimentos: empreendimentosFromResponse,
            overlays: [overlay, ...this.state.overlays ],
            overlay_cidade: true,
            loading : false
        });

        if (this.state.empreendimentos.length > 0) {
            this.fitMap();
            this.openPane();

        } else {
            console.log('sem resultados');
            toast.warn('Nenhum resultado com os parametros fornecidos')
        }

    }

    setLocation = async (place) => {
        try {
            console.log(place[0].geometry.location);

            let latitude = place[0].geometry.location.lat();
            let longitude = place[0].geometry.location.lng();

            //adiciona um Marca no Ponto da Busca
            let ponto = {
                lat: latitude,
                lng: longitude
            };

            const reverse = await geolocationService.reverse(
                latitude,
                longitude
            );

            console.log(reverse);

            let cidadeValue = {
                label : reverse.cidade,
                value : `${reverse.uf}-${reverse.cidade}`
            }

            let bairros = await this.fetchBairros(reverse.cidade);
            let newBairrosOptions = [...this.state.bairrosOptions, ...bairros];

            this.setState({
                ...this.state,
                cidade: [ cidadeValue, ...this.state.cidade ],
                estado: [ reverse.uf, ...this.state.estado],
                bairrosOptions: newBairrosOptions,
                overlay_cidade : true,
                pontos: [...this.state.pontos, ponto]
            })

            this.reloadEmpreendimentos();
            document.querySelector('#search_box').value = '';

        } catch (error) {
            console.log('erro ao buscar cidade');
            console.log(error);
        }


    }

    /**
     * Handle Cidade Changed pelo campo do filtro onde os resultados não tem latitude e longitude
     */
    handleCidadeChangedOnFormFilter = async( cidadeOption )=>
    {


        if( cidadeOption === null ){
            this.setState({
                ...this.state,
                cidade: [],
                estado : [],
                newBairrosOptions : []
            });

            return;
        }

        console.log( cidadeOption );
        let estados = [];
        let cidades = [];
        let newBairrosOptions = [];

        for( let x = 0; x < cidadeOption.length; x ++ ){
            let option = cidadeOption[x];
            //alert( option );
            const cidadeParts = option.value.split('-');
            let estado = cidadeParts[0].trim();

            if( ! cidades.includes(option)){
                cidades.push(option);
            }

            if( ! estados.includes(estado)){
                estados.push(estado);
            }

            let bairros = await this.fetchBairros(option.label);


            newBairrosOptions = [...bairros, newBairrosOptions ]
        }



        console.log( cidades );



        await this.setState({
            ...this.state,
            cidade : cidades,
            estado : estados,
            overlay_cidade : true,
            bairrosOptions : [...newBairrosOptions]
        });

        try {
            let cidade = this.state.cidade[this.state.cidade.length - 1].label;
            let estado = this.state.cidade[this.state.cidade.length - 1].value.split('-')[0];

            let address = `${cidade} ${estado} - Brasil`;

            let location = await geolocationService.location( address );

            window.googleMap.panTo({ lat: location.latitude, lng : location.longitude });

            this.reloadEmpreendimentos();
        } catch (error) {
            console.log( error );
        }

    }

    handleCidadeChanged = async ( latitude, longitude, address )=>{
        try {

            let ponto = {
                lat: latitude,
                lng: longitude
            };


            const reverse = await geolocationService.reverse(
                latitude,
                longitude
            );

            console.log(reverse);

            let bairros = await this.fetchBairros(reverse.cidade);

            this.setState({
                ...this.state,
                cidade: reverse.cidade,
                estado: reverse.uf,
                bairrosOptions: bairros,
                overlay_cidade: true,
                pontos: [...this.state.pontos, ponto],
                address: address,
                zoom : 15
            })


            this.reloadEmpreendimentos();
            window.googleMap.context["__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED"].setOptions({ zoom: 15 });

        } catch (error) {
            console.log('erro ao buscar cidade');
            console.log(error);
        }
    }

    fetchBairros = async (cidade) => {
        try {
            let bairros = await axios.get('/bairros/json/' + cidade);

            return bairros.data;
        } catch (error) {
            console.log(error);
        }
    }

    onMarkerComplete = async (marker, map, circle ) => {
        marker.setMap( null );
        let newCircle = {};
        newCircle.center = marker.getPosition();
        newCircle.radius = 1000;
        newCircle.fillColor = this.getColor();
        newCircle.color = newCircle.fillColor;
        newCircle.fillOpacity = 0.3;
        newCircle.type = 'Círculo Manual';
        newCircle.id = this.generateRandomString();

        newCircle.name = this.state.manualCircles.length + 1;
        await this.setState({
            ...this.state,
            manualCircles: [ newCircle, ...this.state.manualCircles],
            overlays: [ newCircle, ...this.state.overlays]
        });

        this.reloadEmpreendimentos();

    }

    onChangeInputRadius = ( id, newRadius )=>{
        let manualCircles = this.state.manualCircles;


        if( manualCircles.length == 1 ){
            var index = 0;
        }else {
            var index = this.state.manualCircles.findIndex((circle) => {
                return circle.id == id
            });
        }

        console.log( index );
        console.log(newRadius);

        manualCircles[index].radius = parseFloat(newRadius);
        this.setState({
            manualCircles: [...manualCircles]
        })

    }

    onCircleRadiusChanged = ( id ) =>{

        let manualCircles = this.state.manualCircles;

        let newRadius = window.refs[id].getRadius();

        this.setState({
            showTooltip : true,
            radiusChanging : newRadius
        })

        let index = this.state.manualCircles.findIndex( ( circle )=>{
            return circle.id == id
        });

        console.log( newRadius );

        manualCircles[index].radius = newRadius;

        this.setState({
            ...this.state,
            manualCircles : manualCircles
        });

        setTimeout(()=>{
            this.setState({
                showTooltip: false,
            })
        }, 10000 )
    }

    removeOverlay = async ( id )=>{

        let overlays = this.state.overlays;
        let circles = this.state.manualCircles;
        let poligonos = this.state.polygons;
        let countPolygons = this.state.countPolygons;
        let countRectangles = this.state.countRectangles;

        let index = overlays.findIndex((overlay) => {
            return overlay.id == id
        });

        let overlay = overlays[index];



        if (overlay.type == 'Retângulo' || overlay.type == 'Polígono'){
            let indexPoligono = poligonos.findIndex((overlay) => {
                return overlay.id == id
            });
            poligonos.splice( indexPoligono, 1 );
            overlay.setMap(null);

            if (overlay.type == 'Retângulo'){
                countRectangles--;
            }else{
                countPolygons--;
            }
        }

        if (overlay.type == 'Círculo' || overlay.type == 'Círculo Manual'){
            let indexCircle = circles.findIndex((overlay) => {
                return overlay.id == id
            });
            circles.splice(indexCircle, 1);


            console.log( circles );

            if( overlay.setMap !== undefined ){
                overlay.setMap(null);
            }

        }

        // if (overlay.type == "Círculo Manual") {

        //     let indexCircle = circles.findIndex((overlay) => {
        //         return overlay.id == id
        //     });

        //     circles = circles.splice(indexCircle, 1);

        //     //this.forceUpdate();
        //     console.log(circles);
        //     //overlay.setMap(null);
        // }

        let newOverlays = overlays.splice(index, 1);




        console.log( overlays );
        await this.setState({
            overlays : [...overlays],
            manualCircles : [...circles],
            polygons : [...poligonos],
            countPolygons: countPolygons,
            countRectangles: countRectangles
        });

       if( this.state.empreendimentos.length > 0 ){
           this.reloadEmpreendimentos();
       }

    }

    handleShowInfoWindow =()=>{
        this.setState({
            showInfoWindow: true
        });
    }

    handleHideInfoWindow = () => {
        this.setState({
            showInfoWindow: false
        });
    }

    onCircleCenterChanged = (id) => {

        let manualCircles = this.state.manualCircles;

        let newRadius = window.refs[id].getCenter();

        let index = this.state.manualCircles.findIndex((circle) => {
            return circle.id == id
        });

        manualCircles[index].center = newRadius;

        this.setState({
            ...this.state,
            manualCircles: manualCircles
        });
        console.log(index)
    }

    getColor = () => {
        return colors[this.state.overlays.length];
    }

    render() {
        return (
            <>


                { this.state.loading && <Loader /> }

                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: "60%", color: '#000', zIndex: 9999999 }}
                />
                <div className="col-3 ed-mapa-filtro" style={{ paddingLeft: 0, paddingRight: 0, zIndex: 1 }}>
                    <button id="btn-filter" className="btn btn-sm" style={{
                        position: 'relative',
                        float: 'right',
                        top: '70px',
                        zIndex: 1500,
                        borderRadius: 0
                    }} onClick={(e) => {
                        e.preventDefault();
                        $("#sidebar").toggleClass("collapsed");
                        $("#content").toggleClass("col-12 col-9 map-filter-off");
                        $("#btn-filter").toggleClass("btn-filter-off");

                        if ($("#filter-icon").hasClass("fa-angle-double-left")) {
                            $("#filter-icon").removeClass("fa-angle-double-left");
                            $("#filter-icon").addClass("fa-angle-double-right");
                        } else if ($("#filter-icon").hasClass("fa-angle-double-right")) {
                            $("#filter-icon").removeClass("fa-angle-double-right");
                            $("#filter-icon").addClass("fa-angle-double-left");
                        }

                    }}>
                        <i id="filter-icon" className="fas fa-angle-double-left"></i>
                    </button>
                    <div id="sidebar" style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <div className="card" style={{ borderRadius: 0, height: '100vh', }}>
                            {/* <div className="card-header">
                                <button className="close" onClick={ (e)=>{
                                    e.preventDefault();
                                    $("#sidebar").toggleClass("collapsed");
                                    $("#content").toggleClass("col-12 col-9");
                                }}>x</button>
                            </div> */}
                            <div className="card-body">
                                <FormFilters
                                    handleCidadeChanged={this.handleCidadeChangedOnFormFilter}
                                    handleAreaMaximaChanged={this.handleAreaMaximaChanged}
                                    handleAreaMinimaChanged={this.handleAreaMinimaChanged}
                                    handlePadraoChanged={this.handlePadraoChanged}
                                    handleValorMinimoChanged={this.handleValorMinimoChanged}
                                    handleValorMaximoChanged={this.handleValorMaximoChanged}
                                    handleValorMetroMinimoChanged={this.handleValorMetroMinimoChanged}
                                    handleValorMetroMaximoChanged={this.handleValorMetroMaximoChanged}
                                    handleTipoImovelChanged={this.handleTipoImovelChanged}
                                    handleTipoNegocioChanged={this.handleTipoNegocioChanged}
                                    handleStatusChanged={this.handleStatusChanged}
                                    getPadroes={this.getPadroes}
                                    bairrosOptions={this.state.bairrosOptions}
                                    bairros={this.state.bairros}
                                    handleBairrosChanged={this.handleBairrosChanged}
                                    handleRaioChanged={this.handleRaioChanged}
                                    tipo_empreendimento={this.state.tipo_empreendimento}
                                    handleSubmit={this.reloadEmpreendimentos}
                                    cleanState={this.cleanState}
                                    handleQuartosChanged={this.handleQuartosChanged}
                                    quartos={this.state.quartos}
                                    area_minima={this.state.area_maior_que}
                                    area_maxima={this.state.area_menor_que}
                                    status={this.state.status}
                                    padrao={this.state.padrao}
                                    raio={this.state.raio}
                                    handleIncorporadoraChanged={this.handleIncorporadoraChanged}
                                    address={this.state.address}
                                    valor_maior_que={this.state.valor_maior_que}
                                    valor_menor_que={this.state.valor_menor_que}
                                    valor_metro_menor_que={this.state.valor_metro_menor_que}
                                    valor_metro_maior_que={this.state.valor_metro_maior_que}
                                    showLegenda={this.state.empreendimentos.length > 0}
                                    handleAnoLancamentoChanged={this.handleAnoLancamentoChanged}
                                    ano_lancamento={this.state.ano_lancamento}
                                    handleNomeEmpreendimentoChanged={this.handleNomeEmpreendimentoChanged}
                                    nome_empreendimento={this.state.nome_empreendimento}
                                    searchEmpreendimentos={this.searchEmpreendimentos}
                                    overlays={this.state.overlays}
                                    cidade={this.state.cidade}
                                    tipo_negocio={this.state.tipo_negocio}
                                    tipo_imovel={this.state.tipo_imovel}
                                    incorporators={this.state.incorporadoras}
                                    saveState={this.saveState}


                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-9 ed-mapa" id="content" style={{ position: 'relative', left: -47, }}>
                    {/*{this.state.empreendimentos.length > 0 && <SlidingPaneEmpreendimento
                        closePane={this.closePane}
                        isOpen={this.state.isSlideOpen}
                        empreendimento={this.state.currentShowing}
                        showEmprendimentoCard={this.state.panel_empreendimento_visible}
                        handleHideClicked={this.handleHideClicked}
                        overlays={this.state.overlays}
                        togglePainelEmpreendimento={this.togglePainelEmpreendimento}
                        loading_empreendimento={this.state.loading_empreendimento}
                        empreendimentos={this.state.empreendimentos}
                    />}*/}
                    <MapWithADrawingManager
                        onMarkerComplete={this.onMarkerComplete}
                        currentPosition={this.state.currentPosition}
                        onPolygonComplete={this.onPolygonComplete}
                        onCircleComplete={this.onCircleComplete}
                        empreendimentos={this.state.empreendimentos}
                        onRectangleComplete={this.onRectangleComplete}
                        onMarkerClick={this.onMarkerClick}
                        manualCircles={this.state.manualCircles}
                        raio={this.state.raio}
                        zoom={this.state.zoom}
                        setLocation={this.setLocation}
                        pontos={this.state.pontos}
                        onCircleRadiusChanged={this.onCircleRadiusChanged}
                        onCircleCenterChanged={this.onCircleCenterChanged}
                        getColor={this.getColor}
                        fillColor={this.state.fillColor}
                        selectedIcon={this.state.selectedIcon}
                        showInfoWindow={this.showInfoWindow}
                        handleShowInfoWindow={this.handleShowInfoWindow}
                        handleHideInfoWindow={this.handleHideInfoWindow}

                    />
                    <ToolsBar overlays={this.state.overlays}
                        onChangeInputRadius={this.onChangeInputRadius}
                        removeOverlay={this.removeOverlay}
                    />

                    <MouseTooltip
                        visible={this.state.showTooltip}
                        offsetX={15}
                        offsetY={10}
                    >
                        <span style={{fontWeight: 'bold',padding: '10px',backgroundColor: 'green', color: '#ffffff', borderRadius: '5pu'}}>
                            {Number(this.state.radiusChanging).toFixed(2)} metros
                        </span>


                    </MouseTooltip>

                </div>

            </>
        )
    }

    //verifica se tem formas desenhadas no mapa
    thereAreDrownShapes() {
        return this.state.polygons.length > 0 || this.state.manualCircles.length > 0 || this.state.circles.length > 0;


    }
}

export default AnunciosMap;
