import React, { Component } from 'react';
import Modal from 'react-modal';

class ModalSetRadiusFromMarker extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isOpen :false
        }
    }

    setIsOpen = ( state )=>{
        this.setState({
            isOpen : state
        })
    }

    closeModal = async()=>{
        setIsOpen( false );
    }

    render() { 
        return (  
            <Modal
                isOpen={this.props.isModalOpen}
                //onAfterOpen={afterOpenModal}
                onRequestClose={this.closeModal}                
                contentLabel="Defina o Raio"
            >

                <h2>Editar Raio do Círculo</h2>
                <button onClick={this.closeModal}>close</button>
                <div>Alterar Raio do Círculo</div>
                <form>
                    <label>Raio do Círculo</label>
                    <input type="number"/>
                    
                    <button>Alterar Raio</button>
                </form>
            </Modal>

        );
    }
}
 
export default ModalSetRadiusFromMarker;