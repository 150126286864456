import React from "react";

class LegendaDataLayer extends React.Component {

    render() {
        return (
            <div>
                <table className="table table-striped table-condensed legenda-mapa">
                    <thead>
                    <tr>
                        <th colSpan="2" className="" style={{paddingBottom: 7}}>
                                <span className="font-weight-bold text-center"
                                      style={{color: 'rgb(0, 72, 14)', fontSize: 16}}>
                                    LEGENDA ZONEAMENTO
                                </span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.legendas.map((legenda, index) => {

                        return (
                            <tr key={index}>
                                <td style={{backgroundColor: legenda.color, width: '15%'}}>
                                   &nbsp;
                                </td>
                                <td style={{ width: '85%', fontSize: '13px'}}>
                                    {legenda.value}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>

                </table>
            </div>
        );
    }
}

export default LegendaDataLayer;
