/*
element.style {
    height: 100%;
    position: absolute;
    z-index: 9999999;
    margin: auto;
    width: 100%;
    background-color: rgba(0,0,0,0.3);
    padding: 100px;
    top: 0;
    left: 0;
    border-radius: 20px;
}*/

import {React, Component} from 'react';
import Loader from "react-loader-spinner";


export default class InnerLoading extends Component{

    render() {
        return (
            <div className="d-flex justify-content-center align-items-center"
                 style={{backgroundColor: `rgba(0,0,0,0.3)`,borderRadius: '20px',height: '100%', position: 'absolute', zIndex: '999999', width: '100%', top: '0', left: '0'}}>
                <Loader type="TailSpin" color="#CCCCCC" height={100} width={100} />
            </div>
        );
    }
}
