export default {
    apartamentosTypes : {
        types: [
            "Apartamento", "Apartamento Duplex", "Apartamento Garden", "Flat",
            "Edifício residencial", "Cobertura", "Kitinet", "Loft", "Studio",
            "Duplex", "Hotel/Motel/Pousada", "Garden", "Triplex", "Kitnet/Conjugado"
        ],
        icons: {
            aluguel: "/img/markers/apartamentos_aluguel.png",
            venda: "/img/markers/apartamentos.png"
        }
    },
    casasTypes : {
        types: [
            "Casa de Condomínio", "Casa Residencial", "Residência", "Sobrado",
            "Vila/Rua", "Casa", "Casa de Vila", "Térrea", "Condominio",
            "Casa de Condomínio", "Casa Padrão", "Casa em condomínio fechado"
        ],
        icons: {
            aluguel: "/img/markers/residencial_alugel.png",
            venda: "/img/markers/residencial.png"
        }
    },
    ruralTypes : {
        types: ["Chácara", "Fazenda", "Haras"],
        icons: {
            aluguel: "/img/markers/rural_aluguel.png",
            venda: "/img/markers/rural.png"
        }
    },
    terrenosTypes : {
        types: [
            "Terreno", "Lote/Terreno", "Fazenda/Sítio/Chácara",
            "Loteamento/Condomínio"
        ],
        icons: {
            aluguel: "/img/markers/lotes-terrenos_aluguel.png",
            venda: "/img/markers/lotes-terrenos.png"
        }
    },
    industrialTypes : {
        types: ["Galpão", "Galpão/Depósito/Armazém", "Garagem"],
        icons: {
            aluguel: "/img/markers/industrial-garagem_aluguel.png",
            venda: "/img/markers/industrial-garagem.png"
        }
    },
    comercialTypes : {
        types: [
            "Loja", "Shopping", "Prédio/Edifício inteiro", "Imóvel comercial",
            "Galeria", "Escritório", "Ponto comercial/Loja/Box",
            "Loja Shopping/ Ct Comercial", "Loja/Salão", "Conjunto Comercial/Sala",
            "Casa Comercial", "Prédio Inteiro", "Sala/Conjunto", "Área Comercial",
            "Edifício Comercial", "Galpão Comercial", "Prédio Comercial",
            "Residência Comercial", "Sala Comercial", "Prédio/Edificio",
            "Prédio/Edificio inteiro", "Andar/Laje corporativa", "Centro comercial",
            "Consultório"
        ],
        icons: {
            aluguel: "/img/markers/comercial_aluguel.png",
            venda: "/img/markers/comercial.png"
        }
    },
    select_options: [
        {
            value: "Apartamento",
            label: "Apartamento",
        },
        {
            value: "Apartamento Duplex",
            label: "Apartamento Duplex",
        },
        {
            value: "Apartamento Garden",
            label: "Apartamento Garden",
        },
        {
            value: "Edifício residencial",
            label: "Edifício residencial",
        },
        {
            value: "Flat",
            label: "Flat",
        },
        {
            value: "Duplex",
            label: "Duplex",
        },
        {
            value: "Triplex",
            label: "Triplex",
        },
        {
            value: "Garden",
            label: "Garden",
        },
        {
            value: "Cobertura",
            label: "Cobertura",
        },
        {
            value: "Hotel/Motel/Pousada",
            label: "Hotel/Motel/Pousada",
        },
        {
            value: "Kitinet",
            label: "Kitinet",
        },
        {
            value: "Kitnet/Conjugado",
            label: "Kitnet/Conjugado",
        },
        {
            value: "Loft",
            label: "Loft",
        },
        {
            value: "Studio",
            label: "Studio",
        },
        {
            value: "Vila/Rua",
            label: "Vila/Rua",
        },
        {
            value: "Casa",
            label: "Casa",
        },
        {
            value: "Casa em condomínio fechado",
            label: "Casa em condomínio fechado",
        },
        {
            value: "Condominio",
            label: "Condominio",
        },
        {
            value: "Casa Padrão",
            label: "Casa Padrão",
        },
        {
            value: "Casa de Vila",
            label: "Casa de Vila",
        },
        {
            value: "Casa de Condomínio",
            label: "Casa de Condomínio",
        },
        {
            value: "Casa Residencial",
            label: "Casa Residencial",
        },
        {
            value: "Residência",
            label: "Residência",
        },
        {
            value: "Térrea",
            label: "Térrea",
        },
        {
            value: "Sobrado",
            label: "Sobrado",
        },
        {
            value: "Chácara",
            label: "Chácara",
        },
        {
            value: "Fazenda",
            label: "Fazenda",
        },
        {
            value: "Fazenda/Sítio/Chácara",
            label: "Fazenda/Sítio/Chácara",
        },
        {
            value: "Haras",
            label: "Haras",
        },
        {
            value: "Terreno",
            label: "Terreno",
        },
        {
            value: "Lote/Terreno",
            label: "Lote/Terreno",
        },
        {
            value: "Loteamento/Condomínio",
            label: "Loteamento/Condomínio",
        },
        {
            value: "Escritório",
            label: "Escritório",
        },
        {
            value: "Loja",
            label: "Loja",
        },
        {
            value: "Imóvel comercial",
            label: "Imóvel comercial",
        },
        {
            value: "Galeria",
            label: "Galeria",
        },
        {
            value: "Prédio/Edifício inteiro",
            label: "Prédio/Edifício inteiro",
        },
        {
            value: "Shopping",
            label: "Shopping",
        },
        {
            value: "Consultório",
            label: "Consultório",
        },
        {
            value: "Loja/Salão",
            label: "Loja/Salão",
        },
        {
            value: "Centro comercial",
            label: "Centro comercial",
        },
        {
            value: "Andar/Laje corporativa",
            label: "Andar/Laje corporativa",
        },
        {
            value: "Área Comercial",
            label: "Área Comercial",
        },
        {
            value: "Ponto comercial/Loja/Box",
            label: "Ponto comercial/Loja/Box",
        },
        {
            value: "Conjunto Comercial/Sala",
            label: "Conjunto Comercial/Sala",
        },
        {
            value: "Sala/Conjunto",
            label: "Sala/Conjunto",
        },
        {
            value: "Casa Comercial",
            label: "Casa Comercial",
        },
        {
            value: "Edifício Comercial",
            label: "Edifício Comercial",
        },
        {
            value: "Galpão Comercial",
            label: "Galpão Comercial",
        },
        {
            value: "Prédio Inteiro",
            label: "Prédio Inteiro",
        },
        {
            value: "Prédio/Edificio",
            label: "Prédio/Edificio",
        },
        {
            value: "Prédio/Edificio inteiro",
            label: "Prédio/Edificio inteiro",
        },
        {
            value: "Prédio Comercial",
            label: "Prédio Comercial",
        },
        {
            value: "Edifício Comercial",
            label: "Edifício Comercial",
        },
        {
            value: "Residência Comercial",
            label: "Residência Comercial",
        },
        {
            value: "Sala Comercial",
            label: "Sala Comercial",
        },
        {
            value: "Galpão/Depósito/Armazém",
            label: "Galpão/Depósito/Armazém",
        },
        {
            value: "Galpão",
            label: "Galpão",
        },
        {
            value: "Garagem",
            label: "Garagem",
        },
    ]
}
