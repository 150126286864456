import React, { Component } from 'react';
import ModalDashboard from "./ModalDashboard";


class InfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalDashboard: false,
        }
    }

    showModalDashboard = () => {
        this.setState({
            ...this.state,
            modalDashboard: true,
        });

        $("#btn-results").click();
    };

    render() {
        return (
            <div className="card area-info ov-hidden"
                style={{ borderStyle: 'solid', borderColor: this.props.color, borderWidth: '2px', fontSize: 16 }}>
                <div className="card-header"
                    style={{ color: "#517E37", fontWeight: 'bold', textTransform: 'uppercase', fontSize: 18 }}>
                    {this.props.type.split(' ')[0]} {this.props.selectedArea.name} &nbsp;
                    {console.log(this.props.selectedArea)}
                    <button className="btn btn-sm btn-round btn-primary"
                        title="Renomear Área"
                        onClick={() => { this.props.editShapeName(this.props.selectedArea); }}>
                        <i className="fa fa-edit"></i>
                    </button>
                    <br />
                </div>
                <div className="card-body" style={{ backgroundColor: '#ffffff', fontSize: '15' }}>
                    Tipo da Busca: {this.props.type}<br />
                    {(this.props.selectedArea.radius !== undefined) && <>Raio do Círculo: {Number(this.props.selectedArea.radius).toFixed(2)}</>}
                    <div style={{ padding: '5px' }}>
                        {this.props.meta_data.request.map((param, key) => {
                            return (<div key={`${param.value}-${key}-label`}>
                                <span className="badge badge-primary badge-blue btn-round">{param.value}</span> &nbsp;
                            </div>)
                        })}
                    </div>
                    <hr />
                    <strong>Total de Empreendimentos:</strong> {this.props.totalEmpreendimentos} <br />
                    <strong>Oferta Lançada:</strong> {this.props.oferta_lancada} <br />
                    <strong>Oferta Final:</strong> {this.props.oferta_final} <br />
                    <strong>Unidades Vendidas:</strong> {this.props.unidadesVendidas} <br />
                    <div>
                        <form action={this.props.meta_data.file.pdf} method="GET">
                            <div>
                                <label htmlFor="accept" className="form-check-label">
                                    <input
                                        className="detalhes-check-input"
                                        type="checkbox"
                                        id="accept"
                                        name="accept"
                                        value="true"
                                    />
                                    <span className="slider detalhe-slider"></span>
                                    Detalhar Empreendimentos.
                                </label>
                            </div>
                            {window.verPdfExcel && <>
                                <div className="row">
                                    <div className="col-sm-6 col-6 text-left">
                                        <button type="submit" className="btn btn-sm btn-block btn-round">
                                            <i className={"fa fa-file-pdf"}></i> PDF
                                        </button>
                                    </div>
                                    <div className="col-sm-6 col-6 text-left">
                                        <button type="submit" formAction={this.props.meta_data.file.excel} className="btn btn-sm btn-block btn-round">
                                            <i className={"fa fa-file-excel"}></i> Excel
                                        </button>
                                    </div>
                                </div>
                            </>}

                            {window.viewPdfDashboardMapa == true && <>
                                <div className="row">
                                    <div className="col-sm-6 col-6 text-left">
                                        <button className="btn btn-sm btn-block btn-round" type="button" onClick={this.showModalDashboard} style={{ width: "198px" }}>
                                            <i className={"fa fa-file-pdf"}></i> PDF Dashboard
                                        </button>
                                    </div>
                                </div>
                            </>
                            }
                        </form>
                        <div className="row">
                            <div className="col-sm-6 col-6">
                                {window.authenticatedUserCanQueryPolos == true && this.props.polos && this.props.polos.length > 0 && <>
                                    <button className="btn btn-behance btn-sm btn-block" onClick={() => {


                                        let agrupamentos = [];

                                        this.props.polos.map((polo) => {
                                            let found = agrupamentos.find((agrupamento) => {
                                                return agrupamento.grupo === polo.sub_category;
                                            });
                                            if (found) {
                                                found.count++;
                                            } else {
                                                let grupo = polo.sub_category;
                                                agrupamentos.push({ grupo: grupo, count: 1 });
                                            }
                                        });

                                        this.props.setPolosGeradoresCurrentShowing(this.props.polos, agrupamentos, this.props.polos_cache_key);

                                    }}>
                                        <i className="fas fa-map-marker-alt"></i> Polos Geradores
                                    </button>


                                </>}
                            </div>
                        </div>
                    </div>
                </div>
                <ModalDashboard
                    show={this.state.modalDashboard}
                    closeModal={() => this.setState({ modalDashboard: false })}
                    setLoading={this.props.setLoading}
                    originalState={this.props.meta_data.file.pdfdashboard}
                />
            </div>
        );
    }
}

export default InfoCard;
