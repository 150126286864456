import React from 'react';
import {Dialog} from "primereact/dialog";
import {Dropdown} from 'primereact/dropdown';
import {InputSwitch} from 'primereact/inputswitch';


export default class ModalEdicaoMassaPorte extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    async componentDidMount() {
    }

    render() {

        return (<>
            <Dialog
                header="Editar Em Massa - Porte"
                visible={this.props.show}
                style={{width: '50vw', marginTop: '50px'}}
                onHide={this.props.handleClose}
                blockScroll={true}
            >

                <div className="card">
                    <div className="card-body">
                        <div className="form-group row">
                            <div className="col-12">
                                <label>Porte</label>
                                <Dropdown
                                    optionLabel="label"
                                    name="porte"
                                    value={this.props.opcoes.porte}
                                    blockScroll={true}
                                    appendTo={document.body}
                                    options={this.props.valores}
                                    onChange={this.props.handleChange}
                                    placeholder="Selecione Uma Opção"
                                    style={{width: '100%'}}
                                    filter={true}
                                />
                                {this.props.errors?.size && <div className="feedback text-danger d-block">{this.props.errors.size}</div>}
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-12">
                                <button className="btn btn-success btn-sm btn-block"
                                        onClick={this.props.handleSave}>Salvar
                                </button>
                            </div>
                        </div>


                    </div>
                </div>
            </Dialog>
        </>);
    }
}
const styles = {
    legend: {
        padding: '5px',
        margin: '4px',
    }
}
