import React, { Component } from "react";

class TablePadroesInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <table className="table table-striped table-condensed table-padroes" style={{border: `3px solid ${this.props.color}`}}>
                <thead>
                    <tr
                        className="text-center"
                        style={{
                            backgroundColor: '#5B7537',
                            color: "#ffffff",
                            fontWeight: "bold",
                        }}
                    >
                        <th
                            className="text-center"
                            style={{ color: "#ffffff", padding: "7px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>Padrão</span>
                        </th>
                        <th
                            className="text-center"
                            style={{ color: "#ffffff", padding: "7px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>
                                Faixa de Valor
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-center">
                        <td className="text-center">Compacto</td>
                        <td className="text-center">
                            Studio - Loft - 1 quarto
                        </td>
                    </tr>
                    <tr className="text-center">
                        <td className="text-center">Econômico</td>
                        <td className="text-center">
                            Teto Minha Casa Minha Vida
                        </td>
                    </tr>
                    <tr className="text-center">
                        <td className="text-center">Standard</td>
                        <td className="text-center">
                            de Teto Minha Casa Minha Vida até 700.000
                        </td>
                    </tr>
                    <tr className="text-center">
                        <td className="text-center">Médio</td>
                        <td className="text-center">
                            de 700.001 até 1.250.000
                        </td>
                    </tr>
                    <tr className="text-center">
                        <td className="text-center">Alto</td>
                        <td className="text-center">
                            de 1.250.001 até 2.000.000
                        </td>
                    </tr>
                    <tr className="text-center">
                        <td className="text-center">Luxo</td>
                        <td className="text-center">
                            de 2.000.001 até 4.000.000
                        </td>
                    </tr>
                    <tr className="text-center">
                        <td className="text-center">Super Luxo</td>
                        <td className="text-center">Acima de 4.000.001</td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default TablePadroesInfo;
