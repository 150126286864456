import React from 'react';
import {Dialog} from "primereact/dialog";
import {Dropdown} from 'primereact/dropdown';
import {InputSwitch} from 'primereact/inputswitch';
import SimpleMap from "../SimpleMap/SimpleMap";
import {Marker} from "react-google-maps";


export default class ModalEdicaoPolo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sub_categorias: [],
            sub_categorias_filtradas: [],
            polo: {},
        }
    }

    async componentDidMount() {
        await this.setState({
            polo: {
                ...this.props.polo
            }
        });

    }


    static getDerivedStateFromProps(props, state) {
        if (props.polo) {
            return {
                polo: {
                    ...props.polo
                },
            };
        }
        return null;
    }


    render() {

        return (<>
            <Dialog
                header="Editar Polo Gerador"
                visible={this.props.show}
                style={{width: '70vw', marginTop: '3px'}}
                onHide={this.props.handleClose}
                blockScroll={true}
            >

                <div className="card">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-6">
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label>Title</label>
                                        <input type="text"
                                               className="form-control"
                                               name="title"
                                               value={this.state?.polo?.title}
                                               onChange={this.props.handleChange}/>
                                        {this.props.errors?.title && <div
                                            className="feedback text-danger d-block">{this.props.errors.title}</div>}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-12">
                                        <label htmlFor="">Endereço</label>
                                        <input type="text"
                                               className="form-control"
                                               name="endereco" value={this.state?.polo?.formatted_address}
                                               readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-6">
                                        <label htmlFor="">Cidade</label>
                                        <input type="text"
                                               className="form-control"
                                               name="cidade" value={this.state?.polo?.city}
                                               readOnly={true}
                                        />
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="">Estado</label>
                                        <input type="text"
                                               className="form-control"
                                               name="cidade" value={this.state?.polo?.state}
                                               readOnly={true}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-6">
                                        <label>Categoria</label>
                                        <Dropdown
                                            optionLabel="label"
                                            name="main_category"
                                            value={this.state?.polo?.main_category}
                                            blockScroll={true}
                                            appendTo={document.body}
                                            options={this.props.categorias}
                                            onChange={this.props.handleChange}
                                            placeholder="Selecione Uma Categoria"
                                            style={{width: '100%'}}
                                            filter={true}
                                        />
                                        {this.props.errors?.main_category && <div
                                            className="feedback text-danger d-block">{this.props.errors.main_category}</div>}
                                    </div>

                                    <div className="col-6">
                                        <label>Sub-Categoria</label>
                                        <Dropdown
                                            name="sub_category"
                                            optionLabel="label"
                                            value={this.state?.polo?.sub_category}
                                            blockScroll={true}
                                            appendTo={document.body}
                                            options={this.props.sub_categorias}
                                            onChange={this.props.handleChange}
                                            placeholder="Selecione Uma Categoria"
                                            style={{width: '100%'}}
                                            filter={true}
                                            lazy={true}
                                        />

                                        {this.props.errors?.sub_category && <div
                                            className="feedback text-danger d-block">{this.props.errors.sub_category}</div>}

                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-6">
                                        <label>Em Shopping Center: <br/></label>

                                        <div className="d-flex align-items-center">
                                            <InputSwitch checked={this.state?.polo?.in_shopping}
                                                         name="in_shopping"
                                                         onChange={this.props.handleChange}/> &nbsp;
                                            <span className="font-weight-bold">
                                        {this.state?.polo?.in_shopping ? 'Sim' : 'Não'}
                                    </span>
                                        </div>

                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-12">
                                        <button className="btn btn-success btn-sm btn-block"
                                                onClick={this.props.handleSave}>Salvar
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className="col-6">
                                <SimpleMap
                                    currentPosition={{
                                        lat: this.state?.polo?.position?.coordinates?.[1],
                                        lng: this.state?.polo?.position?.coordinates?.[0]
                                    }}
                                    zoom={15}
                                    draggable={false}
                                    >
                                    <Marker position={{
                                        lat: this.state?.polo?.position?.coordinates?.[1],
                                        lng: this.state?.polo?.position?.coordinates?.[0]
                                    }}/>
                                </SimpleMap>

                            </div>
                        </div>


                    </div>
                </div>
            </Dialog>
        </>);
    }
}
const styles = {
    legend: {
        padding: '5px',
        margin: '4px',
    }
}
