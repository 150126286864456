import React from 'react';
import {Dialog} from "primereact/dialog";
import {Dropdown} from 'primereact/dropdown';
import {InputSwitch} from 'primereact/inputswitch';


export default class ModalRemocaoMassa extends React.Component {

    constructor(props) {
        super(props);

    }

    async componentDidMount() {
    }

    render() {

        return (<>
            <Dialog
                header="Remover Em Massa"
                visible={this.props.show}
                style={{width: '50vw', marginTop: '50px'}}
                onHide={this.props.handleClose}
                blockScroll={true}
            >

                <div className="card">
                    <div className="card-body">
                        <div className="form-group row">
                            <div className="col-12">
                                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                    {this.props.ids_selecionados.length > 1 &&
                                        <h4 className="alert-heading">Confirma a remoção dos {this.props.ids_selecionados.length} registros selecionados?</h4>
                                    }

                                    {this.props.ids_selecionados.length === 1 &&
                                        <h4 className="alert-heading">Confirma a remoção do registro selecionado?</h4>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-6">
                                <button className="btn btn-danger btn-sm btn-block"
                                        onClick={this.props.handleSave}>Confirmar
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-default btn-sm btn-block"
                                        onClick={this.props.handleClose}>Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>);
    }
}
const styles = {
    legend: {
        padding: '5px',
        margin: '4px',
    }
}
