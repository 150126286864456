
export function formatNumber(number) {
    return new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    }).format(Number(number));
}

export function formatCurrency(number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(number));
}

export function formatPercentage(number) {
    return new Intl.NumberFormat('pt-BR', { style: 'percent' }).format(Number(number));
}

export function getPercentageFromTotal(total, value) {
    return (Number(value) / Number(total)) * 100;
}

export function slugify( string ){
    return string
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '')
        .replace(/\s+/g, '-');
}
