import React from 'react';
import {Accordion, Button, Card, Modal} from "react-bootstrap";
import InnerLoading from "./InnerLoading";
import './estilos.css';
import IndiceVerticalizacao from "./IndiceVerticalizacao/IndiceVerticalizacao";
import GenericTable from "./GenericTable/GenericTable";
import GenericDataFormatter from "./GenericTable/GenericDataFormatter";
import config from "./config";
import {InputNumber} from "primereact/inputnumber";
import {InputText} from "primereact/inputtext";

import { Dialog } from 'primereact/dialog';


const useGenericTableFor = [
    'faixa_etaria', 'faixa_etaria2022','domicilios_por_numero_moradores', 'domicilios_por_tipo_moradia',
    'domicilios_por_tipo_moradia2022',
    'domicilios_por_condicao_ocupacao', 'domicilios_por_numero_moradores',
    'domicilios_por_numero_moradores2022',
    'domicilios_por_faixa_de_renda',
    'populacao_por_faixa_de_renda'
];

const slugfy = (text) => {
    return text
        .toString()
        .normalize('NFD')                   // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/\s+/g, "_");
};

class SocioDemografiaModal extends React.Component {


    constructor(props) {
        super(props);

        let formatter = new GenericDataFormatter([], props.overlays, config['faixa_etaria']);

        let opcoesKeys = Object.keys(config).sort();

        let gruposOpcoes = [
            {
                grupo: 'Censo 2010',
                opcoes: []
            },
            {
                grupo: 'Censo 2022',
                opcoes: []
            }
        ];

        opcoesKeys.map((key) => {
            let opcao = {
                value: key,
                label: config[key].title
            }

            if(config[key].censo === 2022 ){
                gruposOpcoes[1].opcoes.push(opcao);
            }
            else{
                gruposOpcoes[0].opcoes.push(opcao);
            }

        });

        this.state = {
            opcoes: gruposOpcoes,
            loading: false,
            tipoInfo: {},
            errors: {},
            dados: [],
            formatter: formatter,
            ranges_domicilios_por_numero_moradores: [],
            range_domicilios_por_numero_moradores: {
                min: 0,
                max: 0,
                column_label: '',
                column_label_slug: ''
            },
            ranges_domicilios_por_numero_moradores_error: null
        };
    }

    handleSubmit = async () => {
        debugger;
        let tipoInfo = this.state.tipoInfo;

        if (!tipoInfo || tipoInfo === '') {
            this.setState({
                errors: {
                    tipoInfo: 'O campo informação é obrigatório'
                }
            });
        }

        if( tipoInfo.startsWith('mapa_coropetico') ){
            this.props.loadMapaCloropetico( tipoInfo);

            this.props.handleClose();

            return;

        }


        let requestConfig = {
            tipoInformacao : tipoInfo,
            ranges: this.state.ranges_domicilios_por_numero_moradores
        }

        let dados = await this.props.loadDadosSensoDemografico( requestConfig );


        let configuracao = config[tipoInfo];

        if( tipoInfo === 'domicilios_por_numero_moradores' && this.state.ranges_domicilios_por_numero_moradores.length > 0 ){
            let dados = {};

            for( let range of this.state.ranges_domicilios_por_numero_moradores ){
                dados[range.column_label_slug] = range.column_label;
            }

            configuracao.dados = dados;

        }

        let formatter = new GenericDataFormatter(dados, this.props.overlays, configuracao);

        await this.setState({
            ...this.state,
            dados: dados || [],
            formatter: formatter
        });

    }

    onChangeFormFieldValue = async (field, value) => {
        await this.setState({
            [field]: value
        });

        await this.handleSubmit();
    }

    validateOverlap = (ranges, rangeToAdd) => {
        let newRanges = [...ranges, rangeToAdd];

        let overlap = false;

        for (let i = 0; i < newRanges.length; i++) {
            let range = newRanges[i];

            for (let j = 0; j < newRanges.length; j++) {
                let range2 = newRanges[j];

                if (i !== j) {
                    if (range.min <= range2.max && range.max >= range2.min) {
                        overlap = true;
                    }
                }
            }

        }

        return overlap;
    }


    addRangeNumeroMoradores = async (e) => {

        if (this.state.range_domicilios_por_numero_moradores.min < 1 ||
            this.state.range_domicilios_por_numero_moradores.max < 1 ||
            this.state.range_domicilios_por_numero_moradores.max > 10 ||
            this.state.range_domicilios_por_numero_moradores.min > 10 ||
            this.state.range_domicilios_por_numero_moradores.min > this.state.range_domicilios_por_numero_moradores.max

        ) {
            this.setState({
                ...this.state,
                ranges_domicilios_por_numero_moradores_error: 'Os valores mínimos e máximos devem ser maiores que 0 e menores que 10'
            });

            return;
        }

        if (this.state.range_domicilios_por_numero_moradores.column_label === '') {
            this.setState({
                ...this.state,
                ranges_domicilios_por_numero_moradores_error: 'O rótulo da coluna é obrigatório'
            });

            return;
        }



        let thereAreOverlaps = this.validateOverlap(
            this.state.ranges_domicilios_por_numero_moradores,
            this.state.range_domicilios_por_numero_moradores
        );

        if (thereAreOverlaps) {
            await this.setState({
                ...this.state,
                ranges_domicilios_por_numero_moradores_error: 'Existem faixas que se sobrepõem'
            });

            return;
        }


        this.setState({
            ...this.state,
            ranges_domicilios_por_numero_moradores: [
                ...this.state.ranges_domicilios_por_numero_moradores,
                this.state.range_domicilios_por_numero_moradores
            ],
            range_domicilios_por_numero_moradores: {
                min: 0,
                max: 0,
                column_label: '',
            },
            ranges_domicilios_por_numero_moradores_error: null
        });
    }

    removeRangeNumeroMoradores = async (index) => {
        let ranges = this.state.ranges_domicilios_por_numero_moradores;

        ranges.splice(index, 1);

        await this.setState({
            ...this.state,
            ranges_domicilios_por_numero_moradores: ranges
        });

    }

    render() {
        return (
            <div>

                <Dialog header="Dados de Sociodemografia" style={{ width: '60vw', marginTop: '10px' }}  visible={this.props.show} onHide={this.props.handleClose}>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="informacao">Informação</label>
                                <select className="form-control"
                                        value={this.state.tipoInfo?.value}
                                        onChange={(e) => this.onChangeFormFieldValue('tipoInfo', e.target.value)}
                                        id="informacao">
                                    <option>Selecione</option>
                                    {this.state.opcoes.map((opcao, index) => {
                                        return(
                                            <optgroup label={opcao.grupo}>
                                                {opcao.opcoes.map((opcao, index) => {
                                                    return <option key={index} value={opcao.value}>{opcao.label}</option>
                                                })}
                                            </optgroup>
                                        );
                                    })}
                                </select>
                                {this.state.errors.tipoInfo &&
                                    <small className="text-danger">{this.state.errors.tipoInfo}</small>}
                            </div>

                            {this.props.loading && <>
                                <InnerLoading/>
                            </>}

                            {this.state.tipoInfo === 'domicilios_por_numero_moradores' && !this.props.loading && <>
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                Customizar Faixas
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <div>
                                                    {this.state.ranges_domicilios_por_numero_moradores_error && <>
                                                        <div className="alert alert-danger">
                                                            {this.state.ranges_domicilios_por_numero_moradores_error}
                                                        </div>
                                                    </>
                                                    }

                                                    <table className="table table-bordered table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>Min</th>
                                                            <th>Max</th>
                                                            <th>Rótulo da Coluna</th>
                                                            <th>&nbsp;</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.ranges_domicilios_por_numero_moradores.map((range, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{range.min}</td>
                                                                    <td>{range.max}</td>
                                                                    <td>{range.column_label}</td>
                                                                    <td className="text-right">
                                                                        <button className="btn btn-danger btn-sm"
                                                                                onClick={(e) => this.removeRangeNumeroMoradores(index)}>
                                                                            <i className="fa fa-trash"/>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>

                                                    {this.state.ranges_domicilios_por_numero_moradores.length > 0 && <>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <div style={{width: '50%'}}>
                                                                <button className="btn btn-sm btn-danger btn-block"
                                                                        onClick={async (e) => {
                                                                            await this.setState({
                                                                                ...this.state,
                                                                                ranges_domicilios_por_numero_moradores: []
                                                                            });

                                                                            await this.handleSubmit();
                                                                        }}>
                                                                    Limpar Ranges
                                                                </button>
                                                            </div>
                                                            <div style={{width: '50%', marginLeft: '5px'}}>
                                                                <button className="btn btn-sm btn-default btn-block"
                                                                        onClick={async (e) => {
                                                                            await this.handleSubmit();
                                                                        }}
                                                                >
                                                                    Aplicar Ranges
                                                                </button>

                                                            </div>
                                                        </div>

                                                    </>}

                                                    <div id="container-form-ranges-renda-ibge">
                                                        <div style={{width: '30%'}}>
                                                            <label htmlFor="min">Mínimo</label>
                                                            <InputNumber id="min" name="min"
                                                                         className="d-block"
                                                                         value={this.state.range_domicilios_por_numero_moradores?.min}
                                                                         inputStyle={{width: '99%'}}
                                                                         onChange={(e) => this.setState({

                                                                             range_domicilios_por_numero_moradores: {
                                                                                 ...this.state.range_domicilios_por_numero_moradores,
                                                                                 min: e.value
                                                                             }
                                                                         })}/>
                                                        </div>

                                                        <div style={{width: '30%'}}>
                                                            <label htmlFor="min">Máximo</label>
                                                            <InputNumber id="max" name="max"
                                                                         className="d-block"
                                                                         inputStyle={{width: '99%'}}
                                                                         value={this.state.range_domicilios_por_numero_moradores?.max}
                                                                         onChange={(e) => this.setState({
                                                                             range_domicilios_por_numero_moradores: {
                                                                                 ...this.state.range_domicilios_por_numero_moradores,
                                                                                 max: e.value
                                                                             }
                                                                         })}/>
                                                        </div>
                                                        <div style={{width: '15%'}}>
                                                            <label htmlFor="column_label">Rótulo da Coluna</label>
                                                            <InputText
                                                                value={this.state.range_domicilios_por_numero_moradores?.column_label}
                                                                inputStyle={{width: '99%'}}
                                                                onChange={(e) => this.setState({
                                                                    range_domicilios_por_numero_moradores: {
                                                                        ...this.state.range_domicilios_por_numero_moradores,
                                                                        column_label: e.target.value,
                                                                        column_label_slug : 'col_' + slugfy(e.target.value)
                                                                    }
                                                                })}/>

                                                        </div>
                                                        <div style={{width: '10%'}}>
                                                            <label htmlFor="color">&nbsp;</label>
                                                            <button className="btn btn-default btn-sm btn-block"
                                                                    onClick={this.addRangeNumeroMoradores}>
                                                                Adicionar
                                                                <i className="fa fa-plus"/>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </>}

                            {useGenericTableFor.includes(this.state.tipoInfo) && !this.props.loading && <>
                                <GenericTable
                                    formatter={this.state.formatter}
                                />
                            </>
                            }

                            { (this.state.tipoInfo === 'indice_verticalizacao' || this.state.tipoInfo === 'indice_verticalizacao2022')  && !this.props.loading && <>
                                <IndiceVerticalizacao
                                    overlays={this.props.overlays}
                                    data={this.state.dados}
                                    formatter={this.state.formatter}
                                />
                            </>
                            }

                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default SocioDemografiaModal;
