import React, { Component } from 'react';

class SelectEmpreendimentoType extends Component {
    state = {}
    render() {
        return (
            <>               
                <label htmlFor="tipo_empreendimento">Tipo Empreendimento</label>
                <select className="form-control" value={this.props.tipoEmpreendimento} onChange={this.props.onChange}>
                    <option value="Horizontal">Horizontal</option>
                    <option value="Vertical">Vertical</option>
                    <option value="Comercial">Comercial</option>
                </select>                
            </>
        );
    }
}

export default SelectEmpreendimentoType;