import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Axios from "axios";
import { relativeTimeRounding } from "moment";

class Form extends Component {
    state = this.props.state;

    searchState = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await axios.post(
                "/state-json-filter-by-region/" + inputQuery,
                {
                    region: this.props.region,
                }
            );
            return response.data;
        }
    };

    searchCity = async (inputQuery) => {
        if (this.props.state.length == 0) {
            return [{ label: "Selecione ao menos um estado!" }];
        }

        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/city-json-filter-by-state/" +
                    this.props.state +
                    "/" +
                    inputQuery
            );
            return response.data;
        }
    };
    searchNeighborhoods = async (inputQuery) => {
        if (this.props.city.length == 0) {
            return [{ label: "Selecione ao menos uma cidade!" }];
        }

        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/neighborhoods-json-filter-by-city/" +
                    this.props.city +
                    "/" +
                    inputQuery
            );
            return response.data;
        }
    };

    clearState = async () => {
        await this.setState({
            ...this.state,
            period: "",
            type: [],
            category: [],
            patterns: [],
            region: [],
            state: [],
            city: [],
            neighborhoods: [],
            group_by: "",
            show: "",
            variation: "",
            periodFormated: "",
            sales: [],
            salesVariation: [],
            salesState: [],
            units: [],
            unitsState: [],
            unitsVariation: [],
            offer: [],
            offerVariation: [],
            offerState: [],
            prices: [],
            pricesVariation: [],
            pricesState: [],
            showGraf: false,
            showGraf: false,
        });

        return this.props.clearState();
    };

    handleChanged = async (event) => {
        await this.setState({
            ...this.state,
            [event.name]: event.value,
        });

        return this.props.handleChanged(event);
    };

    handleChangedArray = async (event) => {
        if (event.value == null) {
            await this.setState({
                ...this.state,
                [event.name]: [],
            });

            return this.props.handleChangedArray(event);
        }

        await this.setState({
            ...this.state,
            [event.name]: event.value,
        });

        return this.props.handleChangedArray(event);
    };

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="form-group col-4">
                                <label>Período</label>
                                <input
                                    type="month"
                                    name="period"
                                    value={this.state.period}
                                    onChange={(value) =>
                                        this.handleChanged({
                                            name: "period",
                                            value: value.target.value,
                                        })
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Tipo</label>
                                <Select
                                    value={this.state.type}
                                    placeholder="Selecione"
                                    isMulti={true}
                                    onChange={(value) => {
                                        this.handleChangedArray({
                                            name: "type",
                                            value: value,
                                        });
                                    }}
                                    options={[
                                        {
                                            value: "Comercial",
                                            label: "Comercial",
                                        },
                                        {
                                            value: "Horizontal",
                                            label: "Horizontal",
                                        },
                                        {
                                            value: "Vertical",
                                            label: "Vertical",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Categoria</label>
                                <Select
                                    value={this.state.category}
                                    placeholder="Selecione"
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.handleChanged({
                                            name: "category",
                                            value: value,
                                        })
                                    }
                                    options={[
                                        {
                                            value: "units",
                                            label: "Lançamentos",
                                        },
                                        {
                                            value: "sales",
                                            label: "Vendas",
                                        },
                                        {
                                            value: "offer",
                                            label: "Ofertas",
                                        },
                                        {
                                            value: "prices",
                                            label: "Preços",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Padrão</label>
                                <Select
                                    value={this.state.patterns}
                                    placeholder="Selecione"
                                    isMulti={true}
                                    onChange={(value) =>
                                        this.handleChangedArray({
                                            name: "patterns",
                                            value: value,
                                        })
                                    }
                                    options={[
                                        {
                                            value: "Econômico",
                                            label: "Econômico",
                                        },
                                        {
                                            value: "Standard",
                                            label: "Standard",
                                        },
                                        {
                                            value: "Médio",
                                            label: "Médio",
                                        },
                                        {
                                            value: "Alto",
                                            label: "Alto",
                                        },
                                        {
                                            value: "Compacto",
                                            label: "Compacto",
                                        },
                                        {
                                            value: "Luxo",
                                            label: "Luxo",
                                        },
                                        {
                                            value: "Super Luxo",
                                            label: "Super Luxo",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Região</label>
                                <Select
                                    isMulti={true}
                                    value={this.state.region}
                                    placeholder="Selecione"
                                    onChange={(value) =>
                                        this.handleChangedArray({
                                            name: "region",
                                            value: value,
                                        })
                                    }
                                    options={[
                                        {
                                            value: "Sul",
                                            label: "Sul",
                                        },
                                        {
                                            value: "Sudeste",
                                            label: "Sudeste",
                                        },
                                        {
                                            value: "Nordeste",
                                            label: "Nordeste",
                                        },
                                        {
                                            value: "Centro-Oeste",
                                            label: "Centro-Oeste",
                                        },
                                        {
                                            value: "Norte",
                                            label: "Norte",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Estado</label>
                                <AsyncSelect
                                    value={this.state.state}
                                    cacheOptions
                                    isMulti
                                    placeholder="Selecione"
                                    loadOptions={this.searchState}
                                    onChange={(value) =>
                                        this.handleChangedArray({
                                            name: "state",
                                            value: value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Cidade</label>
                                <AsyncSelect
                                    value={this.state.city}
                                    cacheOptions
                                    isMulti
                                    placeholder="Selecione"
                                    loadOptions={this.searchCity}
                                    onChange={(value) =>
                                        this.handleChangedArray({
                                            name: "city",
                                            value: value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Bairros</label>
                                <AsyncSelect
                                    value={this.state.neighborhoods}
                                    cacheOptions
                                    isMulti
                                    placeholder="Selecione"
                                    loadOptions={this.searchNeighborhoods}
                                    onChange={(value) =>
                                        this.handleChangedArray({
                                            name: "neighborhoods",
                                            value: value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Agrupamento</label>
                                <Select
                                    value={this.state.group_by}
                                    placeholder="Selecione"
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.handleChanged({
                                            name: "group_by",
                                            value: value,
                                        })
                                    }
                                    options={[
                                        {
                                            value: "mensal",
                                            label: "Mensal",
                                        },
                                        {
                                            value: "trimestral",
                                            label: "Trimestral",
                                        },
                                        {
                                            value: "semestral",
                                            label: "Semestral",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Variação</label>
                                <Select
                                    value={this.state.variation}
                                    placeholder="Selecione"
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.handleChanged({
                                            name: "variation",
                                            value: value,
                                        })
                                    }
                                    options={[
                                        {
                                            value: "",
                                            label: "Não Mostrar",
                                        },
                                        {
                                            value: "periodo_anterior",
                                            label: "Período Imediamente Anterior",
                                        },
                                        {
                                            value: "ano_anterior",
                                            label: "Mesmo Período do ano Anterior",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>Exibição</label>
                                <Select
                                    placeholder="Selecione"
                                    value={this.state.show}
                                    isMulti={false}
                                    onChange={(value) =>
                                        this.handleChanged({
                                            name: "show",
                                            value: value,
                                        })
                                    }
                                    options={[
                                        {
                                            value: "ambos",
                                            label: "Mostrar Ambos",
                                        },
                                        {
                                            value: "grafico",
                                            label: "Exibir apenas Gráfico",
                                        },
                                        {
                                            value: "mapa",
                                            label: "Exibir apenas Mapa",
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-success btn-block"
                                    onClick={this.props.post}
                                >
                                    <i className="fas fa-search"></i> Buscar
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-block"
                                    style={{ backgroundColor: "#FFBB27" }}
                                    onClick={this.clearState}
                                >
                                    <i className="far fa-file"></i> Limpar
                                </button>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form;
