import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import ImageSlider from "./ImageSlider";
import { Transition } from "react-transition-group";
import * as PropTypes from "prop-types";
// import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

moment.locale("pt-br");


class ModalEmpreendimentos extends Component {
    state = {};

    convertDate = () => {
        return moment(this.props.empreendimento.updatedAt).format("DD/MM/YYYY HH:mm:ss");
    }
    render() {


        let ConvertNumber = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        return (
            <Modal
                size="lg"
                dialogClassName="dialogWidth"
                style={{ zIndex: 9999999 }}
                show={this.props.show}
                onHide={this.props.closeModalEmpreendimento}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"><i className="far fa-building"></i> &nbsp; {this.props.empreendimento.titulo}</h3>
                            <small>{this.props.empreendimento.tipo}</small><br />
                            <span className="badge badge-success">
                                <strong>
                                    <small>
                                        Status: {this.props.empreendimento.tipo_negocio}
                                    </small>
                                </strong>
                            </span>
                            <br/>
                            <strong>Atualizado em: </strong>&nbsp;{this.convertDate()}


                            <hr />
                        </div>
                        <div className="card-body" style={{ paddingLeft: 0, color: '#03260a' }}>
                            <Tabs defaultActiveKey="empreendimento"
                                id="uncontrolled-tab-example"
                                className="mb-3">
                                <Tab eventKey="empreendimento" title="Imóvel">
                                    {this.props.empreendimento.fotos.length > 0 && <ImageSlider images={this.props.empreendimento.fotos} />}
                                    <i className="nc-icon nc-pin-3"></i> &nbsp;
                                    {this.props.empreendimento.logradouro}, {this.props.empreendimento.numero} {this.props.empreendimento.bairro}, {this.props.empreendimento.cidade} - {this.props.empreendimento.estado}
                                    <hr />
                                    <strong>Preço: </strong>{ConvertNumber.format(this.props.empreendimento.preco)} <br />
                                    <strong>Área Total: </strong>{ConvertNumber.format(this.props.empreendimento.area_total).replace("R$", "")} <br />
                                    <strong>Preço Metro² Total: </strong>{ConvertNumber.format(this.props.empreendimento.preco /this.props.empreendimento.area_total  )} <br />
                                    <strong>Área Útil: </strong>{ConvertNumber.format(this.props.empreendimento.area_util).replace("R$", "")} <br />
                                    <strong>Preço Metro² Útil: </strong>{ConvertNumber.format(this.props.empreendimento.preco / this.props.empreendimento.area_util)} <br />

                                    <hr />
                                </Tab>

                                <tab eventKey="ficha" title="Ficha">
                                    <h5 style={{ color: '#00480E' }}>Ficha</h5>
                                    <div>
                                        <strong>Anunciante: {this.props.empreendimento.anunciante} </strong> <br />
                                        <strong>Endereço do Anúncio: <a href={this.props.empreendimento.anuncioUrl} target="_blank">{this.props.empreendimento.anuncioUrl} </a></strong> <br />
                                        <strong>Quartos: {this.props.empreendimento.quartos ?? 0} </strong> <br />
                                        <strong>Suites: {this.props.empreendimento.suites ?? 0} </strong> <br />
                                        <strong>Vagas: {this.props.empreendimento.parkingSpaces ?? 0} </strong> <br />
                                        <strong>Iptu: {this.props.empreendimento.itpu ?? 0} </strong> <br />
                                        <strong>Condomínio: {ConvertNumber.format(this.props.empreendimento.condominio) ?? 0} </strong> <br />
                                        <hr />
                                        <strong>Descrição: </strong>
                                        <p dangerouslySetInnerHTML={{
                                            __html: this.props.empreendimento.descricao
                                        }}></p>
                                    </div>
                                </tab>
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="btn-block"
                        onClick={this.props.togglePainelEmpreendimento}
                    >
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalEmpreendimentos;
