import md5 from "md5";

export default class ZoneamentoService {

    colors =  [
        '#a1d070' ,
        '#ffff00' ,
        '#80ff00' ,
        '#00ff00' ,
        '#00ff80' ,
        '#ff7000' ,
        '#00ffff' ,
        '#0080ff' ,
        '#0000ff' ,
        '#8000ff' ,
        '#ff00ff' ,
        '#ff0080' ,
        '#FFC0CB' ,
        '#008080' ,
        '#800000' ,
        '#556B2F' ,
        '#006400' ,
        '#808000' ,
        '#BC8F8F' ,
        '#DEB887' ,
        '#8B008B' ,
        '#DC143C' ,
    ];

    legendaZoneamento = [];


    properties = {
        "nm_municipio" : "Munícipio",
        "sigla_zoneamento" : "Sigla Zoneamento",
        "nm_zoneamento" : "Nome Zoneamento",
        "descricao" : "Descrição",
        "permissao_horizontal" : "Permissão Horizontal",
        "permissao_vertical" : "Permissão Vertical",
        "permissao_horizontal_e_vertical" : "Permissão Horizontal e Vertical",
        "categoria" : "Categoria",
        "lotes" : "Lotes",
        "quadras" : "Quadras",
        "fracao" : "Fração",
        "area_unida" : "Área Unida",
        "pavimentos" : "Pavimentos",
        "sala" : "Sala",
        "banheiro" : "Banheiro",
        "cozinha" : "Cozinha",
        "dormitorio" : "Dormitório",
        "pe_direito" : "Pé direito",
        "vagas" : "Vagas",
        "elevadores" : "Elevadores",
        "descricao_macrozoneamento" : "Descrição Macrozoneamento",
        "ocupacao" : "Ocupação",
        "aproveitamento" : "Aproveitamento",
        "estacionamento" : "Estacionamento",
        "area_computada" : "Área Computada",
        "subdivisao" : "Subdivisão",
        "usos_permitidos" : "Usos Permitidos",
        "usos_permissiveis_ou_condicionaveis" : "Usos Permitidos ou Condicionáveis",
        "usos_proibidos" : "Usos Proibidos",
        "taxa_de_permeabilidade" : "Taxa de Permeabilidade",
        "densidade_maxima" : "Densidade Máxima",
        "incentivos_construtivos" : "Incentivos Construtivos",
        "recuos" : "Recuos",
        "observacoes" : "Observações",
        "fonte" : "Fonte",
    };

    /** Propriedades usadas como filtro **/
    propertiesUsedForFilters = [
        "permissao_horizontal", "permissao_vertical"
    ];

    getZoneamento = async ({cidade, estado}) => {
        return await axios.get(route('web.zoneamento.index', {
            cidade: encodeURI(cidade), estado: encodeURI(estado),
        }));
    }

    prepareData = (geojson )=>{



        let availableColors = this.colors;
        let zoneamentoColors = {};

        let valoresPropriedades = {};

        for ( let key in this.properties) {
            valoresPropriedades[key] = new Set();
        }

        let legendaZoneamento = [];

        for( let feature of geojson.features ){

            feature.properties.visible = true;


            for ( let key in feature.properties){
                let propertyValue = feature.properties[key];

                if( propertyValue && valoresPropriedades[key]){
                    valoresPropriedades[key].add(propertyValue);
                }
            }

            let siglaZona = feature.properties.sigla_zoneamento;
            let nomeZona = feature.properties.nm_zoneamento;

            //tem uma cor para siglaZona em zoneamentoColor
            if ( zoneamentoColors[siglaZona] ){
                feature.properties.color = zoneamentoColors[siglaZona];
            }else {
                let color = availableColors.shift();
                feature.properties.color = color;
                zoneamentoColors[siglaZona] = color;

                let value = siglaZona;

                if( nomeZona ){
                    value += ` - ${nomeZona}`;
                }

                let legenda = {
                    key: siglaZona,
                    value: value,
                    color: color
                }

                legendaZoneamento.push(legenda);
            }
        }

        legendaZoneamento.sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            if (a.value > b.value) {
                return 1;
            }
            return 0;
        });

        let valorePropriedadesSemVazios = {};
        let opcoesDropdownProperties = [{
            label: 'Selecione',
            value: '',
        }];

        for( let key in valoresPropriedades ){
            if (valoresPropriedades[key].size > 0){
                valorePropriedadesSemVazios[key] = [...valoresPropriedades[key]];

                if( this.propertiesUsedForFilters.includes( key ) ){
                    opcoesDropdownProperties.push({
                        label: this.properties[key],
                        value: key
                    });
                }
            }
        }

        return {
            legenda: legendaZoneamento,
            geojson: geojson,
            valoresPropriedades: valorePropriedadesSemVazios,
            opcoesDropdownProperties: opcoesDropdownProperties
        };

    }


    getZoneAvailability = async ({cidade, estado}) => {
        return axios.post( route('web.zone.available'), {
            cidade, estado
        });
    }

}
