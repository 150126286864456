import * as XLSX from 'xlsx';

// Função para obter dados da tabela
const getTableData = (tableId) => {
  const headers = [];
  const tableData = [];

  const table = document.querySelector(tableId);
  if (!table) {
    console.warn(`Tabela com id ${tableId} não encontrada.`);
    return [];
  }

  // Extraindo os cabeçalhos da tabela
  const headerRows = table.querySelectorAll('thead tr');
  if (headerRows.length > 0) {
    headerRows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll('th').forEach((cell) => {
        rowData.push(cell.textContent.trim());
      });
      headers.push(rowData);
    });
  }

  // Extraindo os dados do corpo da tabela
  const rows = table.querySelectorAll('tbody tr');
  rows.forEach((row) => {
    const rowData = [];
    row.querySelectorAll('td, th').forEach((cell) => {
      const cellText = cell.textContent.replaceAll('.', '').trim();
      if (!isNaN(cellText) && cellText !== "") {
        rowData.push({ v: parseFloat(cellText), t: 'n', z: '#,##0' });
      } else {
        rowData.push({ v: cellText });
      }
    });
    tableData.push(rowData);
  });

  // Retornando cabeçalhos e dados combinados
  return headers.concat(tableData);
};

// Função para gerar a planilha Excel
const generateExcelData = (key, wb, titulo) => {
  const tableId = `#excel1`;
  const excelData = getTableData(tableId);

  if (excelData.length > 0) {
    const aoaData = excelData.map((row) => row.map((cell) => (typeof cell === 'object' ? cell.v : cell)));
    const ws = XLSX.utils.aoa_to_sheet(aoaData);
    XLSX.utils.book_append_sheet(wb, ws, titulo);
  }
};

// Função para exportar os dados
const exportData = (rows) => {
  const wb = XLSX.utils.book_new();

  generateExcelData(1, wb, 'Relatório');

  XLSX.writeFile(wb, 'acompanhamento.xlsx');
};

export default exportData;
