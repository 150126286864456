import React, {Component} from "react";


const labels = {
    "Venda": "Revenda",
    "Aluguel": "Locação"
}

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    monetaryFormat = (value) => {
        let doubleFormatter = new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 0
        });

        return doubleFormatter.format(value);
    }

    areaFormat = (value) => {
        let doubleFormatter = new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 2
        });

        return doubleFormatter.format(value);
    }

    render() {
        var totalEmpreendimentos = 0;
        return (
            <div style={{marginBottom: '40px'}}>
                <table className="table table-striped table-condensed table-padroes">
                    <thead>
                    <tr className="text-center" style={{
                        backgroundColor: this.props.color,
                        color: "#ffffff",
                        fontWeight: "bold",
                    }}
                    >
                        <td colSpan="8">
                            <span style={{fontWeight: "bold"}}>
                                {labels[this.props.title]}
                            </span>
                        </td>
                    </tr>
                    <tr
                        className="text-center"
                        style={{
                            backgroundColor: this.props.color,
                            color: "#ffffff",
                            fontWeight: "bold",
                        }}
                    >
                        <th
                            className="text-center"
                            style={{color: "#ffffff", padding: "7px"}}
                        >
                            <span style={{fontWeight: "bold"}}>
                                Tipo Imóvel
                            </span>
                        </th>
                        <th
                            className="text-center"
                            style={{color: "#ffffff", padding: "7px"}}
                        >
                            <span style={{fontWeight: "bold"}}>Nº Imóveis</span>
                        </th>
                        <th
                            className="text-center"
                            style={{color: "#ffffff", padding: "7px"}}
                        >
                            <span style={{fontWeight: "bold"}}>(%)</span>
                        </th>
                        <th
                            className="text-center"
                            style={{color: "#ffffff", padding: "7px"}}
                        >
                            <span style={{fontWeight: "bold"}}>
                                Área Média Total
                            </span>
                        </th>
                        <th
                            className="text-center"
                            style={{color: "#ffffff", padding: "7px"}}
                        >
                            <span style={{fontWeight: "bold"}}>
                                Área Média Útil
                            </span>
                        </th>
                        <th
                            className="text-center"
                            style={{color: "#ffffff", padding: "7px"}}
                        >
                            <span style={{fontWeight: "bold"}}>
                                Preço Médio
                            </span>
                        </th>
                        <th
                            className="text-center"
                            style={{color: "#ffffff", padding: "7px"}}
                        >
                            <span style={{fontWeight: "bold"}}>
                                Preço Médio M²
                            </span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>

                    {this.props.metadata.map((index, key) => {
                        totalEmpreendimentos += parseInt(index.total);

                        return (
                            <tr className="text-center" key={key}>
                                <td className="text-center">{index.tipo}</td>
                                <td className="text-center">{index.total.toLocaleString("pt-BR")}</td>
                                <td className="text-center">{Number((index.total * 100) / this.props.count_buildings).toFixed(2).toLocaleString().replace('.', ',')}%</td>
                                <td className="text-center">{this.areaFormat(index.media_area_total)}</td>
                                <td className="text-center">{this.areaFormat(index.media_area_util)}</td>
                                <td className="text-center">{this.monetaryFormat(index.media_preco)}</td>
                                <td className="text-center">{this.monetaryFormat(index.media_preco_metro)}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td className="text-center">Total</td>
                        <td className="text-center">
                            {totalEmpreendimentos.toLocaleString("pt-BR")}
                        </td>
                        <td className="text-center">100%</td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
}

export default Table;
