import { random } from 'lodash';
import React from 'react';

const detalhesSemSoma = [
    'ivv',
    'oferta_final',
    'vgv_oferta_lancada',
    'preco_metro_medio',
    'preco_metro_minimo',
    'preco_metro_maximo',
    'area_privativa_minima',
    'area_privativa_media',
    'area_privativa_maxima',
    'preco_medio',
    'avg_porcentagem_entrada',
    'avg_percentual_desconto',
];

class TableRelatorioAcompanhamentoDinamico extends React.Component {

    componentDidMount() {
    }

    formatNumber = (number) => {
        if (isNaN(number)) {
            number = 0;
        }

        return new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(number);
    }

    formatNumberAsPercentage = (number) => {
        if (isNaN(number)) {
            number = 0;
        }
        return new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(number);
    }

    formatPeriodString = (period) => {

        if (typeof period === 'number') {
            period = period.toString();
        }

        if (this.props.response_period_type === 'mensal') {
            let periodsParts = period.split('-');
            return `${periodsParts[1]}/${periodsParts[0]}`;
        }

        if (this.props.response_period_type === 'trimestre') {
            let periods = period.split('-');
            return `${periods[0]}T/${periods[1]}`;
        }

        if (this.props.response_period_type === 'semestre') {

            let periods = period.split('-');

            return `${periods[0]}S/${periods[1]}`;
        }

        if (this.props.response_period_type === 'anual') {
            return period;
        }

    }

    getDetailsColumns = () => {
        let columns = ["Detalhe"]; // "Detalhe" é sempre incluído

        // Array de tipos que devem incluir "Tipo"
        const tiposComTipo = [
            "Padrão",
            "Tipo",
            "Bairro",
            "Cidade",
            "Estado",
            "Incorporadora",
            "Cluster",
            "Estágio de Obra e Padrão",
            "Estágio de Obra e Tipo"
        ];

        // Adiciona "Tipo" se algum dos tipos estiver presente
        if (tiposComTipo.some(tipo => this.props.tipo_detalhamento.includes(tipo))) {
            columns.push("Tipo");
        }

        // Condições específicas para outros campos
        if (this.props.tipo_detalhamento.includes("Padrão")) {
            columns.push("Padrão");
        }

        if (this.props.tipo_detalhamento.includes("Bairro")) {
            columns.push("Cidade", "Bairro");
        }

        if (this.props.tipo_detalhamento.includes("Cidade")) {
            columns.push("Cidade");
        }

        if (this.props.tipo_detalhamento.includes("Estado")) {
            columns.push("Estado");
        }

        if (this.props.tipo_detalhamento.includes("Tipologia")) {
            columns.push("Tipologia");
        }

        if (this.props.tipo_detalhamento.includes("Incorporadora")) {
            columns.push("Incorporadora");
        }

        if (this.props.tipo_detalhamento.includes("Ano de Lançamento")) {
            columns.push("Ano de Lançamento");
        }

        if (this.props.tipo_detalhamento.includes("Cluster")) {
            columns.push("Cidade", "Cluster");
        }

        if (this.props.tipo_detalhamento.includes("Estágio de Obra e Padrão")) {
            columns.push("Padrão", "Estágio de Obra");
        }

        if (this.props.tipo_detalhamento.includes("Estágio de Obra e Tipo")) {
            columns.push("Estágio de Obra");
        }

        return [...new Set(columns)];
    };


    formatTipologia = (tipologia) => {
        if (tipologia == 1) {
            return `${tipologia} Quarto`;
        }

        if (tipologia == 4) {
            return `${tipologia}+ Quartos`;
        }

        return `${tipologia} Quartos`;

    }

    getHeadersColumns = () => {

        let detailsColumns = this.getDetailsColumns();

        let periodsColumns = this.props.periodos.map((periodo, index) => {
            return this.formatPeriodString(periodo);
        });

        let columns = detailsColumns.concat(periodsColumns);

        columns.push("Total");

        return columns;

    }

    groupRowsByAttributes = (rows) => {
        let attributes = [
            { label: "Padrão", value: "padrao" },
            { label: "Tipo", value: "tipo" },
            { label: "Bairro", value: "bairro" },
            { label: "Cluster", value: "cluster" },
            { label: "Cidade", value: "cidade" },
            { label: "Tipologia", value: "tipologia" },
            { label: "Incorporadora", value: "incorporadora" },
            { label: "Ano de Lançamento", value: "ano_lancamento" },
            { label: "Estado", value: "estado" },
            { label: "Estágio de Obra e Padrão", value: "estagio" },
            { label: "Estágio de Obra e Padrão", value: "estagio" },
        ];

        const tipoDetalhamento = this.props.tipo_detalhamento.split(",");
        const filteredAttributes = attributes
            .filter((attr) => tipoDetalhamento.includes(attr.label))
            .map((attr) => attr.value);

        const grouped = {};

        rows.forEach((row) => {
            let key = filteredAttributes.map((attr) => row[attr] || "").join("-");

            if (!grouped[key]) {
                grouped[key] = [];
            }

            grouped[key].push(row);
        });

        return grouped;
    };


    render() {
        let headersColumns = this.getHeadersColumns();
        const estados = {
            'AC': 'Acre',
            'AL': 'Alagoas',
            'AP': 'Amapá',
            'AM': 'Amazonas',
            'BA': 'Bahia',
            'CE': 'Ceará',
            'DF': 'Distrito Federal',
            'ES': 'Espírito Santo',
            'GO': 'Goiás',
            'MA': 'Maranhão',
            'MT': 'Mato Grosso',
            'MS': 'Mato Grosso do Sul',
            'MG': 'Minas Gerais',
            'PA': 'Pará',
            'PB': 'Paraíba',
            'PR': 'Paraná',
            'PE': 'Pernambuco',
            'PI': 'Piauí',
            'RJ': 'Rio de Janeiro',
            'RN': 'Rio Grande do Norte',
            'RS': 'Rio Grande do Sul',
            'RO': 'Rondônia',
            'RR': 'Roraima',
            'SC': 'Santa Catarina',
            'SP': 'São Paulo',
            'SE': 'Sergipe',
            'TO': 'Tocantins'
        };

        const renderDetalhes = (row, estados) => {
            return (
                <>
                    {row.hasOwnProperty("tipo") && <th>{row.tipo}</th>}
                    {row.hasOwnProperty("padrao") && <th>{row.padrao}</th>}
                    {row.hasOwnProperty("cidade") && <th>{row.cidade}</th>}
                    {row.hasOwnProperty("bairro") && <th>{row.bairro}</th>}
                    {row.hasOwnProperty("incorporadora") && <th>{row.incorporadora}</th>}
                    {row.hasOwnProperty("ano_lancamento") && <th>{row.ano_lancamento}</th>}
                    {row.hasOwnProperty("cluster") && <th>{row.cluster}</th>}
                    {row.hasOwnProperty("estagio") && <th>{row.estagio}</th>}
                    {row.hasOwnProperty("tipologia") && <th>{this.formatTipologia(row.tipologia)}</th>}
                    {row.hasOwnProperty("estado") && <th>{estados[row.estado]}</th>}
                </>
            );
        };

        const groupedRows = this.groupRowsByAttributes(this.props.rows);


        return (
            <>
                <hr />
                <table className='table table-striped acompanhamento' style={{ textAlign: "center" }} id={`excel${this.props.id}`}>
                    <thead>
                        <tr style={{ backgroundColor: "#5B7537", color: "#ffffff", textAlign: 'center', fontWeight: 'bold' }}>
                            {headersColumns.map((column, index) => (
                                <th key={index} style={{ backgroundColor: "#5B7537", color: "#ffffff", textAlign: 'center', fontWeight: 'bold' }}>{column}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.detalhesSelecionados.map((detalhe) => {
                            detalhe = detalhe.value;
                            let isDetalheSemSoma = detalhesSemSoma.includes(detalhe);

                            return Object.keys(groupedRows).map((key) => {
                                let rows = groupedRows[key];
                                let firstRow = rows[0];
                                let soma = 0;

                                return (
                                    <React.Fragment key={`${key}-detalhe`}>
                                        <tr>
                                            <th>{this.props.detalhesLabel[detalhe]}</th>
                                            {renderDetalhes(firstRow, estados)}

                                            {this.props.periodos.map((periodo, index) => {
                                                let matchedRows = rows.filter((row) => row.periodo === periodo);

                                                let valor = matchedRows.length > 0 ? matchedRows.reduce((acc, row) => acc + (row[detalhe] || 0), 0) : 0;

                                                soma += valor;

                                                return (
                                                    <td key={`${periodo}-${index}`} style={{ borderColor: "#E3E3E3" }}>{this.formatNumber(valor)}</td>
                                                );
                                            })}

                                            <td>{isDetalheSemSoma ? '-' : this.formatNumber(soma)}</td>
                                        </tr>
                                        {this.props.variacao && (
                                            <tr>
                                                <th>Variação - {this.props.detalhesLabel[detalhe]}</th>
                                                {renderDetalhes(firstRow, estados)}

                                                {this.props.periodos.map((periodo, index) => {
                                                    let matchedRows = rows.filter((row) => row.periodo === periodo);

                                                    let row = matchedRows.length > 0 ? matchedRows.reduce((acc, currRow) => {

                                                        Object.keys(currRow).forEach((key) => {
                                                            acc[key] = (acc[key] || 0) + (currRow[key] || 0);
                                                        });
                                                        return acc;
                                                    }, {}) : {};

                                                    let valor = this.getVariacao(row, periodo, rows, detalhe);

                                                    if (valor == 0) {
                                                        valor = '-';
                                                    } else {
                                                        valor = this.formatNumberAsPercentage(valor) + '%';
                                                    }
                                                    return (
                                                        <td key={index} style={{ borderColor: "#E3E3E3" }}>
                                                            {valor < 0 && <i className="fas fa-angle-down" style={{ color: "red" }}></i>}
                                                            {valor > 0 && <i className="fas fa-angle-up" style={{ color: "green" }}></i>}
                                                            &nbsp; {valor}
                                                        </td>
                                                    )
                                                })}
                                                <td> - </td>

                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            });
                        })}
                    </tbody>

                </table>
                <hr />
            </>
        );
    }


    getPeriodoAnterior = (periodo) => {

        return this.props.periodos_variacao[this.props.periodos.indexOf(periodo)];

    }

    getVariacao = (row, periodo, rows, detalhe) => {
        let variacao = 0;
        let periodoAnterior = this.getPeriodoAnterior(periodo);

        let valorAtual = row[detalhe] || 0;

        let linhaAnterior = rows.find((r) => r.periodo === periodoAnterior);

        console.log(row, linhaAnterior);

        let valorAnterior = linhaAnterior ? linhaAnterior[detalhe] || 0 : 0;

        if (valorAtual == 0 && valorAnterior == 0) {
            return 0;
        }

        if (valorAnterior == 0) {
            return 100;
        }

        variacao = (valorAtual / valorAnterior - 1) * 100;

        if (isNaN(variacao)) {
            return 0;
        }

        return variacao;
    }

}


export default TableRelatorioAcompanhamentoDinamico;
