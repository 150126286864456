import Axios from "axios";

class SimpleEmpreendimentosService {
    baseUrlEmpreendimentos = route('web.empreendimentos.simple.json');


    extractValuesArray = (array) => {
        let newArray = [];

        array.forEach((element) => {
            newArray.push(element.value)
        });

        return newArray;
    }

    extractValuesArrayFromCidade = (array) => {
        let newArray = [];

        array.forEach((element) => {
            let elementParts = element.value.split('-');
            let cidade = elementParts[1].trim();
            newArray.push(cidade)
        });

        return newArray;
    }

    getEmpreendimentosWithinPolygon = async (path, state) => {
        let { tipo_empreendimento, padrao, area_maior_que, area_menor_que, quartos, status, valor_menor_que,
            valor_maior_que, valor_metro_maior_que,
            valor_metro_menor_que, cidade, estado, bairros, ano_lancamento, incorporadoras, nome_empreendimento,
            juros_minimo, juros_maximo, parcelas_minimo, parcelas_maximo, valor_parcela_maxima,
            valor_parcela_minima, porcentagem_vendida_minima, porcentagem_vendida_maxima, estagio, mes_ano_lancamento, retrofit, regiao
        } = state;

        let extractStatusValue = this.extractValuesArray(status);
        status = extractStatusValue.length > 0 ? extractStatusValue : ['Comercialização', 'Esgotado', 'Futuro'];
        padrao = this.extractValuesArray(padrao);
        let bairro = this.extractValuesArray(bairros);
        let nome = this.extractValuesArray(nome_empreendimento);
        cidade = this.extractValuesArrayFromCidade(cidade)
        incorporadoras = this.extractValuesArray(incorporadoras)
        ano_lancamento = this.extractValuesArray(ano_lancamento)
        retrofit = this.extractValuesArray(retrofit)
        let area_util_maior_que = area_maior_que;
        let area_util_menor_que = area_menor_que;
        estagio = this.extractValuesArray(estagio);
        regiao = this.extractValuesArray(regiao);
        const requestBody = {
            tipo_empreendimento,
            padrao,
            mes_ano_lancamento,
            cidade,
            estado,
            bairro,
            area_util_maior_que,
            area_util_menor_que,
            status,
            quartos,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            ano_lancamento,
            'polygon': path,
            incorporadoras,
            nome,
            juros_minimo,
            juros_maximo,
            parcelas_minimo,
            parcelas_maximo,
            valor_parcela_maxima,
            valor_parcela_minima,
            porcentagem_vendida_minima,
            porcentagem_vendida_maxima,
            estagio,
            retrofit,
            regiao
        }

        try {
            const empreendimentos = await axios.post(this.baseUrlEmpreendimentos,
                requestBody
            );

            return empreendimentos;

        } catch (error) {
            console.log('error');
        }

    }

    getEmpreendimentosWithinRadius = async (latitude, longitude, radius, state) => {
        let { tipo_empreendimento, padrao, area_maior_que, area_menor_que, quartos, status, valor_menor_que, valor_maior_que, valor_metro_maior_que, valor_metro_menor_que, cidade,
            estado, bairros, ano_lancamento, incorporadoras, nome_empreendimento,
            juros_minimo, juros_maximo, parcelas_minimo, parcelas_maximo,
            valor_parcela_maxima, valor_parcela_minima, porcentagem_vendida_minima, porcentagem_vendida_maxima, retrofit, estagio, mes_ano_lancamento, regiao } = state;

        let extractStatusValue = this.extractValuesArray(status);
        status = extractStatusValue.length > 0 ? extractStatusValue : ['Comercialização', 'Esgotado', 'Futuro'];
        padrao = this.extractValuesArray(padrao);
        let bairro = this.extractValuesArray(bairros);
        let nome = this.extractValuesArray(nome_empreendimento);
        cidade = this.extractValuesArrayFromCidade(cidade);
        incorporadoras = this.extractValuesArray(incorporadoras);
        ano_lancamento = this.extractValuesArray(ano_lancamento)
        let area_util_maior_que = area_maior_que;
        let area_util_menor_que = area_menor_que;
        estagio = this.extractValuesArray(estagio);
        retrofit = this.extractValuesArray(retrofit)
        regiao = this.extractValuesArray(regiao);

        const requestBody = {
            tipo_empreendimento,
            cidade,
            estado,
            mes_ano_lancamento,
            bairro,
            padrao,
            area_util_maior_que,
            area_util_menor_que,
            quartos,
            status,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            incorporadoras,
            ano_lancamento,
            'circle': {
                'radius': radius,
                'latitude': latitude,
                'longitude': longitude,
            },
            nome,
            juros_minimo,
            juros_maximo,
            parcelas_minimo,
            parcelas_maximo,
            valor_parcela_maxima,
            valor_parcela_minima,
            porcentagem_vendida_minima,
            porcentagem_vendida_maxima,
            estagio,
            retrofit,
            regiao

        }

        try {
            const empreendimentos = await axios.post(this.baseUrlEmpreendimentos,
                requestBody
            );

            return empreendimentos;

        } catch (error) {
            console.log('Erro ao Buscar Empreendimentos');
            console.log(error);
        }
    }

    getEmpreendimentoOnCidade = async (state) => {
        let { tipo_empreendimento, padrao, area_maior_que, area_menor_que, quartos, status, valor_menor_que, valor_maior_que, valor_metro_maior_que,
            valor_metro_menor_que, cidade, estado, bairros, ano_lancamento, incorporadoras, nome_empreendimento,
            juros_minimo, juros_maximo, parcelas_minimo, parcelas_maximo,
            valor_parcela_maxima, valor_parcela_minima, porcentagem_vendida_minima, retrofit, porcentagem_vendida_maxima, estagio, mes_ano_lancamento,
            regiao
        } = state;

        let extractStatusValue = this.extractValuesArray(status);
        status = extractStatusValue.length > 0 ? extractStatusValue : ['Comercialização', 'Esgotado', 'Futuro'];

        padrao = this.extractValuesArray(padrao);
        let nome = this.extractValuesArray(nome_empreendimento);
        cidade = this.extractValuesArrayFromCidade(cidade)
        incorporadoras = this.extractValuesArray(incorporadoras)
        ano_lancamento = this.extractValuesArray(ano_lancamento)
        let area_util_maior_que = area_maior_que;
        let area_util_menor_que = area_menor_que;
        let bairro = this.extractValuesArray(bairros);
        estagio = this.extractValuesArray(estagio);
        retrofit = this.extractValuesArray(retrofit);
        regiao = this.extractValuesArray(regiao);

        const requestBody = {
            tipo_empreendimento,
            cidade,
            mes_ano_lancamento,
            estado,
            bairro,
            padrao,
            area_util_maior_que,
            area_util_menor_que,
            quartos,
            status,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            ano_lancamento,
            incorporadoras,
            nome,
            juros_minimo,
            juros_maximo,
            parcelas_minimo,
            parcelas_maximo,
            valor_parcela_maxima,
            valor_parcela_minima,
            porcentagem_vendida_minima,
            porcentagem_vendida_maxima,
            estagio,
            retrofit,
            regiao
        }

        try {
            const empreendimentos = await axios.post(this.baseUrlEmpreendimentos,
                requestBody
            );

            return empreendimentos;

        } catch (error) {
            console.log('Erro ao Buscar Empreendimentos');
            console.log(error);
        }
    }
}

export default new SimpleEmpreendimentosService();
