import React, { Component } from 'react';
import Pin from "./Pin/Pin";

class Legenda extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            markers: []
        };
    }

    componentDidMount = async () => {
        this._isMounted = true;
        await this.loadMarkers();
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    loadMarkers = async () => {
        let markers = localStorage.getItem('markers');

        if (markers && this._isMounted) {
            markers = JSON.parse(markers);
            this.setState({
                ...this.state,
                markers: markers
            });

            return;
        }

        let response = await axios.get(route('building-markers')).catch(error => {
            console.log(error);
        });


        if (response.status === 200 && this._isMounted) {
            await this.setState({
                ...this.state,
                markers: response.data
            });
        }


    }

    render() {

        const labels = {
            "area_media": "Área média",
            "ticket_medio_metro": "Ticket Médio R$/m²",
            "total_unidades": "Oferta Inicial",
            "oferta_final": "Estoque",
        }

        return (
            <div>
                <table className="table table-striped table-condensed legenda-mapa">
                    <thead>
                        <tr>
                            <th colSpan="2" className="" style={{ paddingBottom: 7 }}>
                                <span className="font-weight-bold text-center"
                                    style={{ color: 'rgb(0, 72, 14)', fontSize: 16 }}>
                                    LEGENDA
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.ranges.map((range) => {

                            let image = null;

                            if (range.marker.type === 'image') {
                                image = range.marker.value;
                            }

                            let hexaBackground = null;

                            if (range.marker.type === 'color') {
                                hexaBackground = range.marker.background;
                            }

                            return (
                                <tr key={range.id}>
                                    <td>
                                        {hexaBackground && <Pin backgroundColor={hexaBackground}
                                            borderColor={range.marker.borderColor}
                                            glyphColor={range.marker.glyphColor}
                                        />}

                                        {image && <img src={image} alt="image" />}

                                    </td>
                                    <td>

                                        {range.type === 'padroes' && <>
                                            {range.padroes.toString()}
                                        </>}

                                        {range.type !== 'padroes' && <>
                                            {labels[range.type]} a partir de {Number(range.min).toLocaleString()}
                                            {(() => {
                                                if (range.max === 99999999) {
                                                    return (null)
                                                }
                                                return " até " + Number(range.max).toLocaleString()
                                            })()}
                                        </>}
                                    </td>
                                </tr>
                            )
                        })}

                        {this.state.markers.map((marker) => {
                            let randomNumber = Math.floor(Math.random() * (999999 - 100000)) + 100000;
                            return (
                                <tr key={`${marker.status}.${marker.tipo}.${marker.status}.${randomNumber}`}>
                                    <td>
                                        <img src={marker.icon} alt="" />
                                    </td>
                                    <td>
                                        {`${marker.tipo} ${marker.padrao} ${marker.status}`}
                                    </td>
                                </tr>
                            );
                        })
                        }


                    </tbody>

                </table>
                {window.verMarcadoresRevenda === true &&
                    <table className="table table-striped table-condensed legenda-mapa">
                        <thead>
                            <tr>
                                <th
                                    colSpan="2"
                                    className=""
                                    style={{ paddingBottom: 7 }}
                                >
                                    <span
                                        className="font-weight-bold text-center"
                                        style={{
                                            color: "rgb(0, 72, 14)",
                                            fontSize: 16,
                                        }}
                                    >
                                        LEGENDA REVENDA E LOCAÇÃO
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img src="/img/markers/apartamentos.png" alt="" />
                                </td>
                                <td>Apartamentos Revenda</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="/img/markers/apartamentos_aluguel.png" alt="" />
                                </td>
                                <td>Apartamentos Locação</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="/img/markers/residencial.png" alt="" />
                                </td>
                                <td>Residencias Revenda</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="/img/markers/residencial_aluguel.png" alt="" />
                                </td>
                                <td>Residencias Locação</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="/img/markers/comercial.png" alt="" />
                                </td>
                                <td>Comercial Revenda</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="/img/markers/comercial_aluguel.png" alt="" />
                                </td>
                                <td>Comercial Locação</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="/img/markers/lotes-terrenos.png" alt="" />
                                </td>
                                <td>Lotes e Terrenos Revenda</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="/img/markers/lotes-terrenos_aluguel.png" alt="" />
                                </td>
                                <td>Lotes e Terrenos Locação</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src="/img/markers/industrial-garagem.png"
                                        alt=""
                                    />
                                </td>
                                <td>Industrial e Garagens Revenda</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src="/img/markers/industrial-garagem_aluguel.png"
                                        alt=""
                                    />
                                </td>
                                <td>Industrial e Garagens Locação</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="/img/markers/rural.png" alt="" />
                                </td>
                                <td>Rural Revenda</td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="/img/markers/rural_aluguel.png" alt="" />
                                </td>
                                <td>Rural Locação</td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>

        );
    }
}

export default Legenda;
