
class EmpreendimentosServiceCrawler {
    baseUrlEmpreendimentos = `${process.env.MIX_API_MR_CRAWLER_URL}api/imoveis`;
    baseUrlEmpreendimentosExport = `${process.env.MIX_API_MR_CRAWLER_URL}api`;
    baseUrlEmpreendimentosMetadata = `${process.env.MIX_API_MR_CRAWLER_URL}api/imoveis-agregated`;

    extractValuesArray = (array) => {
        let newArray = [];

        array.forEach((element) => {
            newArray.push(element.value);
        });

        return newArray;
    };

    extractValuesArrayFromCidade = (array) => {
        let newArray = [];

        array.forEach((element) => {
            let elementParts = element.value.split("-");
            let cidade = elementParts[1].trim();
            newArray.push(cidade);
        });

        return newArray;
    };

    getEmpreendimento = async (id) => {
        try {
            const empreendimentoResponse = await axios.get(
                `${this.baseUrlEmpreendimentos}/${id}`
            );

            return empreendimentoResponse.data;
        } catch (error) {
            console.log("error");
        }
    };

    getEmpreendimentoOnCidade = async (state) => {
        let {
            tipo_empreendimento,
            area_maior_que,
            area_menor_que,
            quartos,
            vagas,
            tipo_negocio,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            cidade,
            estado,
            bairros,
            get_meta_data,
        } = state;

        tipo_negocio = tipo_negocio.value;
        tipo_empreendimento = this.extractValuesArray(tipo_empreendimento);
        cidade = this.extractValuesArrayFromCidade(cidade);

        let bairro = this.extractValuesArray(bairros);

        const requestBody = {
            tipo: tipo_empreendimento,
            cidade,
            estado,
            bairro,
            area_maior_que,
            area_menor_que,
            quartos,
            vagas,
            tipo_negocio,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            get_meta_data,
        };

        try {
            return await axios.post(
                this.baseUrlEmpreendimentos,
                requestBody
            );
        } catch (error) {
            console.log(error);
        }
    };

    getEmpreendimentosWithinRadius = async (
        latitude,
        longitude,
        radius,
        state
    ) => {
        let {
            tipo_empreendimento,
            area_maior_que,
            area_menor_que,
            quartos,
            vagas,
            tipo_negocio,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            cidade,
            estado,
            bairros,
            nome_empreendimento,
            get_meta_data,
        } = state;

        tipo_negocio = this.extractValuesArray(tipo_negocio);
        tipo_empreendimento = this.extractValuesArray(tipo_empreendimento);

        let bairro = this.extractValuesArray(bairros);
        cidade = this.extractValuesArrayFromCidade(cidade);

        const requestBody = {
            tipo: tipo_empreendimento,
            cidade,
            estado,
            bairro,
            area_maior_que,
            area_menor_que,
            quartos,
            vagas,
            tipo_negocio,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            get_meta_data,
            circle: {
                radius: radius,
                latitude: latitude,
                longitude: longitude,
            },
        };

        try {
            return await axios.post(
                this.baseUrlEmpreendimentos,
                requestBody
            );

        } catch (error) {
            console.log("Erro ao Buscar Empreendimentos");
            console.log(error);
        }
    };

    getEmpreendimentosWithinPolygon = async ( geoJsonPolygon, state) => {
        let {
            tipo_empreendimento,
            padrao,
            area_maior_que,
            area_menor_que,
            quartos,
            vagas,
            tipo_negocio,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            cidade,
            estado,
            bairros,
            get_meta_data,
        } = state;

        tipo_negocio = tipo_negocio.value;
        tipo_empreendimento = this.extractValuesArray(tipo_empreendimento);

        let bairro = this.extractValuesArray(bairros);
        cidade = this.extractValuesArrayFromCidade(cidade);

        const requestBody = {
            tipo: tipo_empreendimento,
            padrao,
            cidade,
            estado,
            bairro,
            area_maior_que,
            area_menor_que,
            tipo_negocio,
            quartos,
            vagas,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            get_meta_data,
            polygon: geoJsonPolygon,
        };

        try {
            return await axios.post(this.baseUrlEmpreendimentos, requestBody);
        } catch (error) {
            console.log("Erro ao Buscar Empreendimento");
            console.log("error");
        }
    };

    getMetadataWithinPolygon = async ( geoJsonPolygon, state) => {
        let {
            tipo_empreendimento,
            padrao,
            area_maior_que,
            area_menor_que,
            quartos,
            vagas,
            tipo_negocio,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            cidade,
            estado,
            bairros,
            get_meta_data,
        } = state;

        tipo_negocio = tipo_negocio.value;
        tipo_empreendimento = this.extractValuesArray(tipo_empreendimento);

        let bairro = this.extractValuesArray(bairros);
        cidade = this.extractValuesArrayFromCidade(cidade);

        const requestBody = {
            tipo: tipo_empreendimento,
            padrao,
            cidade,
            estado,
            bairro,
            area_maior_que,
            area_menor_que,
            tipo_negocio,
            quartos,
            vagas,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            polygon: geoJsonPolygon,
        };

        try {
            return await axios.post(this.baseUrlEmpreendimentosMetadata, requestBody);
        } catch (error) {
            console.log("Erro ao Buscar Empreendimento");
            console.log("error");
        }
    };


    getMetadataWithinCidade = async (state) => {
        let {
            tipo_empreendimento,
            area_maior_que,
            area_menor_que,
            quartos,
            vagas,
            tipo_negocio,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            cidade,
            estado,
            bairros,
            get_meta_data,
        } = state;

        tipo_negocio = tipo_negocio.value;
        tipo_empreendimento = this.extractValuesArray(tipo_empreendimento);
        cidade = this.extractValuesArrayFromCidade(cidade);

        let bairro = this.extractValuesArray(bairros);

        const requestBody = {
            tipo: tipo_empreendimento,
            cidade,
            estado,
            bairro,
            area_maior_que,
            area_menor_que,
            quartos,
            vagas,
            tipo_negocio,
            valor_menor_que,
            valor_maior_que,
            valor_metro_maior_que,
            valor_metro_menor_que,
            get_meta_data,
        };

        try {
            return await axios.post(this.baseUrlEmpreendimentosMetadata , requestBody );
        } catch (error) {
            console.log(error);
        }
    };

    exportExcel = async (queryID) => {
        let user = document.querySelector('#user_id').value;

        let requestBody = {
            user_id: user,
            query_id: queryID,
        }

        try {
            return await axios.post(`${this.baseUrlEmpreendimentosExport}/imoveis-export`, requestBody);
        } catch (error) {
            console.log(error);
        }
    }
}

export default new EmpreendimentosServiceCrawler();
