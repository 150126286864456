import {Dialog} from "primereact/dialog";
import {Component} from "react";
import InnerLoading from "../SocioDemografia/InnerLoading";
import {ProgressBar} from "react-bootstrap";
import {formatNumberAsPercentage} from "../../util/formatacoes";


export default class ModalResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busca: {},
            loading: false,
            results_count: null,
            meta : {

            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            busca: props.busca
        }
    }

    componentDidMount = async ()=>{

    }

    componentDidUpdate = async (prevProps)=> {
        if (!prevProps.visible && this.props.visible) {
            await this.getResults();
        }
    }

    getResults = async ()=>{
        try{
            this.setState({loading: true});

            let response = await axios.get( route('web.coleta_polos_geradores.show_results', this.state.busca.id) );

            this.setState({
                results_count: response.data.resultsSum,
                meta: response.data.search,
            });

        }catch (e) {
            console.log(e);
        }finally {
            this.setState({loading: false});
        }
    }

    getProgress = ()=>{
        return (this.state.meta?.numero_processos_concluidos / this.state.meta?.numero_processos ) * 100;
    }

    render() {
        const progress = this.getProgress();
        const progressFormatted = formatNumberAsPercentage(progress);
        return (
            <Dialog header="Resultados coletados até o momento" visible={this.props.visible}
                    style={{width: '50vw'}} modal={true} onHide={this.props.onHide}>
                {this.state.loading && <InnerLoading />}
                {!this.state.loading && <div>
                    <strong>Número de Polos Geradores Coletados:</strong> {Number(this.state.results_count).toLocaleString()} <br />
                    <strong>Número Tarefas:</strong> {Number(this.state.meta?.numero_processos).toLocaleString()} <br />
                    <strong>Número Tarefas Concluídas:</strong> {Number(this.state.meta?.numero_processos_concluidos).toLocaleString()} <br />

                    <div style={{marginTop: '30px'}}>
                        <ProgressBar striped variant="success" now={progress} label={`${progressFormatted}%`} />
                    </div>
                </div>}
            </Dialog>
        );
    }
}
