const padroes = [
    { 'label': 'Alto', value: 'Alto' },
    { 'label': 'Alto +', value: 'Alto +' },
    { 'label': 'Condomínio de Casas/Sobrados', value: 'Condomínio de Casas/Sobrados' },
    { 'label': 'Condomínio de Chácaras', value: 'Condomínio de Chácaras' },
    { 'label': 'Econômico', value: 'Econômico' },
    { 'label': 'Compacto', value: 'Compacto' },
    { 'label': 'Loteamento Aberto', value: 'Loteamento Aberto' },
    { 'label': 'Loteamento Fechado', value: 'Loteamento Fechado' },
    { 'label': 'Luxo', value: 'Luxo' }, { 'label': 'Médio', value: 'Médio' },
    { 'label': 'Standard', value: 'Standard' },
    { 'label': 'Super Econômico', value: 'Super Econômico' },
    { 'label': 'Super Luxo', value: 'Super Luxo' },
]

module.exports = padroes;