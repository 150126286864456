import React, {Component} from 'react';
import AsyncSelect from "react-select/async";
import ReactDOM from "react-dom";
import {MultiSelect} from "primereact/multiselect";
import Table from "../MapaRevendaLocacao/Table";
import InnerLoading from "./InnerLoading";
import EmpreendimentoServiceCrawler from "../../services/empreendimentosServicesCrawler";
import {SelectButton} from "primereact/selectbutton";
import {InputNumber} from "primereact/inputnumber";
import { Accordion, AccordionTab } from 'primereact/accordion';
import tipos from "../MapaRevendaLocacao/tipos";

class RelatorioRevendaLocacao extends Component {

    initialState = {
        cidade: '',
        tipo_negocio: '',
        tipo: [],
        metadata: [],
        loading: false,
        count_buildings: 0,
        errors: {},
        query_id: '',
        show_mensagem: false,
        mensagem: '',
        bairros_options: [],
        bairros: [],
        quartos: [],
        vagas: [],
        area_maior_que: null,
        area_menor_que: null,
        valor_menor_que: null,
        valor_maior_que: null,
        valor_metro_maior_que: null,
        valor_metro_menor_que: null,
        activeIndex: 0
    }

    constructor() {
        super();
        this.state = this.initialState

        this.tipos = tipos.select_options;
    }

    cleanState = async () => {
        await this.setState({
            ...this.initialState
        });
    }

    quartos = [
        {label: '1Q', value: 1 },
        {label: '2Q', value: 2 },
        {label: '3Q', value: 3 },
        {label: '4Q', value: 4 },
    ];

    vagas = [
        {label: '1V', value: 1 },
        {label: '2V', value: 2 },
        {label: '3V', value: 3 },
        {label: '4V', value: 4 },

    ]

    searchCidade = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await axios.get('/cidades/json-options-with-state-on-values/' + inputQuery);
            return response.data;
        }
    }

    fetchBairros = async (cidade) => {
        try {
            let bairros = await axios.get("/bairros-crawler/json/" + cidade);

            this.setState({
                ...this.state,
                bairros_options: bairros.data
            })
        } catch (error) {
        }
    };

    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }

    submit = async () => {

        let baseUrl = process.env.MIX_API_MR_CRAWLER_URL;

        const formIsValid = await this.validateForm();

        if( !formIsValid ){
            return;
        }

        try {
            await this.setState({
                loading: true,
                metadata: [],
                errors: {}
            })

            const cidade = this.state.cidade?.value?.split('-')[0];
            const estado = this.state.cidade?.value?.split('-')[1];

            let response = await axios.post(`${baseUrl}api/imoveis-agregated`, {
                cidade,
                estado,
                tipo: this.state.tipo,
                tipo_negocio: this.state.tipo_negocio,
                area_menor_que: this.state.area_menor_que,
                area_maior_que: this.state.area_maior_que,
                valor_menor_que: this.state.valor_menor_que,
                valor_maior_que: this.state.valor_maior_que,
                valor_metro_menor_que: this.state.valor_metro_menor_que,
                valor_metro_maior_que: this.state.valor_metro_maior_que,
                quartos: this.state.quartos,
                vagas: this.state.vagas,
                bairros: this.state.bairros
            })

            this.setState({
                ...this.state,
                metadata: response.data.data,
                count_buildings: response.data.data.reduce((accumulator, current )=>{
                    return accumulator + current.total
                }, 0 )
                ,
                query_id : response.headers['x-query-id'],
                activeIndex: 1
            })

        } catch (e) {
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    requestExcel = async ( queryID ) => {
        try {
            let response = await EmpreendimentoServiceCrawler.exportExcel( this.state.query_id );

            this.setState({
                ...this.state,
                show_mensagem: true,
                mensagem: response.data.message
            })

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

        }catch (error) {
            console.log("Erro ao Buscar Empreendimento");
            console.log(error);
        }
    }


    render() {
        return (
            <React.Fragment>
                {this.state.show_mensagem && <>
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                <strong>Sucesso!</strong> {this.state.mensagem}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </>}
                <Accordion activeIndex={this.state.activeIndex}
                           onTabChange={(e) => this.setState({activeIndex: e.index})}>
                    <AccordionTab header={"Formulário"}>
                        <div>
                            {this.state.loading && <InnerLoading/>}
                            <div className="form-group row">


                                <div className="col-12 col-md-6 col-sm-6">
                                    <label htmlFor="">Cidade</label>
                                    <AsyncSelect
                                        cacheOptions
                                        isClearable
                                        noOptionsMessage={() => 'Nenhum Resultado'}
                                        placeholder="Digite para buscar..."
                                        value={this.state.cidade}
                                        loadOptions={this.searchCidade}
                                        onChange={async (e) => {
                                            await this.setState({
                                                ...this.state,
                                                cidade: e
                                            });

                                            await this.fetchBairros(e.value.split('-')[0]);
                                        }}
                                    />
                                    {this.state.errors['cidade'] && <>
                            <span className="invalid-feedback d-block" role="alert">
                                    <strong>{this.state.errors['cidade']}</strong>
                            </span>
                                    </>}
                                </div>

                                <div className="col-12 col-md-6 col-sm-6">
                                    <label htmlFor="">Bairro</label>
                                    <MultiSelect
                                        maxSelectedLabels={3}
                                        filter={true}
                                        style={{width: '100%'}}
                                        display="chip"
                                        optionLabel="label"
                                        value={this.state.bairros}
                                        options={this.state.bairros_options}
                                        appendTo={document.body}
                                        onChange={(e) => this.setState({bairros: e.value})}
                                    />

                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-12 col-md-6 col-sm-6">
                                    <label htmlFor="tipo">Tipo</label><br/>
                                    <MultiSelect
                                        maxSelectedLabels={3}
                                        filter={true}
                                        style={{width: '100%'}}
                                        display="chip"
                                        optionLabel="label"
                                        value={this.state.tipo}
                                        options={this.tipos}
                                        appendTo={document.body}
                                        onChange={(e) => this.setState({tipo: e.value})}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-sm-6">
                                    <label htmlFor="">Tipo de Negócio</label>
                                    <select value={this.state.tipo_negocio} className="form-control"
                                            onChange={(e) => {
                                                this.setState({
                                                    ...this.state,
                                                    tipo_negocio: e.target.value
                                                })
                                            }}
                                            name="tipo_negocio" id="tipo_negocio">
                                        <option value="">Selecione</option>
                                        <option value="VENDA">Revenda</option>
                                        <option value="ALUGUEL">Locação</option>
                                    </select>
                                    {this.state.errors['tipo_negocio'] && <>
                            <span className="invalid-feedback d-block" role="alert">
                                    <strong>{this.state.errors['tipo_negocio']}</strong>
                            </span>
                                    </>}
                                </div>

                            </div>

                            <div className="form-group row">
                                <div className="col-12 col-md-6 col-sm-6">
                                    <label htmlFor="tipo">Quartos</label><br/>
                                    <SelectButton
                                        optionLabel="label"
                                        optionValue="value"
                                        multiple={true}
                                        value={this.state.quartos}
                                        options={this.quartos}
                                        itemTemplate={(option) => {
                                            return <React.Fragment><i className="fa fa-bed"></i> &nbsp; {option.label}
                                            </React.Fragment>
                                        }}
                                        onChange={(e) => this.setState({quartos: e.value})}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-sm-6">
                                    <label htmlFor="tipo">Vagas</label><br/>
                                    <SelectButton
                                        optionLabel="label"
                                        optionValue="value"
                                        multiple={true}
                                        value={this.state.vagas}
                                        options={this.vagas}
                                        style={{width: '100%'}}
                                        itemTemplate={(option) => {
                                            return <React.Fragment><i className="fa fa-car"></i> &nbsp; {option.label}
                                            </React.Fragment>
                                        }}
                                        onChange={(e) => this.setState({vagas: e.value})}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-12 col-md-6 col-sm-6">
                                    <label htmlFor="tipo">Área Mínima</label><br/>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        mode="decimal" minFractionDigits={2} maxFracionDigits={2}
                                        value={this.state.area_maior_que}
                                        locale="pt-BR"
                                        onValueChange={(e) => this.setState({area_maior_que: e.value})}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-sm-6">
                                    <label htmlFor="">Área Máxima</label><br/>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        mode="decimal" minFractionDigits={2} maxFracionDigits={2}
                                        locale="pt-BR"
                                        value={this.state.area_menor_que}
                                        onValueChange={(e) => this.setState({area_menor_que: e.value})}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-12 col-md-6 col-sm-6">
                                    <label htmlFor="tipo">Valor Mínimo</label><br/>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        mode="decimal" minFractionDigits={2} maxFracionDigits={2}
                                        value={this.state.valor_maior_que}
                                        locale="pt-BR"
                                        onValueChange={(e) => this.setState({valor_maior_que: e.value})}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-sm-6">
                                    <label htmlFor="">Valor Máximo</label><br/>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        mode="decimal" minFractionDigits={2} maxFracionDigits={2}
                                        locale="pt-BR"
                                        value={this.state.valor_menor_que}
                                        onValueChange={(e) => this.setState({valor_menor_que: e.value})}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-12 col-md-6 col-sm-6">
                                    <label htmlFor="tipo">Valor Metro Mínimo</label><br/>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        mode="decimal" minFractionDigits={2} maxFracionDigits={2}
                                        value={this.state.valor_metro_maior_que}
                                        locale="pt-BR"
                                        onValueChange={(e) => this.setState({valor_metro_maior_que: e.value})}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-sm-6">
                                    <label htmlFor="">Valor Metro Máximo</label><br/>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        mode="decimal" minFractionDigits={2} maxFracionDigits={2}
                                        locale="pt-BR"
                                        value={this.state.valor_metro_menor_que}
                                        onValueChange={(e) => this.setState({valor_metro_menor_que: e.value})}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-12 col-md-6 col-sm-6">
                                    <button disabled={this.state.loading} className="btn btn-sm btn-success btn-block"
                                            onClick={this.submit}>
                                        Gerar Relatório
                                    </button>
                                </div>

                                <div className="col-12 col-md-6 col-sm-6">
                                    <button disabled={this.state.loading} className="btn btn-sm btn-danger btn-block"
                                            onClick={this.cleanState}>
                                        Limpar
                                    </button>
                                </div>
                            </div>
                        </div>

                    </AccordionTab>
                    <AccordionTab header={"Resultado"}>
                        <div>

                            {
                                this.state.metadata.length > 0 && !this.state.loading && <>
                                    <React.Fragment>
                                        <Table
                                            title={this.state.tipo_negocio}
                                            metadata={this.state.metadata}
                                            count_buildings={this.state.count_buildings}
                                            color={'#1e78b4'}
                                        />
                                        <hr/>

                                        <button className="btn btn-sm btn-success float-right" onClick={this.requestExcel}>
                                            <i className="fa fa-file-excel-o"></i> &nbsp;
                                            Exportar Excel dos Imóveis
                                        </button>

                                    </React.Fragment>

                                </>
                            }
                        </div>
                    </AccordionTab>
                </Accordion>
                <hr/>


            </React.Fragment>
        )
            ;
    }

    validateForm = async () => {
        let errors = {};
        //cidade e estado e tipo de negocio são requeridos
        if (!this.state.cidade || this.state.cidade === '') {
            errors['cidade'] = 'O campo cidade é requerido'
        }

        if (!this.state.tipo_negocio || this.state.tipo_negocio === '') {
            errors['tipo_negocio'] = 'O campo Tipo Negócio é requerido'
        }

        if (Object.keys(errors).length > 0) {
            await this.setState({
                ...this.state,
                errors: errors
            })

            return false;
        }

        return true;
    }
}

if (document.getElementById('relatorioRevendaLocacao')) {
    ReactDOM.render(<RelatorioRevendaLocacao/>, document.getElementById('relatorioRevendaLocacao'));
}
