import React from 'react';
import {Dialog} from "primereact/dialog";
import {Dropdown} from 'primereact/dropdown';
import {InputSwitch} from 'primereact/inputswitch';


export default class ModalEdicaoMassa extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sub_categorias: [],
            sub_categorias_filtradas: [],
        }
    }

    async componentDidMount() {
    }

    render() {

        return (<>
            <Dialog
                header="Editar Em Massa"
                visible={this.props.show}
                style={{width: '50vw', marginTop: '50px'}}
                onHide={this.props.handleClose}
                blockScroll={true}
            >

                <div className="card">
                    <div className="card-body">
                        <div className="form-group row">
                            <div className="col-6">
                                <label>Categoria</label>
                                <Dropdown
                                    optionLabel="label"
                                    name="main_category"
                                    value={this.props.opcoes.main_category}
                                    blockScroll={true}
                                    appendTo={document.body}
                                    options={this.props.categorias}
                                    onChange={this.props.handleChange}
                                    placeholder="Selecione Uma Categoria"
                                    style={{width: '100%'}}
                                    filter={true}
                                />
                                {this.props.errors?.main_category && <div className="feedback text-danger d-block">{this.props.errors.main_category}</div>}
                            </div>

                            <div className="col-6">
                                <label>Sub-Categoria</label>
                                <Dropdown
                                    name="sub_category"
                                    optionLabel="label"
                                    value={this.props.opcoes.sub_category}
                                    blockScroll={true}
                                    appendTo={document.body}
                                    options={this.props.sub_categorias}
                                    onChange={this.props.handleChange}
                                    placeholder="Selecione Uma Categoria"
                                    filterMatchMode={'startsWith'}
                                    style={{width: '100%'}}
                                    filter={true}
                                    lazy={true}
                                />

                                {this.props.errors?.sub_category && <div className="feedback text-danger d-block">{this.props.errors.sub_category}</div>}

                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-12">
                                <button className="btn btn-success btn-sm btn-block"
                                        onClick={this.props.handleSave}>Salvar
                                </button>
                            </div>
                        </div>


                    </div>
                </div>
            </Dialog>
        </>);
    }
}
const styles = {
    legend: {
        padding: '5px',
        margin: '4px',
    }
}
