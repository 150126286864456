import React, { Component } from 'react';
import EmpreendimentoServiceCrawler from "../../services/empreendimentosServicesCrawler";

const labels = {
    "Venda": "Revenda",
    "Aluguel": "Locação"
}

class InfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        return (
            <div className="card area-info ov-hidden"
                style={{ borderStyle: 'solid', borderColor: this.props.color, borderWidth: '2px', fontSize: 16 }}>
                <div className="card-header"
                    style={{ color: "#517E37", fontWeight: 'bold', textTransform: 'uppercase', fontSize: 18 }}>
                    {this.props.type.split(' ')[0]} {this.props.selectedArea.name} <br />


                </div>
                <div className="card-body" style={{ backgroundColor: '#ffffff', fontSize: '15' }}>
                    Tipo da Busca: {this.props.type}<br />
                    {(this.props.selectedArea.radius !== undefined) && <>
                        Raio do Círculo: {Number(this.props.selectedArea.radius).toFixed(2)}
                    </>}

                    <hr />
                    <strong>Total de Imóveis:</strong> {this.props.count_buildings.toLocaleString("pt-BR")} <br />
                    <strong>Tipo de Negócio:</strong> {labels[this.props.selectedArea.tipo_negocio]} <br />
                    <div>
                        <form action={this.props.pdf} method="GET">
                        <div className="row">
                            <div className="col-sm-6 col-6 text-left">
                                <a data-id={this.props.query_id} onClick={ (event )=>{
                                    event.preventDefault();
                                    this.props.requestExcel(this.props.query_id);
                                }}
                                    className="btn btn-sm btn-block btn-round">
                                    <i className={"fa fa-file-excel"}></i> Excel
                                </a>
                            </div>
                        </div>
                        </form>
                        <div className="row">
                            <div className="col-sm-6 col-6">
                                {window.authenticatedUserCanQueryPolos == true && this.props.polos && this.props.polos.length > 0 && <>
                                    <button className="btn btn-behance btn-sm btn-block" onClick={() => {

                                        let agrupamentos = [];

                                        this.props.polos.map((polo) => {
                                            let found = agrupamentos.find((agrupamento) => {
                                                return agrupamento.category === polo.main_category || polo.categories[0] === agrupamento.category;
                                            });
                                            if (found) {
                                                found.count++;
                                            } else {
                                                let category = polo.main_category || polo.categories[0];
                                                agrupamentos.push({ category: category, count: 1 });
                                            }
                                        });

                                        this.props.setPolosGeradoresCurrentShowing(this.props.polos, agrupamentos, this.props.polos_cache_key);

                                    }}>
                                        <i className="fas fa-map-marker-alt"></i> Polos Geradores
                                    </button>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InfoCard;
