import React from 'react';
import {Dropdown} from 'primereact/dropdown';


class FiltroZoneamento extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            propriedade: '', valores_propriedades_zoneamento: [], valor_propriedade: ''
        }
    }


    handleChangeProperty = (e) => {

        let rawProertyValues = this.props.valores[e.target.value] ?? [];

        let valores_propriedades_zoneamento = rawProertyValues.map((p) => {
            return {
                label: p, value: p
            }
        })

        this.setState({
            propriedade: e.target.value, valores_propriedades_zoneamento
        });
    }

    handleChangePropertyValue = (e) => {
        this.setState({
            ...this.state, valor_propriedade: e.target.value
        })
    }

    render() {
        return (<div className="card" style={{marginTop:'25px'}}>
                <div className="card-body">
                    <div className="form-group row">
                        <label htmlFor="filtrar_por">Filtrar Zoneamento por:</label>
                        <Dropdown
                            style={{width: '100%'}}
                            value={this.state.propriedade}
                            options={this.props.opcoes}
                            onChange={this.handleChangeProperty}
                            placeholder="Selecione uma Propriedade"/>
                    </div>

                    {this.state.propriedade !== '' && <React.Fragment>
                        <div className="form-group row">
                            <label htmlFor="filtrar_por">Valor:</label>
                            <Dropdown
                                style={{width: '100%'}}
                                value={this.state.valor_propriedade}
                                options={this.state.valores_propriedades_zoneamento}
                                onChange={this.handleChangePropertyValue}
                                placeholder="Selecione um valor"/>
                        </div>
                    </React.Fragment>}

                    <div className="row form-group">
                        <button disabled={this.state.valor_propriedade === ''}
                                onClick={( e )=>{
                                    this.props.handleSubmit({
                                        property: this.state.propriedade,
                                        value: this.state.valor_propriedade,
                                    });
                                }}
                                className="btn btn-primary btn-sm btn-block">
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>

        );
    }
}

export default FiltroZoneamento;
