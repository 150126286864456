import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Loader from "../Loader";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Accordion, Card, Button } from "react-bootstrap";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

export default class Index extends Component {

    constructor(props) {
        super();

        this.state = {
            errors: [],
            perfilOptions: [],
            perfil: [],
            empresaOptions: [],
            empresa: [],
            statusOptions: [{ label: 'Ativo', value: 1 }, { label: 'Inativo', value: 0 }],
            status: [],
            assunto: "",
            mensagem: "",
            fotos: [],
            documentos: [],
        }
    }

    getRequestHeader = () => {
        return {
            headers: {
                Authorization: `Bearer ${this.state.user.jwt_token}`
            }
        }
    }

    setLoading = async (loading) => {
        await this.setState({
            loading: loading
        });
    }

    loadAuthUser = async () => {
        try {

            let response = await axios.get(route('user.auth'));

            if (response.status === 200) {
                this.setState({
                    ...this.state, user: response.data
                });
            }

        } catch (e) {
            console.log(e);
        }
    }

    loadPerfil = async () => {
        let perfil = await axios.get('role-json');

        this.setState({ ...this.state, perfilOptions: perfil.data.roles })
    }

    loadEmpresa = async () => {
        let empresa = await axios.get('empresa-json');

        this.setState({ ...this.state, empresaOptions: empresa.data.companies })
    }

    handleRemoveFoto = (index) => {
        const updatedFotos = [...this.state.fotos];
        updatedFotos.splice(index, 1);
        this.setState({ fotos: updatedFotos });
    };

    handleRemoveDocumento = (index) => {
        const updatedDocumentos = [...this.state.documentos];
        updatedDocumentos.splice(index, 1);
        this.setState({ documentos: updatedDocumentos });
    };


    componentDidMount = async () => {
        try {
            await Promise.all([this.loadPerfil(), this.loadEmpresa()]);



        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Validação do formulário
     */
    validate = async () => {

        let errors = {};


        if (this.state.assunto === "") {
            errors['assunto'] = ['O campo assunto é obrigatório'];
        }
        if (this.state.mensagem === "") {
            errors['mensagem'] = ['O campo mensagem é obrigatório'];
        }

        if (Object.keys(errors).length > 0) {
            await this.setState({
                ...this.state,
                errors: errors
            });

            throw new Error('Validation Error');
        }
    }


    submit = async () => {
        try {

            await this.validate();

            this.setState({ ...this.state, loading: true })

            let payload = this.getPayloadForRequest();

            const response = await axios.post('melhorias', payload);
            console.log(response);
            if (response.status == 200) {
                Swal.fire({
                    title: response.data.msg,
                    icon: "sucess",
                    confirmButtonColor: "#FB404B",
                    confirmButtonText: "Fechar!",
                }).then(async (result) => {
                    if (result.value) {
                        await this.clearState();
                    }
                });
            }


        } catch (e) {
            if (e.response.status === 500) {
                Swal.fire({
                    title: e.response.data.msg,
                    icon: "warning",
                    confirmButtonColor: "#FB404B",
                    confirmButtonText: "Fechar!",
                });
            }
            if (e.response && e.response.status === 422) {

                let errors = e.response.data.errors;

                this.setState({
                    ...this.state, errors: errors
                });
            }
        } finally {
            this.setLoading(false);
            this.setState({
                ...this.state,
                submited: true,
                loading: false
            });
        }
    }

    clearState = async () => {
        return this.setState({
            ...this.state,
            errors: [],
            perfil: [],
            empresa: [],
            status: [],
            assunto: "",
            mensagem: "",
            fotos: [],
            documentos: [],
        })
    }

    getPayloadForRequest = () => {
        const formData = new FormData();

        formData.append('perfil', this.state.perfil?.map((perfil) => perfil.label));
        formData.append('empresa', this.state.empresa?.map((empresa) => empresa.value));
        formData.append('status', this.state.status?.map((status) => status.value));
        formData.append('assunto', this.state.assunto);
        formData.append('mensagem', this.state.mensagem);

        if (this.state.fotos && this.state.fotos.length > 0) {
            this.state.fotos.forEach(foto => {
                formData.append('fotos[]', foto);
            });
        }

        if (this.state.documentos && this.state.documentos.length > 0) {
            this.state.documentos.forEach(documento => {
                formData.append('documentos[]', documento);
            });
        }

        return formData;
    };


    render() {

        return (<div className="card">
            {this.state.loading && <Loader />}
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="form-group row">
                                <div className="col-4">
                                    <label htmlFor="perfil">Perfil</label>
                                    <Select
                                        isMulti
                                        name="perfil"
                                        closeMenuOnSelect={false}
                                        options={this.state.perfilOptions}
                                        value={this.state.perfil}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Selecione os Perfis"
                                        onChange={(value) => { this.setState({ ...this.state, perfil: value || [] }) }}>
                                    </Select>

                                    {this.state.errors['perfil'] &&
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {this.state.errors['perfil'][0]}
                                        </div>
                                    }
                                </div>
                                <div className="col-4">
                                    <label htmlFor="status">Status</label>
                                    <Select
                                        name="status"
                                        isMulti
                                        options={this.state.statusOptions}
                                        value={this.state.status}
                                        closeMenuOnSelect={false}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Selecione os Status"
                                        onChange={(value) => { this.setState({ ...this.state, status: value || [] }) }}>
                                    </Select>

                                    {this.state.errors['status'] &&
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {this.state.errors['status'][0]}
                                        </div>
                                    }
                                </div>
                                <div className="col-4">
                                    <label htmlFor="empresa">Empresa</label>
                                    <Select
                                        isMulti
                                        name="empresa"
                                        closeMenuOnSelect={false}
                                        options={this.state.empresaOptions}
                                        value={this.state.empresa}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Selecione as Empresas"
                                        onChange={(value) => { this.setState({ ...this.state, empresa: value || [] }) }}>
                                    </Select>

                                    {this.state.errors['empresa'] &&
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {this.state.errors['empresa'][0]}
                                        </div>
                                    }
                                </div>
                                <div className='col-12'>
                                    <label htmlFor='assunto'>Assunto</label>
                                    <input
                                        className="form-control"
                                        value={this.state.assunto}
                                        onChange={(event) => this.setState({ assunto: event.target.value })}
                                        type="text"
                                    />
                                    {this.state.errors['assunto'] &&
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {this.state.errors['assunto'][0]}
                                        </div>
                                    }
                                </div>

                                <div className='col-12'>
                                    <label>Mensagem</label>
                                    <p style={{ color: '#721c24', fontSize: '12px', marginLeft: '0.8%' }}>Escolha onde inserir o nome do cliente/usuário usando a váriavel {"{{$nome}}"}</p>
                                    <SimpleMDE
                                        value={this.state.mensagem}
                                        onChange={(value) => this.setState({ ...this.state, mensagem: value })}
                                    />
                                    {this.state.errors['mensagem'] &&
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {this.state.errors['mensagem'][0]}
                                        </div>
                                    }
                                </div>
                                <div className='col-12'>
                                    <label>Fotos</label>
                                    <br></br>
                                    <input type="file"
                                        // multiple
                                        onChange={(event) => {
                                            const files = Array.from(event.target.files);
                                            this.setState(prevState => ({
                                                fotos: files
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="fotos-container">
                                    {this.state.fotos.map((foto, index) => (
                                        <>
                                            <img
                                                key={index}
                                                src={URL.createObjectURL(foto)}
                                                alt={`foto-${index}`}
                                                style={{ width: '150px', height: '150px', margin: '10px' }}
                                            />
                                            <button
                                                onClick={() => this.handleRemoveFoto(index)}
                                                className='btn btn-danger btn-sm'
                                                style={{
                                                    position: 'relative',
                                                    top: '-55px',
                                                    right: '160px',
                                                }}
                                            >
                                                X
                                            </button>
                                        </>
                                    ))}
                                </div>
                                <div className='col-12'>
                                    <label>Documentos</label>
                                    <br></br>
                                    <input type="file"
                                        multiple
                                        onChange={(event) => {
                                            const files = Array.from(event.target.files);
                                            this.setState(prevState => ({
                                                documentos: [...prevState.documentos, ...files]
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="documento-container">
                                    <ul>
                                        {this.state.documentos.map((documento, index) => (
                                            <>
                                                <li key={index}>{documento.name}
                                                    <button
                                                        onClick={() => this.handleRemoveDocumento(index)}
                                                        className='btn btn-danger btn-sm'
                                                    >
                                                        X
                                                    </button>
                                                </li>
                                            </>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='col-12'>
                                <button className='btn btn-warning col-12'
                                    onClick={async () => { await this.submit(); }}>Enviar</button>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>);
    }
}

if (document.getElementById('divulgacao_melhorias')) {
    ReactDOM.render(<Index />, document.getElementById('divulgacao_melhorias'));
}
