import React from 'react';
import ReactDOM from "react-dom";
import {MultiSelect} from 'primereact/multiselect';
import Loader from "../Loader";
import ModalEdicaoPolo from "./ModalEdicaoPolo";
import {toast, ToastContainer} from 'react-toastify';
import ModalEdicaoMassa from "./ModalEdicaoMassa";
import {OverlayPanel} from "primereact/overlaypanel";
import ModalRemocaoMassa from "./ModalRemocaoMassa";
import Select from "react-select";
import circleToPolygon from "circle-to-polygon";
import ModalEdicaoMassaPorte from "./ModalEdicaoMassaPorte";
import ModalEdicaoMassaClasse from "./ModalEdicaoMassaClasse";
import { Dropdown } from 'primereact/dropdown';



class Listagem extends React.Component {

    opTipoSelecao;
    op;

    MAX_IDS_SELECIONADOS_PERMITIDOS = 4000;

    constructor() {
        super();
        this.state = {
            tipo_selecao: 'Página',
            tipo_selecao_options: [
                {
                    label: 'Página',
                    value: 'Página'
                },
                {
                    label: 'Tabela',
                    value: 'Tabela'
                },
            ],
            polos: [],
            loading: false,
            pagination: {
                total: 0,
                per_page: 20,
                current_page: 1,
                last_page: 1,
                from: 1,
                to: 0
            },
            filters: {
                cities: [],
                states: [],
                polygons: [],
            },
            categorias: [],
            estados: [
                {value: 'AC', label: 'Acre'},
                {value: 'AL', label: 'Alagoas'},
                {value: 'AP', label: 'Amapá'},
                {value: 'AM', label: 'Amazonas'},
                {value: 'BA', label: 'Bahia'},
                {value: 'CE', label: 'Ceará'},
                {value: 'DF', label: 'Distrito Federal'},
                {value: 'ES', label: 'Espírito Santo'},
                {value: 'GO', label: 'Goiás'},
                {value: 'MA', label: 'Maranhão'},
                {value: 'MT', label: 'Mato Grosso'},
                {value: 'MS', label: 'Mato Grosso do Sul'},
                {value: 'MG', label: 'Minas Gerais'},
                {value: 'PA', label: 'Pará'},
                {value: 'PB', label: 'Paraíba'},
                {value: 'PR', label: 'Paraná'},
                {value: 'PE', label: 'Pernambuco'},
                {value: 'PI', label: 'Piauí'},
                {value: 'RJ', label: 'Rio de Janeiro'},
                {value: 'RN', label: 'Rio Grande do Norte'},
                {value: 'RS', label: 'Rio Grande do Sul'},
                {value: 'RO', label: 'Rondônia'},
                {value: 'RR', label: 'Roraima'},
                {value: 'SC', label: 'Santa Catarina'},
                {value: 'SP', label: 'São Paulo'},
                {value: 'SE', label: 'Sergipe'},
                {value: 'TO', label: 'Tocantins'},
            ],
            currentEditing: null,
            showEditModal: false,
            errors: {},
            ids_selecionados: [],
            show_modal_acoes_em_grupo: false,
            show_modal_acoes_em_grupo_porte: false,
            show_modal_acoes_em_grupo_classe: false,
            show_modal_remocao_em_grupo: false,
            acoes_em_grupo_opcoes:{
                main_category: '',
                sub_category: '',
            },
            acoes_em_grupo_opcoes_porte:{
                porte: ''
            },
            acoes_em_grupo_opcoes_classe:{
                classe: ''
            },
            sub_categorias: [],
            id_delete: null,
            cidadesOptions: [],
            bairrosOptions: [],
            projetos: [],
            opcoes_porte: [
                { value: 'Pequeno', label: 'Pequeno' },
                { value: 'Médio', label: 'Médio' },
                { value: 'Grande', label: 'Grande' },
                { value: 'Mega', label: 'Mega' },
            ],
            opcoes_classe: [
                { "label": "Alimentação Fora do Domicílio", "value": "Alimentação Fora do Domicílio" },
                { "label": "Alimentação no Domicílio", "value": "Alimentação no Domicílio" },
                { "label": "Artigos de Limpeza", "value": "Artigos de Limpeza" },
                { "label": "Bebidas", "value": "Bebidas" },
                { "label": "Eletrodomésticos e Equipamentos", "value": "Eletrodomésticos e Equipamentos" },
                { "label": "Gastos com Medicamentos", "value": "Gastos com Medicamentos" },
                { "label": "Higiene e Cuidados Pessoais", "value": "Higiene e Cuidados Pessoais" },
                { "label": "Calçados", "value": "Calçados" },
                { "label": "Material de Construção", "value": "Material de Construção" },
                { "label": "Despesas com Recreação e Cultura", "value": "Despesas com Recreação e Cultura" },
                { "label": "Despesas com Viagens", "value": "Despesas com Viagens" },
                { "label": "Livros e Material Escolar", "value": "Livros e Material Escolar" },
                { "label": "Matrículas e Mensalidades", "value": "Matrículas e Mensalidades" },
                { "label": "Mobiliários e Artigos do Lar", "value": "Mobiliários e Artigos do Lar" },
                { "label": "Outras Despesas com Saúde", "value": "Outras Despesas com Saúde" },
                { "label": "Outras Despesas com Vestuários", "value": "Outras Despesas com Vestuários" },
                { "label": "Vestuários Confeccionados", "value": "Vestuários Confeccionados" }
            ]
        }
    }

    /**
     * Busca os projetos salvos do usuário
     * @returns {Promise<void>}
     */
    loadProjects = async () => {
        try {
            let projetos = [{ value: null, label: 'Selecione' }];
            const response = await axios.get(route('getMapaProjetos'));

            projetos = [...projetos, ...response.data.projects];

            await this.setState({
                ...this.state,
                projetos: projetos
            });
        } catch (error) {
            console.error("Erro ao carregar os nomes dos projetos", error);
        }
    }

    handleChangeAcoesEmGrupo = async (e) => {
        await this.setState({
            acoes_em_grupo_opcoes: {
                ...this.state.acoes_em_grupo_opcoes,
                [e.target.name]: e.target.value
            }
        });
    }

    handleChangeAcoesEmGrupoPorte = async (e) => {
        await this.setState({
            acoes_em_grupo_opcoes_porte: {
                ...this.state.acoes_em_grupo_opcoes_porte,
                [e.target.name]: e.target.value
            }
        });
    }

    handleChangeAcoesEmGrupoClasse = async (e) => {
        await this.setState({
            acoes_em_grupo_opcoes_classe: {
                ...this.state.acoes_em_grupo_opcoes_classe,
                [e.target.name]: e.target.value
            }
        });
    }

    loadSubCategorias = async () => {
        try {
            try {
                await this.setLoading(true);
                const response = await axios.get( route('web.polos_geradores_subcategorias.index', {
                    categorias: this.state.filters.categorias
                }));

                this.setState({
                    sub_categorias: response.data
                });
            } catch (e) {
                console.log(e);
            }
        } catch (e) {
            console.log(e);
        }finally {
            await this.setLoading(false);
        }
    }

    handleSaveAcoesEmGrupo = async () => {
        try {

            this.setState({
                errors: {}
            });

            await this.setLoading(true);

            const response = await axios.post(route('web.view_gerenciamento_polos_geradores.update_many'), {
                ids: this.state.ids_selecionados,
                ...this.state.acoes_em_grupo_opcoes
            });


            if (response.status === 200) {
                toast.success(response.data.message);
                await this.loadPolos();
                await this.closeModalAcoesEmGrupo();

                this.setState({
                    acoes_em_grupo_opcoes:{
                        main_category: '',
                        sub_category: '',
                    }
                });
            }
        }
        catch (e) {
            console.log(e);
            if ( e.response && e.response.status === 422 ) {
                this.setState({
                    errors: e.response.data.errors
                });
            }
        }
        finally {
            await this.setLoading(false);
        }
    }


    handleSaveAcoesEmGrupoPorte = async () => {
        try {

            this.setState({
                errors: {}
            });

            await this.setLoading(true);

            const response = await axios.post(route('web.view_gerenciamento_polos_geradores.update_many_size'), {
                ids: this.state.ids_selecionados,
                size: this.state.acoes_em_grupo_opcoes_porte.porte
            });


            if (response.status === 200) {
                toast.success(response.data.message);
                await this.loadPolos();
                await this.closeModalAcoesEmGrupoPorte();

                this.setState({
                    acoes_em_grupo_opcoes_porte:{
                        porte: '',
                    }
                });
            }
        }
        catch (e) {
            console.log(e);
            if ( e.response && e.response.status === 422 ) {
                this.setState({
                    errors: e.response.data.errors
                });
            }
        }
        finally {
            await this.setLoading(false);
        }
    }

    handleSaveAcoesEmGrupoClasse = async () => {
        try {

            this.setState({
                errors: {}
            });

            await this.setLoading(true);

            const response = await axios.post(route('web.view_gerenciamento_polos_geradores.update_many_class'), {
                ids: this.state.ids_selecionados,
                class: this.state.acoes_em_grupo_opcoes_classe.classe
            });


            if (response.status === 200) {
                toast.success(response.data.message);
                await this.loadPolos();
                await this.closeModalAcoesEmGrupoClasse();

                this.setState({
                    acoes_em_grupo_opcoes_classe:{
                        classe: '',
                    }
                });
            }
        }
        catch (e) {
            console.log(e);
            if ( e.response && e.response.status === 422 ) {
                this.setState({
                    errors: e.response.data.errors
                });
            }
        }
        finally {
            await this.setLoading(false);
        }
    }

    openModalEdit = async () => {
        this.setState({
            showEditModal: true
        });
    }

    closeModalEdit = async () => {
        this.setState({
            showEditModal: false
        });
    }

    handleSave = async () => {
        try {

            this.setState({
                errors: {}
            });

            await this.setLoading(true);

            const response = await axios.put(route('web.view_gerenciamento_polos_geradores.update', this.state.currentEditing._id), this.state.currentEditing);

            if (response.status === 200) {
                toast.success('Polo atualizado com sucesso!');
                await this.loadPolos();
                await this.closeModalEdit();
            }


        } catch (e) {
            if (e.response && e.response.status === 422) {
                this.setState({
                    errors: e.response.data.errors
                });
            }
        } finally {
            await this.setLoading(false);
        }
    }

    loadCategorias = async () => {
        try {
            let response = await axios.get(route("polos-geradores-tipos"));

            if (response.status === 200) {
                let types = response.data.types;

                let options = [];

                types.forEach((type) => {
                    options.push({value: type, label: type});
                });

                this.setState({categorias: options});
            }
        } catch (e) {
            console.log(e);
        }
    };

    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }


    async componentDidMount() {
       await Promise.all([
            this.loadPolos(),
            this.loadCategorias(),
            this.loadSubCategorias(),
            this.loadProjects()
        ]);
    }


    handleProjetoChanged = async (e) => {
        try{
            await this.setState({
                filters: {}
            });

            await this.setLoading(true);


            const projetoResponse = await axios.get(route('mapa.project.show', e.value));
            const projetoData = projetoResponse.data.project;

            let cidade = JSON.parse(projetoData.cidade) || [];
            let estado = JSON.parse(projetoData.estado) || [];

            cidade = cidade.map((c) => c.label);

            const geojson = [];

            if (projetoData?.polignos != null) {
                const polygons = JSON.parse(projetoData.polignos);

                for (let i = 0; i < polygons.length; i++) {
                    let polygon = polygons[i];

                    let path = await this.getPolygonPath(polygon);
                    path.push(path[0]);

                    let geojsonPolygon = await this.convertPolygonToGeoJson(path);
                    geojson.push( geojsonPolygon );
                }

            }

            if (projetoData?.manualCircles != null) {
                const manualCircles = JSON.parse(projetoData.manualCircles);


                for (let i = 0; i < manualCircles.length; i++) {
                    let circle = manualCircles[i];

                    let center = { lat: parseFloat(circle?.latitude), lng: parseFloat(circle?.longitude) };

                    if (circle.center) {
                        center = {
                            lat: parseFloat(circle.center.lat),
                            lng: parseFloat(circle.center.lng)
                        };
                    }

                    const radius = parseFloat(circle.radius);

                    let polygonFromCircle = circleToPolygon([
                        center.lng,
                        center.lat
                    ], radius, 32);


                    geojson.push(polygonFromCircle);

                }
            }

            await this.setState({
                ...this.state,
                filters: {
                    ...this.state.filters,
                    polygons: geojson,
                    states: estado,
                    cities: cidade
                }
            });

            if( estado.length > 0 ){
                await this.loadCidades(estado, false );
            }

            if(cidade.length > 0) {
                await this.loadBairros(cidade, false );
            }

            await this.loadPolos();
        }catch (e){
            console.log(e);
        }finally {
            await this.setLoading(false);
        }

    }

    convertPolygonToGeoJson = async (path) => {

        console.log(path);

       try{
           return {
               "type": "Polygon",
               "coordinates": [path]
           };
       }catch (e){
           console.log(e);
           console.log(path);
       }
    }

    getPolygonPath = async (polygonData, aditionalOptions = {}) => {
        return polygonData.map(coordinate => ( [ coordinate[1], coordinate[0] ] ));
    }


    getPolo = async (id) => {
        try {
            await this.setLoading(true);

            const response = await axios.get(route('gerenciamento-polos-geradores.show', id));

            this.setState({
                ...this.state,
                currentEditing: response.data
            })

        } catch (e) {
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    getIdsFromTable = async () => {

        try{
            await this.setLoading(true);

            const filters = this.prepareFiltersForRequest();


            filters.page = 0;
            filters.get_only_ids = true;

            const response = await axios.post(route('web.view_gerenciamento_polos_geradores.list'), filters );

            this.setState({
                ...this.state,
                ids_selecionados: response.data.ids,
            })
        }catch (e){

        }finally {
            await this.setLoading(false);
        }
    }

    loadPolos = async ( showLoading = true ) => {
        try {

            if( showLoading ){
                await this.setLoading(true);
            }

            const filters = this.prepareFiltersForRequest();

            console.log(filters);

            const response = await axios.post(route('web.view_gerenciamento_polos_geradores.list'), filters );

            this.setState({
                polos: response.data.data,
                pagination: {
                    total: response.data.total,
                    per_page: response.data.per_page,
                    current_page: response.data.current_page,
                    last_page: response.data.last_page,
                    from: response.data.from,
                    to: response.data.to
                }
            });

        } catch (e) {
            console.log(e);
        } finally {
            if( showLoading ){
                await this.setLoading(false);
            }
        }
    }

    setFilterValue = async (e) => {

        await this.setState({
            filters: {
                ...this.state.filters,
                [e.target.name]: e.target.value,

            },
            pagination: {
                ...this.state.pagination,
                current_page: 1
            }
        });

        if(e.target.name === 'states'){
            await this.loadCidades(e.target.value);
        }

        if(e.target.name === 'cities'){
            await this.loadBairros(e.target.value);
        }

        if( e.target.name === 'categorias' ){
            await this.loadSubCategorias(e.target.value);
        }
    }

    loadCidades = async (estados, showLoading = true ) => {
        try {
            if( showLoading ){
                await this.setLoading(true);
            }

            const response = await axios.get(route('web.municipios.index', {
                ufs: estados
            }));

            this.setState({
                cidadesOptions: response.data
            });

        } catch (e) {
            console.log(e);
        } finally {
            if( showLoading ) {
                await this.setLoading(false);
            }
        }
    }

    loadBairros = async (cidades, showLoading = true ) => {
        try {
            if( showLoading ){
                await this.setLoading(true);
            }

            const response = await axios.get(route('web.bairros_polos.index', {
                cidades: cidades,
                estados: this.state.filters.states
            }));

            this.setState({
                bairrosOptions: response.data
            });

        } catch (e) {
            console.log(e);
        } finally {
            if( showLoading ){
                await this.setLoading(false);
            }
        }
    }

    handleChange = async (e) => {
        await this.setState({
            currentEditing: {
                ...this.state.currentEditing,
                [e.target.name]: e.target.value
            }
        });
    }

    prepareFiltersForRequest = () => {
        let filters = {};

        for (let key in this.state.filters) {
            let value = this.state.filters[key];

            if (!value) {
                continue;
            }

            if (typeof value === 'string') {
                filters[key] = value;
            }

            if (Array.isArray(value)) {
                filters[key] = value;
            }
        }

        filters.page = this.state.pagination.current_page || 1;

        return filters;
    }

    handleCheck = async (e) => {
        let id = e.target.value;

        if (e.target.checked) {
            await this.setState({
                ids_selecionados: [...this.state.ids_selecionados, id]
            });
        } else {
            await this.setState({
                ids_selecionados: this.state.ids_selecionados.filter((item) => item !== id)
            });
        }
    }

    checkAll = async (e) => {
        if (e.target.checked) {
            if( this.state.tipo_selecao === 'Página'){
                let ids = this.state.polos.map((polo) => polo._id);
                await this.setState({
                    ids_selecionados: ids
                });
            }

            if(this.state.tipo_selecao === 'Tabela'){

                if( this.state.pagination.total > this.MAX_IDS_SELECIONADOS_PERMITIDOS ){
                    alert(`Essa operação não pode ser efetuada com a quantidade de registros atual. Permitido no máximo ${this.MAX_IDS_SELECIONADOS_PERMITIDOS} registros. Use os filtros para diminuir a quantidade de resultados.`);
                    e.target.checked = false;
                    return false;
                }

                await this.getIdsFromTable();
            }

        } else {
            await this.setState({
                ids_selecionados: []
            });
        }
    }

    showModalAcoesEmGrupo = async () => {
        this.setState({
            show_modal_acoes_em_grupo: true
        });
    }

    showModalAcoesEmGrupoPorte = async () => {
        this.setState({
            show_modal_acoes_em_grupo_porte: true
        });
    }

    showModalAcoesEmGrupoClasse = async () => {
        this.setState({
            show_modal_acoes_em_grupo_classe: true
        });
    }

    showModalDelecaoEmGrupo = async () => {
        this.setState({
            show_modal_remocao_em_grupo: true
        });
    }

    closeModalAcoesEmGrupo = async () => {
        this.setState({
            show_modal_acoes_em_grupo: false
        });
    }

    closeModalAcoesEmGrupoPorte = async () => {
        this.setState({
            show_modal_acoes_em_grupo_porte: false
        });
    }

    closeModalAcoesEmGrupoClasse = async () => {
        this.setState({
            show_modal_acoes_em_grupo_classe: false
        });
    }

    closeModalRemocaoEmGrupo = async () => {
        this.setState({
            show_modal_remocao_em_grupo: false
        });
    }

    formatIntegerNumber = (number) => {
        return new Intl.NumberFormat('pt-BR').format(number);
    }

    deletePolo = async () => {
        try {
            await this.setLoading(true);

            const response = await axios.delete(route('gerenciamento-polos-geradores.destroy', this.state.id_delete));

            if (response.status === 200) {
                toast.success('Polo deletado com sucesso!');
                this.op.hide();
                await this.loadPolos();

                this.setState({
                   ...this.state,
                     id_delete: null
                });
            }

        }catch (e){
            console.log(e);
        }finally {
            await this.setLoading(false);
        }
    }

    handleRemocaoEmGrupo = async () => {
        try {
            await this.setLoading(true);

            const response = await axios.post(route('gerenciamento-polos-geradores.destroy_many'), {
                ids: this.state.ids_selecionados
            });

            if (response.status === 200) {
                toast.success(response.data.message);
                await this.loadPolos();
                await this.closeModalRemocaoEmGrupo();
            }

        } catch (e) {
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    render() {
        return (

            <div className="card">

                {this.state.loading && (
                    <Loader/>
                )}

                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{width: "50%", zIndex: 9999999, fontWieght: 'bold'}}
                />

                <ModalEdicaoPolo
                    show={this.state.showEditModal}
                    handleClose={this.closeModalEdit}
                    polo={this.state.currentEditing}
                    categorias={this.state.categorias}
                    sub_categorias={this.state.sub_categorias}
                    handleChange={this.handleChange}
                    handleSave={this.handleSave}
                    errors={this.state.errors}
                />

                <ModalEdicaoMassa
                    opcoes={this.state.acoes_em_grupo_opcoes}
                    show={this.state.show_modal_acoes_em_grupo}
                    handleClose={this.closeModalAcoesEmGrupo}
                    categorias={this.state.categorias}
                    sub_categorias={this.state.sub_categorias}
                    handleChange={this.handleChangeAcoesEmGrupo}
                    handleSave={this.handleSaveAcoesEmGrupo}
                    errors={this.state.errors}
                />

                <ModalEdicaoMassaPorte
                    show={this.state.show_modal_acoes_em_grupo_porte}
                    handleClose={this.closeModalAcoesEmGrupoPorte}
                    opcoes={this.state.acoes_em_grupo_opcoes_porte}
                    valores={this.state.opcoes_porte}
                    handleChange={this.handleChangeAcoesEmGrupoPorte}
                    handleSave={this.handleSaveAcoesEmGrupoPorte}
                    errors={this.state.errors}
                />


                <ModalEdicaoMassaClasse
                    show={this.state.show_modal_acoes_em_grupo_classe}
                    handleClose={this.closeModalAcoesEmGrupoClasse}
                    opcoes={this.state.acoes_em_grupo_opcoes_classe}
                    valores={this.state.opcoes_classe}
                    handleChange={this.handleChangeAcoesEmGrupoClasse}
                    handleSave={this.handleSaveAcoesEmGrupoClasse}
                    errors={this.state.errors}
                />

                <ModalRemocaoMassa
                    show={this.state.show_modal_remocao_em_grupo}
                    handleClose={this.closeModalRemocaoEmGrupo}
                    ids_selecionados={this.state.ids_selecionados}
                    handleSave={this.handleRemocaoEmGrupo}
                    errors={this.state.errors}
                />


                <div className="card-body">
                    <form>
                        <div className="form-group row">
                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label>Carregar Projeto</label>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderRadius: 20,
                                        }),
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#F2F2F2",
                                            primary: "#5B7537",
                                        },
                                    })}
                                    placeholder="Selecione"
                                    options={this.state.projetos}
                                    onChange={this.handleProjetoChanged}
                                    isMulti={false}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label htmlFor="title">Título</label>
                                <input type="text" id="title" name="title"
                                       onChange={this.setFilterValue}
                                       value={this.state.filters?.title}
                                       className="form-control"/>
                            </div>

                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label htmlFor="formatted_address">Categoria</label> <br/>
                                <MultiSelect
                                    name="categorias"
                                    optionLabel="label"
                                    value={this.state.filters.categorias}
                                    options={this.state.categorias}
                                    onChange={this.setFilterValue}
                                    filter={true}
                                    display="chip"
                                    placeholder="Selecione a categoria"
                                    selectedItemsLabel="{0} selecionados"
                                    style={{width: '100%'}}
                                    maxSelectedLabels={3}
                                    showClear={true}
                                />
                            </div>

                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label>Sub-Categoria</label>
                                <MultiSelect
                                    name="sub_categorias"
                                    optionLabel="label"
                                    value={this.state.filters.sub_categorias}
                                    blockScroll={true}
                                    appendTo={document.body}
                                    options={this.state.sub_categorias}
                                    onChange={this.setFilterValue}
                                    placeholder="Selecione Uma Sub-Categoria"
                                    selectedItemsLabel="{0} selecionados"
                                    maxSelectedLabels={3}
                                    style={{width: '100%'}}
                                    display="chip"
                                    filter={true}
                                    lazy={true}
                                    showClear={true}
                                    disabled={this.state.filters.categorias?.length === 0}
                                />
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label htmlFor="state">Estado</label>
                                <MultiSelect
                                    name="states"
                                    optionLabel="label"
                                    value={this.state.filters.states}
                                    options={this.state.estados}
                                    onChange={this.setFilterValue}
                                    filter={true}
                                    display="chip"
                                    placeholder="Selecione o estado"
                                    selectedItemsLabel="{0} selecionados"
                                    style={{width: '100%'}}
                                    maxSelectedLabels={3}
                                />
                            </div>

                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label htmlFor="city">Cidade</label>
                                <MultiSelect
                                    name="cities"
                                    optionLabel="label"
                                    value={this.state.filters.cities}
                                    options={this.state.cidadesOptions}
                                    onChange={this.setFilterValue}
                                    filter={true}
                                    display="chip"
                                    placeholder="Selecione"
                                    selectedItemsLabel="{0} selecionados"
                                    style={{width: '100%'}}
                                    maxSelectedLabels={3}
                                    disabled={this.state.filters.states?.length === 0}
                                />
                            </div>

                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label htmlFor="city">Bairro</label>
                                <MultiSelect
                                    name="neighborhoods"
                                    optionLabel="label"
                                    value={this.state.filters.neighborhoods}
                                    options={this.state.bairrosOptions}
                                    onChange={this.setFilterValue}
                                    filter={true}
                                    display="chip"
                                    placeholder="Selecione"
                                    selectedItemsLabel="{0} selecionados"
                                    style={{width: '100%'}}
                                    maxSelectedLabels={3}
                                    disabled={this.state.filters.cities?.length === 0}
                                />
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                                <button className="btn btn-sm btn-block btn-success" onClick={async (e) => {
                                    e.preventDefault();
                                    await this.loadPolos();
                                }}>
                                    <i className="fa fa-search"></i> &nbsp;
                                    Pesquisar
                                </button>
                            </div>

                            <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                                <button className="btn btn-sm btn-block btn-primary" onClick={async (e) => {
                                    e.preventDefault();
                                    await this.cleanFiltros();
                                }}>
                                    <i className="fa fa-eraser"></i> &nbsp;
                                    Limpar Filtros
                                </button>
                            </div>

                        </div>


                    </form>
                    <hr/>

                    <div className="d-flex justify-content-between align-items-center">
                        <p className="text-muted p-1">
                            Exibindo
                            de {this.formatIntegerNumber(this.state.pagination.from)} até {this.formatIntegerNumber(this.state.pagination.to)} de {this.formatIntegerNumber(this.state.pagination.total)} registros
                        </p>

                        <div>

                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button"
                                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                        disabled={this.state.ids_selecionados.length === 0}
                                        aria-expanded="false">
                                    Ações em Grupo
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                    <button className="dropdown-item" onClick={this.showModalAcoesEmGrupo}>
                                        <i className="fa fa-edit"></i> &nbsp;
                                        Atualizar Categoria e Sub-Categoria
                                    </button>

                                    <button className="dropdown-item" onClick={this.showModalAcoesEmGrupoPorte}>
                                        <i className="fa fa-tag"></i> &nbsp;
                                        Atualizar Porte
                                    </button>

                                    <button className="dropdown-item" onClick={this.showModalAcoesEmGrupoClasse}>
                                        <i className="fa fa-shop"></i> &nbsp;
                                        Atualizar Classe
                                    </button>

                                    <button className="dropdown-item" onClick={this.showModalDelecaoEmGrupo}>
                                        <i className="fa fa-trash"></i> &nbsp;
                                        Excluir
                                    </button>
                                </div>
                            </div>

                            {/*<button className="btn btn-sm btn-primary" onClick={this.showModalAcoesEmGrupo}
                                    disabled={this.state.ids_selecionados.length === 0}
                            >Ações em Grupo
                            </button>*/}
                        </div>
                    </div>

                    <OverlayPanel
                        appendTo={document.body}
                        ref={(el) => this.op = el}>
                        <p>
                            Tem certeza que deseja excluir esse registro?
                        </p>
                        <button className="btn btn-danger btn-sm"
                                onClick={async (e) => {
                                    await this.deletePolo();
                                }}
                        >Sim</button> &nbsp;
                        <button className="btn btn-default btn-sm"
                                onClick={(e) => this.op.hide(e)}
                        >Cancelar</button>
                    </OverlayPanel>

                    <OverlayPanel
                        appendTo={document.body}
                        ref={(el) => this.opTipoSelecao = el}>
                        <div>
                            <Dropdown
                                value={this.state.tipo_selecao}
                                onChange={(e) => {
                                    this.setState({
                                        ...this.state,
                                        tipo_selecao: e.value,
                                    })
                                }} options={this.state.tipo_selecao_options}
                                placeholder="Selecione um valor" className="w-full md:w-14rem" />

                        </div>
                    </OverlayPanel>


                    <div style={{overflowY: "auto"}}>
                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr style={{backgroundColor: '#5B7537', color: 'white'}}>
                                <th style={{color: 'white'}}>
                                    <div className="d-flex justify-content-between">
                                        <div style={{width: '40%'}}>
                                            <input type="checkbox"
                                                   className="checkbox"
                                                   defaultChecked={this.state.polos.length > 0 && ( this.state.ids_selecionados.length === this.state.polos.length || this.state.ids_selecionados.length === this.state.pagination.total )}
                                                   onClick={this.checkAll}/>
                                        </div>
                                        <div style={{width: '60%'}}>
                                            <a href="#"
                                               title={`Tipo de Seleção ${this.state.tipo_selecao}`}
                                               style={{
                                                   float: 'right',
                                                   color: 'white',
                                                   fontSize: '16px',
                                                   textDecoration: 'none'
                                               }}
                                               onClick={(e)=>{
                                                e.preventDefault();
                                                this.opTipoSelecao.toggle(e);
                                            }}>
                                                &nbsp;<span className="font-weight-bold">{this.state.tipo_selecao.at(0)}</span> &nbsp;
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </a>
                                        </div>
                                    </div>
                                </th>
                                <th style={{color: 'white'}}>Nome do Estabelecimento</th>
                                <th style={{color: 'white'}}>Endereço</th>
                                <th style={{color: 'white'}}>Bairro</th>
                                <th style={{color: 'white'}}>Cidade</th>
                                <th style={{color: 'white'}}>Estado</th>
                                <th style={{color: 'white'}}>Categoria</th>
                                <th style={{color: 'white'}}>Sub-Categoria</th>
                                <th style={{color: 'white'}}>Porte</th>
                                <th style={{color: 'white'}}>Classe</th>
                                <th style={{color: 'white'}}>Ações</th>
                            </tr>
                            </thead>

                            <tbody>
                            {this.state.polos.map((polo, index) => {
                                return (
                                    <tr key={polo._id}>
                                        <td>
                                            <input type="checkbox" name="ids_selecionados"
                                                   className="checkbox"
                                                   checked={this.state.ids_selecionados.includes(polo._id)}
                                                   onChange={this.handleCheck}
                                                   value={polo._id}/>
                                        </td>
                                        <td>{polo.title}</td>
                                        <td>{polo.formatted_address}</td>
                                        <td>{polo.neighborhood}</td>
                                        <td>{polo.city}</td>
                                        <td>{polo.state}</td>
                                        <td>{polo.main_category}</td>
                                        <td>{polo.sub_category}</td>
                                        <td>{polo.size}</td>
                                        <td>{polo.class}</td>
                                        <td>
                                            <div className="d-flex justify-content-between align-items-center align-content-center ">
                                                <a href="#" className="btn btn-sm btn-success"
                                                   onClick={async (e) => {
                                                       e.preventDefault();
                                                       await this.getPolo(polo._id);

                                                       await this.openModalEdit();
                                                   }}
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </a>
                                                &nbsp;

                                                <button className="btn btn-sm btn-danger" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        id_delete: polo._id
                                                    });
                                                    this.op.toggle(e);
                                                }}>
                                                    <i className="fa fa-trash"></i>
                                                </button>


                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>

                        </table>
                    </div>

                </div>

                <div className="card-footer">
                    <nav>
                        <ul className="pagination d-flex justify-content-center align-items-center">
                            <li className="page-item">
                                {this.state.pagination.current_page > 1 &&
                                    <a href="#" className="page-link" onClick={async (e) => {
                                        e.preventDefault();
                                        this.setState({
                                            pagination: {
                                                ...this.state.pagination,
                                                current_page: this.state.pagination.current_page - 1
                                            }
                                        })
                                        await this.loadPolos();
                                    }}>
                                        Anterior
                                    </a>}
                            </li>
                            <li className="page-item">
                                {this.state.pagination.current_page < this.state.pagination.last_page &&
                                    <a href="#" className="page-link" onClick={async (e) => {
                                        e.preventDefault();
                                        this.setState({
                                            pagination: {
                                                ...this.state.pagination,
                                                current_page: this.state.pagination.current_page + 1
                                            }
                                        })
                                        await this.loadPolos();
                                    }}>
                                        Próxima
                                    </a>}
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }

    async cleanFiltros() {
        await this.setState({
            filters: {}
        });

        await this.loadPolos();
    }
}


if (document.getElementById('js-polos-geradores-gerenciamento')) {
    ReactDOM.render(<Listagem/>, document.getElementById('js-polos-geradores-gerenciamento'));
}

